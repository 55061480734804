// import React, { useEffect, useState } from 'react';
// import IncludeSideBar from '../../Components/Sidebar/IncludeSideBar';
// import useAuth from "../../../src/useAuth";

// function AdminProfile() {
//   const [adminEmployees, setAdminEmployees] = useState([]);
//   const [Adminprofile,setAdminprofile]=useState([])
//   const [loading, setLoading] = useState(false);

//   const EmployeeApis = require('../../apis/employee')
//   const { user } = useAuth();
//   const UserId = user ? user.id : null;
//   console.log(UserId, "roooolllee user?.permissions?.Users")
//   const getEmployee = async () => {
//     try {
//       const response = await EmployeeApis.getAllEmployee();
//       const adminEmployees = response.employees.filter(employee => employee.role === 'admin');
//       setAdminEmployees(adminEmployees);
//     } catch (error) {
//       console.error("Error fetching admin employees:", error);
//     }
//   };

//   const getAdminProfile = async () => {
//     try {
//       setLoading(true);

//       const response = await EmployeeApis.getProfile(UserId);
//       setAdminprofile(response.AdminProfile)
//       console.log(response,'response')
//     } catch (error) {
//       console.error("Error fetching admin employees:", error);
//     }finally {

//       setLoading(false); // Hide loader
//     }
//   };
//   useEffect(() => {
//     getEmployee();
//     getAdminProfile()
//   }, [UserId]);

//   return (
//     <IncludeSideBar>
//       <div>
//       <h1>Welcome to Your Profile</h1>

//         {loading && (
//           <div className="loader-container">
//             <div className="loader"></div>
//           </div>
//         )}

//         <div className="superadmin-details">
         
//               <div  className="superadmin-card">

//                 <table>
//                   <tbody>
//                   {/* <tr>
//                       <td><strong>ID:(receiver_id)</strong></td>
//                       <td>4</td>
//                     </tr> */}
//                     <tr>
                   
//                       <td><strong>Name:</strong></td>
//                       <td>{Adminprofile.first_name} {Adminprofile.last_name}</td>
//                     </tr>
//                     <tr>
//                       <td><strong>Email:</strong></td>
//                       <td>{Adminprofile.email}</td>
//                     </tr>
//                     <tr>
//                       <td><strong>Role:</strong></td>
//                       <td>{Adminprofile.role}</td>
//                     </tr>
//                     <tr>
//                       <td><strong>Gender:</strong></td>
//                       <td>{Adminprofile.gender || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                       <td><strong>Mob No:</strong></td>
//                       <td>{Adminprofile.phone_number || 'N/A'}</td>
//                     </tr>
//                     <tr>
//                       <td><strong>Wallet Money:</strong></td>
//                       <td>{Adminprofile.wallet}</td>
//                     </tr>
//                     <tr>
//   <td><strong>Permission Allotted:</strong></td>
//   <td>
//     {Object.keys(Adminprofile.permissions).map((category) => (
//       <div key={category}>
//         <strong>{category} Permissions:</strong>
//         {Object.keys(Adminprofile.permissions[category]).map((action) => {
//           const actionDetails = Adminprofile.permissions[category][action]
          
//           // Check if the main action is selected (i.e., permission is granted)
//           if (actionDetails.selected) {
//             const subfeatures = actionDetails.subfeatures;

//             // Find subfeatures that are granted (true)
//             const grantedSubfeatures = Object.keys(subfeatures).filter(
//               (subfeature) => subfeatures[subfeature] === true
//             );

//             return (
//               <div key={action}>
//                 {/* Display the main action */}
//                 <strong>{action}</strong>
                
//                 {/* If there are granted subfeatures, list them */}
//                 {grantedSubfeatures.length > 0 && (
//                   <ul>
//                     {grantedSubfeatures.map((subfeature) => (
//                       <li key={subfeature}>{subfeature}</li>
//                     ))}
//                   </ul>
//                 )}
//                 <br />
//               </div>
//             );
//           }

//           return null; // Skip if the main action is not selected
//         })}
//         <br />
//       </div>
//     ))}
//   </td>
// </tr>

                    
//                   </tbody>
//                 </table>
//               </div>
//         </div>

//         {/* <table>
//           <thead>
//             <tr>
//               <th>ID</th>
//               <th>Name</th>
//               <th>Email</th>
//               <th>Role</th>
//               <th>Wallet</th>
//             </tr>
//           </thead>
//           <tbody>
//             {adminEmployees.map((employee) => (
//               <tr key={employee.id}>
//                 <td>{employee.id}</td>
//                 <td>{`${employee.first_name} ${employee.last_name}`}</td>
//                 <td>{employee.email}</td>
//                 <td>{employee.role}</td>
//                 <td>{employee.wallet || 'N/A'}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table> */}
//       </div>
//     </IncludeSideBar>
//   );
// }

// export default AdminProfile;


import React, { useEffect, useState } from 'react';
import IncludeSideBar from '../../Components/Sidebar/IncludeSideBar';
import useAuth from "../../../src/useAuth";
import { FaUser, FaEnvelope, FaPhone, FaWallet, FaUserShield } from 'react-icons/fa';

function AdminProfile() {
  const [adminEmployees, setAdminEmployees] = useState([]);
  const [Adminprofile, setAdminprofile] = useState([]);
  const [loading, setLoading] = useState(false);

  const EmployeeApis = require('../../apis/employee');
  const { user } = useAuth();
  const UserId = user ? user.id : null;

  const getEmployee = async () => {
    try {
      const response = await EmployeeApis.getAllEmployee();
      const adminEmployees = response.employees.filter(employee => employee.role === 'admin');
      setAdminEmployees(adminEmployees);
    } catch (error) {
      console.error("Error fetching admin employees:", error);
    }
  };

  const getAdminProfile = async () => {
    try {
      setLoading(true);
      const response = await EmployeeApis.getProfile(UserId);
      setAdminprofile(response.AdminProfile);
    } catch (error) {
      console.error("Error fetching admin profile:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmployee();
    getAdminProfile();
  }, [UserId]);

  const tableContainerStyle = {
    padding: '20px',
    backgroundColor: '#f4f7f9',
    minHeight: '100vh',
    textAlign: 'center',
  };

  const tableStyle = {
    width: '80%',
    margin: '20px auto',
    borderCollapse: 'collapse',
    backgroundColor: '#ffffff',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
  };

  const thTdStyle = {
    border: '1px solid #ddd',
    padding: '12px',
    textAlign: 'left',
    fontSize: '1.2em',
    color: '#34495e',
  };

  const thStyle = {
    backgroundColor: '#3498db',
    color: '#ffffff',
    padding: '12px',
  };

  const loaderOverlayStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    position: 'absolute',
    width: '100%',
  };

  const spinnerStyle = {
    border: '5px solid #f3f3f3',
    borderRadius: '50%',
    borderTop: '5px solid #3498db',
    width: '60px',
    height: '60px',
    animation: 'spin 1s linear infinite',
  };

  return (
    <IncludeSideBar>
      <div style={tableContainerStyle}>
        <h1 style={{ fontSize: '2.5em', marginBottom: '20px', color: '#2c3e50' }}>
          Welcome to Your Profile
        </h1>

        {loading && (
          <div style={loaderOverlayStyle}>
            <div style={spinnerStyle}></div>
          </div>
        )}

        {!loading && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={thStyle}>Field</th>
                <th style={thStyle}>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={thTdStyle}><FaUser style={{ marginRight: '10px' }} />Name</td>
                <td style={thTdStyle}>{Adminprofile.first_name} {Adminprofile.last_name}</td>
              </tr>
              <tr>
                <td style={thTdStyle}><FaEnvelope style={{ marginRight: '10px' }} />Email</td>
                <td style={thTdStyle}>{Adminprofile.email}</td>
              </tr>
              <tr>
                <td style={thTdStyle}><FaUserShield style={{ marginRight: '10px' }} />Role</td>
                <td style={thTdStyle}>{Adminprofile.role}</td>
              </tr>
              <tr>
                <td style={thTdStyle}><FaPhone style={{ marginRight: '10px' }} />Mobile No</td>
                <td style={thTdStyle}>{Adminprofile.phone_number || 'N/A'}</td>
              </tr>
              <tr>
                <td style={thTdStyle}><FaWallet style={{ marginRight: '10px' }} />Wallet Money</td>
                <td style={thTdStyle}>{Adminprofile.wallet}</td>
              </tr>
            </tbody>
          </table>
        )}

        <h2 style={{ fontSize: '1.5em', color: '#2980b9', marginTop: '30px', marginBottom: '15px' }}>
          Permissions
        </h2>

        {!loading && Adminprofile.permissions && (
  <table style={tableStyle}>
    <thead>
      <tr>
        <th style={thStyle}>Permission Category</th>
        <th style={thStyle}>Granted Actions</th>
      </tr>
    </thead>
    <tbody>
      {Object.entries(Adminprofile.permissions).map(([category, actions]) => (
        <tr key={category}>
          <td style={thTdStyle}><strong>{category} Permissions</strong></td>
          <td style={thTdStyle}>
            {Object.entries(actions).map(([action, actionDetails]) => {
              if (actionDetails?.selected) {
                const grantedSubfeatures = Object.entries(actionDetails?.subfeatures || {})
                  .filter(([_, isGranted]) => isGranted)
                  .map(([subfeature]) => subfeature);

                return (
                  <div key={action}>
                    <strong>{action}</strong>
                    {grantedSubfeatures.length > 0 && (
                      <ul>
                        {grantedSubfeatures.map(subfeature => (
                          <li key={subfeature}>{subfeature}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                );
              }
              return null;
            })}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)}

      </div>
    </IncludeSideBar>
  );
}

export default AdminProfile;
