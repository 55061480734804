import React, { useEffect, useState } from 'react';
// Assuming allVideoApis is an instance of an API object
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar'
import useAuth from "../../../useAuth";
import "./Spinner.css"; 
import useDebounce from '../../../../src/utils/useDebounce';

function MuteVideoAudio() {
  const allVideoApis = require('../../../apis/all_user_video');
  const { user } = useAuth();
  const role = user ? user.role : null;
  console.log(user?.permissions?.Users?.['View Video'], "roooolllee user?.permissions?.Users")
  const hasUnblockMuteAudio = user?.permissions?.Videos?.['unmute VideoAudio']?.selected || user?.role === 'superadmin';

  const [blockedVideos, setBlockedVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('')
  const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);
  const [pageRange, setPageRange] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]); // Visible page range

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const getAllMutedVideoAudio = async (page, searchTerm = '') => {
    try {
      const response = await allVideoApis.getAllMutedVideoAudio(page, searchTerm);
      console.log(response,'responseresponseresponse')
      console.log(response.data); // Check the response structure and ensure it's an array of objects

      setBlockedVideos(response.data); // Store the fetched data in state
      setTotalPages(response.pagination.totalPages);

      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error fetching Blocked Videos:", error);
      setLoading(false); // Handle loading state on error as well
    }
  };

  // useEffect(() => {
  //   getAllBlockedVideo();
  // }, []);

  useEffect(() => {
    getAllMutedVideoAudio(currentPage, debouncedSearchTerm);
}, [currentPage, debouncedSearchTerm]);

const handleSearch = (searchValue) => {
    setSearchTerm(searchValue);
};




const handleUnblockVideoAudio = async (videoId) => {
  const confirmation = window.confirm("Are you sure you want to unMute this video?");
  
  if (!confirmation) {
    return; // Exit the function if the user cancels
  }

  setLoading(true); // Set loading to true before making the API call

  try {
    const response = await allVideoApis.unBlockVideoAudio(videoId);

    if (response.success) {
      alert(response.message);
    }
    getAllMutedVideoAudio(currentPage, debouncedSearchTerm);
  } catch (error) {
    console.error(`Error unblocking video with ID ${videoId}:`, error);
  } finally {
    setLoading(false); // Set loading to false once the request is complete
  }
};


  const handleNoAccess = () => {
    alert('You are not able to access this feature.');
};

const Spinner = () => {
    return <div className="spinner-container">
    <div className="loader"></div>
  </div>;
  };
  useEffect(() => {
    // Simulate loading for 3 seconds
    const timer = setTimeout(() => setLoading(false), 3000);
    return () => clearTimeout(timer);
  }, []);

    // Dynamically update the initial page range based on totalPages
    useEffect(() => {
      const initialRange = [];
      const rangeSize = 10;
      const totalVisiblePages = Math.min(rangeSize, totalPages);
  
      for (let i = 1; i <= totalVisiblePages; i++) {
        initialRange.push(i);
      }
      setPageRange(initialRange);
    }, [totalPages]);
  
    const updatePageRange = (startPage) => {
      const rangeSize = 10; // Number of pages visible at a time
      const newRange = [];
  
      for (
        let i = startPage;
        i < startPage + rangeSize && i <= totalPages;
        i++
      ) {
        newRange.push(i);
      }
  
      setPageRange(newRange);
    };
  
    const handlePageClick = (page) => {
      setCurrentPage(page);
  
      // If the clicked page is the last in the current range, load the next range
      if (page === pageRange[pageRange.length - 1] && page < totalPages) {
        updatePageRange(page);
      }
  
      // If the clicked page is the first in the current range and not the first page
      if (page === pageRange[0] && page > 1) {
        updatePageRange(page - 9);
      }
    };
  
    const handleFirst = () => {
      setCurrentPage(1);
      updatePageRange(1);
    };
  
    const handleLast = () => {
      setCurrentPage(totalPages);
      const lastRangeStart = Math.max(
        1,
        Math.floor((totalPages - 1) / 10) * 10 + 1
      );
      updatePageRange(lastRangeStart);
    };
  
    const handlePrevious = () => {
      if (currentPage > 1) {
        // Check if we are at the start of the current visible range
        if (currentPage === pageRange[0]) {
          // If we are at the first page of the range, move to the previous set of pages
          const previousRangeStart = Math.max(pageRange[0] - 10, 1); // Ensure start page is >= 1
          updatePageRange(previousRangeStart);
        } else {
          setCurrentPage(currentPage - 1);
        }
      }
    };
  
    const handleNext = () => {
      if (currentPage < totalPages) {
        // If we are at the end of the current visible range, load the next range
        if (currentPage === pageRange[pageRange.length - 1] && currentPage < totalPages) {
          updatePageRange(pageRange[pageRange.length - 1]);
        } else {
          setCurrentPage(currentPage + 1);
        }
      }
    };
  
    const handleGoToPage = () => {
      const page = parseInt(inputPageNumber, 10);
  
      // Ensure the page number is a valid number and within the valid range
      if (page >= 1 && page <= totalPages) {
        setCurrentPage(page);
  
        // Calculate the range for the current page
        const startPage = Math.floor((page - 1) / 10) * 10 + 1;
        updatePageRange(startPage);
      } else {
        alert('Please enter a valid page number!');
      }
    };
  
  return (
    <IncludeSideBar>
      <div>
        <p>Mute Video Audio Info</p>
        <input
          type="text"
          placeholder="Search by UserName"
          value={searchTerm}
        onChange={(e) => handleSearch(e.target.value)}
        />
        {loading ? (
        //   <p>Loading...</p>
        <Spinner />

        ) : (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>USER_ID</th>
                <th>VIDEO Thum</th>
                <th>USERNAME</th>

                <th>Action</th>
                {/* Add more table headers for other video attributes */}
              </tr>
            </thead>
            <tbody>
              {blockedVideos.map((video, index) => (
                <tr key={index}>
                  <td>{video?.id}</td>
                  <td>{video?.user_id}</td>

                  <td> 
                    <img
                    src={`https://dpcst9y3un003.cloudfront.net/${video?.thum}`}
                    alt="Thumbnail"

                    style={{ cursor: 'pointer', width: '50px', height: '50px' }}
                  />
                  
                  </td>
                  {/* <td><img
                      //   src={`https://dpcst9y3un003.cloudfront.net/${user.profile_pic}`}
                      src={video.profile_pic}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "20%",
                      }}
                    /></td> */}
                  <td>{video?.user?.username
                  }</td>
                  <td>
                    <button 
                    onClick={()=>{
                      if(hasUnblockMuteAudio){
                        handleUnblockVideoAudio(video?.id)

                      }else{
                        handleNoAccess()
                      }
                    }}
                    style={{
                      backgroundColor:
                          !hasUnblockMuteAudio
                              ? '#C5C6C7'
                              : '', // Set red background when there is access
                      cursor: !hasUnblockMuteAudio ? 'not-allowed' : 'pointer', // Changes cursor when disabled
                  }}
                    >
                      UnMute
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
                {/* ***************************************************pagination***************** */}
                <div>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <button onClick={handleFirst} disabled={currentPage === 1}>
                            First
                        </button>
                        <button onClick={handlePrevious} disabled={currentPage === 1}>
                            Previous
                        </button>
                        {pageRange.map((page) => (
                            <button
                                key={page}
                                onClick={() => handlePageClick(page)}
                                style={{
                                    padding: "5px 10px",
                                    backgroundColor: currentPage === page ? "red" : "#f8f9fa",
                                    color: currentPage === page ? "white" : "black",
                                    border: "1px solid #ddd",
                                    cursor: "pointer",
                                }}
                            >
                                {page}
                            </button>
                        ))}
                        <button onClick={handleNext} disabled={currentPage === totalPages}>
                            Next
                        </button>
                        <button onClick={handleLast} disabled={currentPage === totalPages}>
                            Last
                        </button>
                    </div>
                    {/* direct page */}
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
                        <input
                            type="number"
                            value={inputPageNumber}
                            onChange={(e) => setInputPageNumber(e.target.value)}
                            placeholder="Page No"
                            style={{
                                padding: '5px',
                                fontSize: '14px',
                                // border: '1px solid #ccc',
                                border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

                                borderRadius: '4px 0 0 4px',
                                outline: 'none',
                                width: '90px'
                            }}
                            onFocus={() => setInputFocus(true)}
                            onBlur={() => setInputFocus(false)}
                        />
                        <button
                            onClick={handleGoToPage}
                            style={{
                                padding: '6.5px 10px',
                                fontSize: '14px',
                                border: '1px solid #ccc',
                                borderLeft: 'none',
                                borderRadius: '0 4px 4px 0',
                                // backgroundColor: '#007bff',

                                backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
                                color: 'white',
                                cursor: 'pointer',
                                outline: 'none',
                            }}


                        >
                            Go
                        </button>
                    </div>

                </div>

      </div>
    </IncludeSideBar>
  );
}

export default MuteVideoAudio;
