import React, { useEffect, useState, useCallback } from "react";
import IncludeSideBar from "../../../Components/Sidebar/IncludeSideBar";
import useAuth from "../../../../src/useAuth";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function MuteVideoComment() {
  const allVideoApis = require('../../../apis/all_user_video');

  const allfeedApis = require("../../../apis/feed");

  const { user } = useAuth();
  const hasUnmutevideocommet = user?.permissions?.Videos?.['Unmute Comment']?.selected || user?.role === 'superadmin';

  //   // console.log(hassubfetureEditComment,'hassubfetureEditComment')
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false); // Add a loading state
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [AllMute, setAllMute] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [pageRange, setPageRange] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]); // Visible page range
  const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);
  const [expandedPosts, setExpandedPosts] = useState({});
  const [openfeedmodel, setopenfeedmodel] = useState(false)
  const [feedinfo, setfeedinfo] = useState([])
  const [selectedRow, setSelectedRow] = useState(null); // State to track selected row
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [clickedButton, setClickedButton] = useState(null);

  const FeatchMutedCommentUser = async (page, searchTerm = "", startDate = null, endDate = null) => {
    try {
      setLoading(true);
      const filters = { page, searchTerm };

      if (startDate) {
        filters.startDate = startDate.toISOString();
      }

      if (endDate) {
        filters.endDate = endDate.toISOString();
      }
      const response = await allVideoApis.GetMutedCommentUser(filters);
      console.log(response, 'response?.data?comment')

      // const usersWithRemainingTime = response.mutedUsers.map(user => ({
      //   ...user,
      //   remainingTimes: Math.max(new Date(user.unmute_at) - Date.now(), 0) // Prevent negative values
      // }));
      const usersWithRemainingTime = response.mutedUsers.map(user => ({
        ...user,
        remainingTime: new Date(user.unmute_at) - Date.now()
      }));
      setAllMute(usersWithRemainingTime);
      setTotalPages(response?.pagination?.totalPages || 1);
      setFilteredUsers(usersWithRemainingTime || [])

    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FeatchMutedCommentUser(currentPage, searchTerm, startDate, endDate);
  }, [currentPage, searchTerm, startDate, endDate]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    const filtered = AllMute.filter(
      (user) =>
        user?.user?.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredUsers(filtered);
    setAllMute(filtered)
  };

  //   // Dynamically update the initial page range based on totalPages
  useEffect(() => {
    const initialRange = [];
    const rangeSize = 10;
    const totalVisiblePages = Math.min(rangeSize, totalPages);

    for (let i = 1; i <= totalVisiblePages; i++) {
      initialRange.push(i);
    }
    setPageRange(initialRange);
  }, [totalPages]);

  const updatePageRange = (startPage) => {
    const rangeSize = 10; // Number of pages visible at a time
    const newRange = [];

    for (
      let i = startPage;
      i < startPage + rangeSize && i <= totalPages;
      i++
    ) {
      newRange.push(i);
    }

    setPageRange(newRange);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);

    // If the clicked page is the last in the current range, load the next range
    if (page === pageRange[pageRange.length - 1] && page < totalPages) {
      updatePageRange(page);
    }

    // If the clicked page is the first in the current range and not the first page
    if (page === pageRange[0] && page > 1) {
      updatePageRange(page - 9);
    }
  };

  const handleFirst = () => {
    setCurrentPage(1);
    updatePageRange(1);
  };

  const handleLast = () => {
    setCurrentPage(totalPages);
    const lastRangeStart = Math.max(
      1,
      Math.floor((totalPages - 1) / 10) * 10 + 1
    );
    updatePageRange(lastRangeStart);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      // Check if we are at the start of the current visible range
      if (currentPage === pageRange[0]) {
        // If we are at the first page of the range, move to the previous set of pages
        const previousRangeStart = Math.max(pageRange[0] - 10, 1); // Ensure start page is >= 1
        updatePageRange(previousRangeStart);
      } else {
        setCurrentPage(currentPage - 1);
      }
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      // If we are at the end of the current visible range, load the next range
      if (currentPage === pageRange[pageRange.length - 1] && currentPage < totalPages) {
        updatePageRange(pageRange[pageRange.length - 1]);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const handleGoToPage = () => {
    const page = parseInt(inputPageNumber, 10);

    // Ensure the page number is a valid number and within the valid range
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);

      // Calculate the range for the current page
      const startPage = Math.floor((page - 1) / 10) * 10 + 1;
      updatePageRange(startPage);
    } else {
      alert('Please enter a valid page number!');
    }
  };

  const handleUnMuteComment = async (id) => {
    // Show confirmation alert before proceeding
    const confirmUnmute = window.confirm("Are you sure you want to unmute ?");

    // If the user confirms, proceed with the unmute
    if (confirmUnmute) {
      try {
        // Make an API call to unmute the comment
        const response = await allfeedApis.UnMuteComment(id);

        if (response.success) {
          alert(response.message); // Show success message
          FeatchMutedCommentUser(currentPage, searchTerm); // Refresh the list
        }

      } catch (error) {
        console.error("Error unmuting comment:", error);
      }
    } else {
      console.log("Unmute action canceled.");
    }
  };


  useEffect(() => {
    const intervalId = setInterval(() => {
      setFilteredUsers(mutedUsers => mutedUsers.map(user => {
        if (user.remainingTime <= 0) {
          return { ...user, remainingTime: 0 }; // Ensure time doesn't go negative
        }
        return {
          ...user,
          remainingTime: user.remainingTime - 1000
        };
      }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatRemainingTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const totalHours = Math.floor(totalSeconds / 3600);
    const days = Math.floor(totalHours / 24);
    const hours = totalHours % 24;
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${days}D ${hours}H ${minutes}M ${seconds}S`;
  };
  const handleClean = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchTerm('');
  };
  const handleRowClick = (transactionId) => {
    setSelectedRow(transactionId);
  };
  const handleNoAccess = () => {
    alert('You are not able to access this feature.');
  };
  const handleButtonClick = (muteid, buttonType) => {
    setClickedButton({ muteid, buttonType }); // Set the clicked button
  };

  return (
    <IncludeSideBar>
      <h1>Muted Video Comment </h1>

      <div>
        <input
          type="text"
          placeholder="Search by username"
          value={searchTerm}
          onChange={handleSearch}
        />
        <div>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start Date"
            maxDate={new Date()} // Ensure start date is not in the future
            dateFormat="dd:MM:yyyy" // Format date as dd:mm:yyyy

            style={{
              padding: '5px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              fontSize: '14px',
            }}
          />
          <span style={{ margin: '0 10px' }}>to</span>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            placeholderText="End Date"
            minDate={startDate} // Ensure end date is not before start date
            maxDate={new Date()} // Disable selecting future dates
            dateFormat="dd:MM:yyyy" // Format date as dd:mm:yyyy

            style={{
              padding: '5px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              fontSize: '14px',
            }}
          />
          <button onClick={handleClean}>Clean</button>
        </div>

        {loading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
        {!loading && (
          <table>
            <thead>
              <tr>
                <th> ID</th>
                <th>User Name</th>
                <th>Email</th>

                <th>Reason</th>
                <th>Created Date</th>

                <th>Time</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.length > 0 ? (
                filteredUsers.map((mute) => {
                  return (
                    <tr key={mute.id}
                      onClick={() => handleRowClick(mute.id)}

                      style={{
                        backgroundColor: selectedRow === mute.id ? '#91b7e6' : 'transparent',
                        color: selectedRow === mute.id ? 'white' : 'black',
                        cursor: 'pointer', // Change cursor to pointer for better UX
                      }}
                    >
                      <td>{mute?.id}</td>

                      <td>{mute?.username || "N/A"}</td>
                      <td>{mute?.email || "N/A"}</td>

                      <td>{mute?.mute_reason || "N/A"}</td>
                      <td>{mute?.
                        createdAt || "N/A"}</td>

                      <th>{formatRemainingTime(mute?.remainingTime)}</th>



                      <td>
                        <button

                          onClick={() => {
                            if (hasUnmutevideocommet) {
                              handleUnMuteComment(mute.id)
                              handleButtonClick(mute.id, 'UnMute');

                            } else {
                              handleNoAccess()
                            }
                          }}
                          style={{
                            backgroundColor:
                              !hasUnmutevideocommet
                                ? '#C5C6C7'
                                : '', // Set red background when there is access
                            cursor: !hasUnmutevideocommet ? 'not-allowed' : 'pointer', // Changes cursor when disabled
                          }}
                        >UnMute video Comment</button>

                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="11">No Muted User found</td>
                </tr>
              )}

            </tbody>
          </table>
        )}

        {/* ***************************************************pagination***************** */}
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <button onClick={handleFirst} disabled={currentPage === 1}>
              First
            </button>
            <button onClick={handlePrevious} disabled={currentPage === 1}>
              Previous
            </button>
            {pageRange.map((page) => (
              <button
                key={page}
                onClick={() => handlePageClick(page)}
                style={{
                  padding: "5px 10px",
                  backgroundColor: currentPage === page ? "red" : "#f8f9fa",
                  color: currentPage === page ? "white" : "black",
                  border: "1px solid #ddd",
                  cursor: "pointer",
                }}
              >
                {page}
              </button>
            ))}
            <button onClick={handleNext} disabled={currentPage === totalPages}>
              Next
            </button>
            <button onClick={handleLast} disabled={currentPage === totalPages}>
              Last
            </button>
          </div>
          {/* direct page */}
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
            <input
              type="number"
              value={inputPageNumber}
              onChange={(e) => setInputPageNumber(e.target.value)}
              placeholder="Page No"
              style={{
                padding: '5px',
                fontSize: '14px',
                // border: '1px solid #ccc',
                border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

                borderRadius: '4px 0 0 4px',
                outline: 'none',
                width: '90px'
              }}
              onFocus={() => setInputFocus(true)}
              onBlur={() => setInputFocus(false)}
            />
            <button
              onClick={handleGoToPage}
              style={{
                padding: '6.5px 10px',
                fontSize: '14px',
                border: '1px solid #ccc',
                borderLeft: 'none',
                borderRadius: '0 4px 4px 0',
                // backgroundColor: '#007bff',

                backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
                color: 'white',
                cursor: 'pointer',
                outline: 'none',
              }}


            >
              Go
            </button>
          </div>

        </div>



      </div>
    </IncludeSideBar>
  );
}

export default MuteVideoComment;

