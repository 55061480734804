import React, { useEffect, useState, useCallback } from "react";
import IncludeSideBar from "../../Components/Sidebar/IncludeSideBar";
import useAuth from "../../../src/useAuth";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useDebounce from '../../../src/utils/useDebounce';

function BlockFeed() {
    const allfeedApis = require("../../../src/apis/feed");
    const allUserApis = require("../../../src/apis/users");

    const { user } = useAuth();
    const hasUnMuteComment = user?.permissions?.Feed?.['UnMute Comment']?.selected || user?.role === 'superadmin';

    // console.log(hassubfetureEditComment,'hassubfetureEditComment')
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true); // Add a loading state
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [AllBlock, setAllBlock] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [pageRange, setPageRange] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]); // Visible page range
    const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
    const [inputFocus, setInputFocus] = useState(false);
    const [buttonFocus, setButtonFocus] = useState(false);
    const [expandedPosts, setExpandedPosts] = useState({});
    const [openfeedmodel, setopenfeedmodel] = useState(false)
    const [feedinfo, setfeedinfo] = useState([])
    const [selectedRow, setSelectedRow] = useState(null); // State to track selected row
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [clickedButton, setClickedButton] = useState(null);

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const getAllBlockFeedDetails = async (page, searchTerm = "", startDate = null, endDate = null) => {
        try {
            setLoading(true);
            const filters = { page, searchTerm };

            if (startDate) {
                filters.startDate = startDate.toISOString();
            }

            if (endDate) {
                filters.endDate = endDate.toISOString();
            }
            const response = await allfeedApis.getAllBlockFeedDetails(filters);
            console.log(response, 'response?.data?comment')


            setAllBlock(response?.blockfeeds);
            setTotalPages(response?.pagination?.totalPages || 1);
            setFilteredUsers(response?.blockfeeds || [])

        } catch (error) {
            console.error("Error fetching feed:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllBlockFeedDetails(currentPage, debouncedSearchTerm, startDate, endDate);
    }, [currentPage, debouncedSearchTerm, startDate, endDate]);

    const handleSearch = (e) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);

        const filtered = AllBlock.filter(
            (user) =>
                user?.user?.username.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredUsers(filtered);
        setAllBlock(filtered)
    };

    //   // Dynamically update the initial page range based on totalPages
    useEffect(() => {
        const initialRange = [];
        const rangeSize = 10;
        const totalVisiblePages = Math.min(rangeSize, totalPages);

        for (let i = 1; i <= totalVisiblePages; i++) {
            initialRange.push(i);
        }
        setPageRange(initialRange);
    }, [totalPages]);

    const updatePageRange = (startPage) => {
        const rangeSize = 10; // Number of pages visible at a time
        const newRange = [];

        for (
            let i = startPage;
            i < startPage + rangeSize && i <= totalPages;
            i++
        ) {
            newRange.push(i);
        }

        setPageRange(newRange);
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);

        // If the clicked page is the last in the current range, load the next range
        if (page === pageRange[pageRange.length - 1] && page < totalPages) {
            updatePageRange(page);
        }

        // If the clicked page is the first in the current range and not the first page
        if (page === pageRange[0] && page > 1) {
            updatePageRange(page - 9);
        }
    };

    const handleFirst = () => {
        setCurrentPage(1);
        updatePageRange(1);
    };

    const handleLast = () => {
        setCurrentPage(totalPages);
        const lastRangeStart = Math.max(
            1,
            Math.floor((totalPages - 1) / 10) * 10 + 1
        );
        updatePageRange(lastRangeStart);
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            // Check if we are at the start of the current visible range
            if (currentPage === pageRange[0]) {
                // If we are at the first page of the range, move to the previous set of pages
                const previousRangeStart = Math.max(pageRange[0] - 10, 1); // Ensure start page is >= 1
                updatePageRange(previousRangeStart);
            } else {
                setCurrentPage(currentPage - 1);
            }
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            // If we are at the end of the current visible range, load the next range
            if (currentPage === pageRange[pageRange.length - 1] && currentPage < totalPages) {
                updatePageRange(pageRange[pageRange.length - 1]);
            } else {
                setCurrentPage(currentPage + 1);
            }
        }
    };

    const handleGoToPage = () => {
        const page = parseInt(inputPageNumber, 10);

        // Ensure the page number is a valid number and within the valid range
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);

            // Calculate the range for the current page
            const startPage = Math.floor((page - 1) / 10) * 10 + 1;
            updatePageRange(startPage);
        } else {
            alert('Please enter a valid page number!');
        }
    };


    const handleClean = () => {
        setStartDate(null);
        setEndDate(null);
        setSearchTerm('');
    };
    const handleRowClick = (transactionId) => {
        setSelectedRow(transactionId);
    };
    const handleNoAccess = () => {
        alert('You are not able to access this feature.');
    };
    const handleButtonClick = (muteid, buttonType) => {
        setClickedButton({ muteid, buttonType }); // Set the clicked button
    };

    const UnBlockFeed = async (id) => {
        const confirmunblock = window.confirm("Are you sure you want to UnBlock Feed ?");

        if (confirmunblock) {
            try {
                const response = await allfeedApis.UnBlockFeed(id);

                if (response.success) {
                    alert(response.message);
                    getAllBlockFeedDetails(currentPage, debouncedSearchTerm, startDate, endDate);

                }

            } catch (error) {
                console.error("Error unblock feed:", error);
            }
        } else {
            console.log("unblock canceled.");
        }
    }

    return (
        <IncludeSideBar>
            <h1>Block Feed</h1>

            <div>
                <input
                    type="text"
                    placeholder="Search by username"
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <div>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText="Start Date"
                        maxDate={new Date()} // Ensure start date is not in the future
                        dateFormat="dd:MM:yyyy" // Format date as dd:mm:yyyy

                        style={{
                            padding: '5px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            fontSize: '14px',
                        }}
                    />
                    <span style={{ margin: '0 10px' }}>to</span>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText="End Date"
                        minDate={startDate} // Ensure end date is not before start date
                        maxDate={new Date()} // Disable selecting future dates
                        dateFormat="dd:MM:yyyy" // Format date as dd:mm:yyyy

                        style={{
                            padding: '5px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            fontSize: '14px',
                        }}
                    />
                    <button onClick={handleClean} >Clean</button>
                </div>
                {loading && (
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                )}
                {!loading && (
                    <table>
                        <thead>
                            <tr>
                                <th> ID</th>
                                <th>Profile Pic</th>

                                <th>User ID</th>

                                <th>User Name</th>
                                <th>Email</th>

                                <th>Updated AT</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.length > 0 ? (
                                filteredUsers.map((block) => {
                                    return (
                                        <tr key={block.id}
                                            onClick={() => handleRowClick(block.id)}

                                            style={{
                                                backgroundColor: selectedRow === block.id ? '#91b7e6' : 'transparent',
                                                color: selectedRow === block.id ? 'white' : 'black',
                                                cursor: 'pointer', // Change cursor to pointer for better UX
                                            }}
                                        >
                                            <td>{block?.id}</td>
                                            <td>
                                                {block?.user?.profile_pic ? (
                                                    <img
                                                        src={block?.user?.profile_pic}
                                                        alt="Profile"
                                                        // onClick={() => showfeed(block)}

                                                        style={{
                                                            width: "50px",
                                                            height: "50px",
                                                            borderRadius: "20%",
                                                        }}
                                                    />
                                                ) : (
                                                    "N/A"
                                                )}
                                            </td>
                                            <td>{block?.user?.id}</td>



                                            <td>{block?.user?.username || "N/A"}</td>
                                            <td>{block?.user?.email || "N/A"}</td>


                                            <td>
                                                {block.updatedAt
                                                    ? new Date(block.updatedAt).toLocaleDateString()
                                                    : "N/A"}
                                            </td>




                                            <td>

                                                <button
                                                    onClick={() => {
                                                        UnBlockFeed(block?.id)
                                                    }}

                                                >UnBlock Feed</button>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="11">No Block Feed found</td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                )}
                {/* ***************************************************pagination***************** */}
                <div>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <button onClick={handleFirst} disabled={currentPage === 1}>
                            First
                        </button>
                        <button onClick={handlePrevious} disabled={currentPage === 1}>
                            Previous
                        </button>
                        {pageRange.map((page) => (
                            <button
                                key={page}
                                onClick={() => handlePageClick(page)}
                                style={{
                                    padding: "5px 10px",
                                    backgroundColor: currentPage === page ? "red" : "#f8f9fa",
                                    color: currentPage === page ? "white" : "black",
                                    border: "1px solid #ddd",
                                    cursor: "pointer",
                                }}
                            >
                                {page}
                            </button>
                        ))}
                        <button onClick={handleNext} disabled={currentPage === totalPages}>
                            Next
                        </button>
                        <button onClick={handleLast} disabled={currentPage === totalPages}>
                            Last
                        </button>
                    </div>
                    {/* direct page */}
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
                        <input
                            type="number"
                            value={inputPageNumber}
                            onChange={(e) => setInputPageNumber(e.target.value)}
                            placeholder="Page No"
                            style={{
                                padding: '5px',
                                fontSize: '14px',
                                // border: '1px solid #ccc',
                                border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

                                borderRadius: '4px 0 0 4px',
                                outline: 'none',
                                width: '90px'
                            }}
                            onFocus={() => setInputFocus(true)}
                            onBlur={() => setInputFocus(false)}
                        />
                        <button
                            onClick={handleGoToPage}
                            style={{
                                padding: '6.5px 10px',
                                fontSize: '14px',
                                border: '1px solid #ccc',
                                borderLeft: 'none',
                                borderRadius: '0 4px 4px 0',
                                // backgroundColor: '#007bff',

                                backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
                                color: 'white',
                                cursor: 'pointer',
                                outline: 'none',
                            }}


                        >
                            Go
                        </button>
                    </div>

                </div>

            </div>
        </IncludeSideBar>
    );
}

export default BlockFeed;

