import React, { useState, useEffect } from 'react'
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar'
import useAuth from "../../../useAuth";
import { BsJustify } from "react-icons/bs";
import '../All/AllUser.css'


function BusinessUsers() {
  const { user } = useAuth();
  const role = user ? user.role : null;
  console.log(user?.permissions?.Users?.['View Details'], "business aroooolllee user?.permissions?.Users")
  const hasViewAccess = user?.permissions?.Users?.['View Details']?.selected || user?.role === 'superadmin';
  const hassendcoin = user?.permissions?.Users?.['send coin']?.selected || user?.role === 'superadmin';
  const hasChangeAccountType = user?.permissions?.Users?.['Change Account Type']?.selected || user?.role === 'superadmin';
  const hasVerificationRequest = user?.permissions?.Users?.['Verification Request']?.selected || user?.role === 'superadmin';

  const allUserApis = require("../../../apis/users");
  const sendbusinessMoneyApis = require("../../../apis/super_admin_transaction");
  const alluserverificationrequestapi = require('../../../apis/verification_request_by_user');

  const [BusinessUsers, setBusinessUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isFocused, setIsFocused] = useState(false)
  const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);
  const [pageWindow, setPageWindow] = useState({ start: 1, end: 20 });

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [issendcoinModalOpen, setsendcoinModalOpen] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);

  const [isaccountModalOpen, setIsaccountModalOpen] = useState(false);
  const [currentaccountUserId, setCurrentaccountUserId] = useState(null);
  const [accountTypes, setAccountTypes] = useState({});
  const [activeButton, setActiveButton] = useState(null); // State to track the active button

  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [verificationLink, setVerificationLink] = useState('');
  const [verificationUserId, setVerificationUserId] = useState(null);
  const [selectedVerificationType, setSelectedVerificationType] = useState('');

  const fetchBusinessUsers = async (page, searchTerm = '') => {
    try {
      setIsLoading(true)
      const response = await allUserApis.getAllBusinessUser(page, searchTerm);
      setBusinessUsers(response.data);
      setFilteredUsers(response.data)
      setTotalPages(response.pagination.totalPages);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBusinessUsers(currentPage, searchTerm);
  }, [currentPage, searchTerm]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    const filtered = BusinessUsers.filter(
      (user) =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredUsers(filtered);
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setPageWindow({
        start: Math.floor((currentPage - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
      });
    }

  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      // setCurrentPage((prevPage) => prevPage - 1);
      setCurrentPage(currentPage - 1);
      setPageWindow({
        start: Math.floor((currentPage - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
      });

    }
  };

  const handleNextPageWindow = () => {
    if (pageWindow.end < totalPages) {
      setPageWindow({
        start: pageWindow.start + 19,
        end: Math.min(pageWindow.end + 19, totalPages),
      });
    }
  };

  const handlePreviousPageWindow = () => {
    if (pageWindow.start > 1) {
      setPageWindow({
        start: pageWindow.start - 20,
        end: pageWindow.end - 20,
      });
    }
  };

  const handlePageClick = (page) => {
    handlePageChange(page);
    if (page === pageWindow.end && pageWindow.end < totalPages) {
      handleNextPageWindow();
    } else if (page === pageWindow.start && pageWindow.start > 1) {
      handlePreviousPageWindow();
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setPageWindow({
      start: Math.floor((totalPages - 1) / 20) * 20 + 1,
      end: totalPages,
    });
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    setPageWindow({
      start: 1,
      end: Math.min(20, totalPages),
    });
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(inputPageNumber, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setPageWindow({
        start: Math.floor((pageNumber - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((pageNumber - 1) / 20) * 20 + 20, totalPages),
      });
      setInputPageNumber('');
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  const showDetails = (userId) => {
    const user = BusinessUsers.find((user) => user.id === userId);
    setSelectedUser(user);
    setSelectedUserId(userId);
  };

  const closeDetails = () => {
    setSelectedUser(null);
  };

  // /model coin open
  const opensendcoinModal = (userId) => {
    if (role === "superadmin" || hassendcoin) {
      setsendcoinModalOpen(true);
      setCurrentUserId(userId)
    } else {
      alert("Access Denied: Only superadmins can send coins.");
    }
  };

  const closesendcoinModal = () => {
    setsendcoinModalOpen(false);
  };
  // const sendCoins = async (userId, amount) => {
  //   try {
  //     const response = await sendbusinessMoneyApis.SendMoneyToUser({ userId, amount });
  //     console.log(response, 'response')
  //     if (response.data.message === 'Money sent successfully') {
  //       alert(`Successfully sent ${amount} coins to user with ID: ${user.id}`)
  //     }

  //     console.log(`Successfully sent ${amount} coins to user with ID: ${user.id}`);
  //   } catch (error) {
  //     console.error("Error sending coins:", error);
  //   }
  // };

  // const sendCoins = async (userId, amount) => {
  //   try {
  //     // Check if the user has permission to send coins (either superadmin or hassendcoin)
  //     if (role === "superadmin" || hassendcoin) {

  //       if (amount !== null && amount.trim() !== "") {
  //         // Send the money using the API
  //         const response = await sendbusinessMoneyApis.SendMoneyToUser({ userId, amount, hassendcoin });

  //         // Check if the response is successful
  //         if (response.data.success) {
  //           alert(`Successfully sent ${amount} coins to user `);
  //         } else {
  //           // Show error message if the API call fails
  //           alert(response.data.message);
  //         }
  //       } else {
  //         alert("Amount is required!");
  //       }
  //     } else {
  //       alert("Access Denied: Only superadmins or permitted users can send coins.");
  //     }
  //   } catch (error) {
  //     console.error("Error sending coins:", error);
  //   }
  // };
  const sendCoins = async (userId, amount) => {
    try {
      // Check if the user has permission to send coins (either superadmin or hassendcoin)
      if (role === "superadmin" || hassendcoin) {
        if (amount !== null && amount.trim() !== "") {
          const parsedAmount = parseFloat(amount.trim()); // Convert input to a number

          // Validate the amount is a positive number
          if (!isNaN(parsedAmount) && parsedAmount > 0) {
            // Send the money using the API
            const response = await sendbusinessMoneyApis.SendMoneyToUser({ userId, amount: parsedAmount, hassendcoin });

            // Check if the response is successful
            if (response.data.success) {
              alert(`Successfully sent ${parsedAmount} coins to the user.`);
            } else {
              // Show error message if the API call fails
              alert(response.data.message);
            }
          } else {
            alert("Please enter a valid positive number. Negative or invalid inputs are not allowed.");
          }
        } else {
          alert("Amount is required!");
        }
      } else {
        alert("Access Denied: Only superadmins or permitted users can send coins.");
      }
    } catch (error) {
      console.error("Error sending coins:", error);
    }
  };

  const handleAccountTypeChange = async (accountType) => {
    try {
      // Assuming role is passed as a prop or accessible from the component's state
      if (role === "superadmin" || hasChangeAccountType) {
        // Perform the account type change
        await allUserApis.changeUserAccountType(currentaccountUserId, accountType);
        // console.log(`Successfully changed account type to ${accountType} for user with ID: ${currentaccountUserId}`);
        // Update the account types state with the new account type
        setAccountTypes((prevTypes) => ({
          ...prevTypes,
          [currentaccountUserId]: accountType,
        }));
        await fetchBusinessUsers(currentPage, searchTerm);

        handleCloseModal();
        alert(`Successfully change account`)

        // Fetch updated user data
        console.log("fetchUsers called");
      } else {
        // If the user is not a superadmin, display an error message
        alert("Access Denied: Only superadmins can change account types.");
      }
    } catch (error) {
      console.error("Error changing account type:", error);
    }
  };
  const handleOpenModal = (userId) => {
    setCurrentaccountUserId(userId);
    setIsaccountModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsaccountModalOpen(false);
    setCurrentaccountUserId(null);
  };
  const handleButtonClick = (userId, buttonType) => {
    setActiveButton({ userId, buttonType });
  };

  const handleOpenVerificationModal = (userId) => {
    setVerificationUserId(userId);
    setIsVerificationModalOpen(true);
  };
  const handleGenerateVerificationLink = async () => {
    try {
      const data = {
        verificationUserId: verificationUserId
      }
      const response = await allUserApis.generateVerificationLink(data);
      setVerificationLink(response.payload);
    } catch (error) {
      console.error("Error generating verification link:", error);
    }
  };
  //scan verification link
  const handleGeneratescanVerificationLink = async () => {
    try {
      const data = {
        verificationUserId: verificationUserId
      }
      const response = await allUserApis.generateVerificationscanLink(data);
      setVerificationLink(response.payload);
    } catch (error) {
      console.error("Error generating verification link:", error);
    }
  };

  const handleVerificationRequest = async () => {
    try {
      const data = {
        userId: verificationUserId,
        verificationLink: verificationLink

      }
      console.log(data, 'data for scan')
      const response = await allUserApis.UserverificationAccountrequest(data);
      if (response.success) {
        alert("Verification request processed successfully");
        handleCloseVerificationModal();
      } else {
        alert(response.message);
      }
    } catch (error) {
      console.error("Error handleVerificationRequest:", error);
    }
  };



  const handleGenerateVerification = () => {
    // Assuming you have different API calls for different verification types
    if (selectedVerificationType === 'link') {
      handleGenerateVerificationLink()
    } else if (selectedVerificationType === 'scan') {
      // API call for generating scan document link
      handleGeneratescanVerificationLink()
      // setVerificationLink('Generated Scan Document Link');
    }
  };
  const handleVerificationTypeChange = (event) => {
    setSelectedVerificationType(event.target.value);
  };
  const handleDirectApprove = async (badge) => {

    const confirmed = window.confirm("Are you sure you want to approve this verification request?");
    if (confirmed) {
      const data = {
        userid: verificationUserId,
        badge: badge
      }
      //  console.log(data.userid, 'user_iduser_iduser_id');


      setIsLoading(true);
      try {
        const response = await alluserverificationrequestapi.verificationrequestapprovedbyadmin(data);
        handleCloseVerificationModal();

        if (response.success) {
          // Show success message
          alert("Verification request approved successfully");
          // Optionally, update UI or fetch updated data
        } else {
          // Show message returned from backend
          alert(response.message);
        }
      } catch (error) {
        console.error("Error handleapproved:", error);
        alert("Failed to handle approval");
      } finally {
        setIsLoading(false);
      }
    }


  };
  const handleCloseVerificationModal = () => {
    setIsVerificationModalOpen(false);
    setVerificationLink('');
    setVerificationUserId(null);
  };
  const handleNoAccess = () => {
    alert('You are not able to access this feature.');
  };
  return (
    <IncludeSideBar>
      <div>
        <h1>Business  Users</h1>
        <input
          type="text"
          placeholder="Search by username or email"
          value={searchTerm}
          onChange={handleSearch}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={{
            borderWidth: isFocused ? '10px' : '1px',
            borderStyle: 'solid',
            borderColor: isFocused ? 'green' : 'white',
          }}

        />
        {isLoading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : (
          <div>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Account Type</th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td>{user.account_type}</td>

                    <td>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <button onClick={() => {
                          if (hasViewAccess) {
                            showDetails(user.id);
                            handleButtonClick(user.id, 'viewDetails');
                          } else {
                            handleNoAccess()
                          }

                        }}
                          style={{
                            backgroundColor:
                              activeButton?.userId === user.id &&
                                activeButton?.buttonType === 'viewDetails'
                                ? 'red'
                                : !hasViewAccess
                                  ? '#C5C6C7'
                                  : '',
                            cursor: !hasViewAccess ? 'not-allowed' : 'pointer',
                          }}
                        >View Details</button>

                        <button
                          style={{
                            marginLeft: '1%',
                            backgroundColor:
                              activeButton?.userId === user.id &&
                                activeButton?.buttonType === 'sendCoin'
                                ? 'red'
                                : !hassendcoin
                                  ? '#C5C6C7'
                                  : '',
                            cursor: !hassendcoin ? 'not-allowed' : 'pointer',
                          }}
                          onClick={() => {
                            if (hassendcoin) {
                              opensendcoinModal(user.id);
                              handleButtonClick(user.id, 'sendCoin');

                            } else {
                              handleNoAccess()
                            }
                          }}
                        >
                          Send Coin
                        </button>

                        <button onClick={() => {
                          if (hasChangeAccountType) {
                            handleOpenModal(user.id);
                            handleButtonClick(user.id, 'changeAccountType');

                          } else {
                            handleNoAccess()
                          }
                        }}
                          style={{
                            backgroundColor:
                              activeButton?.userId === user.id &&
                                activeButton?.buttonType === 'changeAccountType'
                                ? 'red'
                                : !hasChangeAccountType
                                  ? '#C5C6C7'
                                  : '',
                            cursor: !hasChangeAccountType ? 'not-allowed' : 'pointer',
                          }}>
                          Change Account Type
                        </button>
                        <button
                          onClick={() => {
                            if (hasVerificationRequest) {
                              handleOpenVerificationModal(user.id);
                              handleButtonClick(user.id, 'verificationRequest');

                            } else {
                              handleNoAccess()
                            }
                          }}
                          style={{
                            backgroundColor:
                              activeButton?.userId === user.id &&
                                activeButton?.buttonType === 'verificationRequest'
                                ? 'red'
                                : !hasVerificationRequest
                                  ? '#C5C6C7'
                                  : '',
                            cursor: !hasChangeAccountType ? 'not-allowed' : 'pointer',
                          }}
                        >
                          Verification Request
                        </button>
                      </div>


                    </td>
                  </tr>
                ))}
              </tbody>

            </table>
            <SendBusinessCoinsModal
              isOpen={issendcoinModalOpen}
              onClose={closesendcoinModal}
              onSend={sendCoins}
              userId={currentUserId}
            />
            <BusinessAccountTypeModal
              isOpen={isaccountModalOpen}
              onClose={handleCloseModal}
              onSave={handleAccountTypeChange}
              currentAccountType={accountTypes[currentaccountUserId] || "Select Account"}
            />
            <VerificationModal
              isVerificationModalOpen={isVerificationModalOpen}
              selectedVerificationType={selectedVerificationType}
              handleVerificationTypeChange={handleVerificationTypeChange}
              handleGenerateVerification={handleGenerateVerification}
              verificationLink={verificationLink}
              handleVerificationRequest={handleVerificationRequest}
              handleCloseVerificationModal={handleCloseVerificationModal}
              handleDirectApprove={handleDirectApprove}
            />
            <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
              {
                filteredUsers.length === 0 && <p>
                  NO Data Available
                </p>
              }
            </div>
            {selectedUser && (

              <div style={{ display: "block", position: "fixed", zIndex: 1, left: 0, top: 0, width: "100%", height: "100%", overflow: "auto", backgroundColor: "rgba(0,0,0,0.4)" }}>
                <div style={{ backgroundColor: "#fefefe", border: "1px solid #888", margin: "10% auto", padding: "20px", width: "60%", marginLeft: "30%" }}>
                  <span style={{ float: "right", cursor: "pointer" }} onClick={closeDetails}>
                    &times;
                  </span>
                  <h2>User Details</h2>
                  <table>
                    <tbody>
                      <tr>
                        <td><strong>Account Type:</strong></td>
                        <td>{selectedUser.account_type}</td>

                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Role:
                          </strong>
                        </td>
                        <td>
                          {selectedUser.role}
                        </td>
                      </tr>

                      <tr>
                        <td><strong>Active</strong></td>
                        <td><strong>{selectedUser.active}</strong></td>
                      </tr>

                      <tr>
                        <td><strong>ID:</strong></td>
                        <td>{selectedUser.id}</td>
                      </tr>
                      <tr>
                        <td><strong>Username:</strong></td>
                        <td>{selectedUser.username}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Nickname:
                          </strong>
                        </td>
                        <td>
                          {selectedUser.nickname}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Email:</strong></td>
                        <td>{selectedUser.email}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Gender:
                          </strong>
                        </td>
                        <td>
                          {
                            selectedUser.gender
                          }
                        </td>
                      </tr>

                      <tr>
                        <td><strong> Bio:</strong></td>
                        <td>{selectedUser.bio}</td>
                      </tr>
                      <tr>
                        <td><strong>D.O.B:</strong></td>
                        {/* <td>{selectedUser.dob}</td> */}
                        <td>{selectedUser.dob ? new Date(selectedUser.dob).toLocaleDateString('en-US') : 'N/A'}</td>

                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Person Height:
                          </strong>
                        </td>
                        <td>
                          {selectedUser.person_height}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Person Weight:
                          </strong>
                        </td>
                        <td>
                          {selectedUser.person_weight}
                        </td>
                      </tr>

                      <tr>
                        <td><strong>Country:</strong></td>
                        <td>{selectedUser.country}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>City:</strong>
                        </td>
                        <td>{selectedUser.city}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Phone:
                          </strong>
                        </td>
                        <td>
                          {selectedUser.phone}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>
                            Device:
                          </strong>
                        </td>
                        <td>
                          {
                            selectedUser.device
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Wallet:
                          </strong>
                        </td>
                        <td>
                          {selectedUser.wallet}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>
                            Emotion state:
                          </strong>
                        </td>
                        <td>
                          {selectedUser.emotion_state}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Facebook:
                          </strong>
                        </td>
                        <td>
                          {
                            selectedUser.facebook
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Instagram:
                          </strong>
                        </td>
                        <td>
                          {
                            selectedUser.instagram
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Twitter:
                          </strong>
                        </td>
                        <td>
                          {selectedUser.twitter}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            You Tube:
                          </strong>
                        </td>
                        <td>
                          {selectedUser.you_tube}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>
                            Hobbies:
                          </strong>
                        </td>
                        <td>
                          {Array.isArray(selectedUser?.hobbies) && selectedUser.hobbies.length > 0 ? (
                            <ul>
                              {selectedUser.hobbies.map((hobby, index) => (
                                <li key={index}>{hobby}</li>
                              ))}
                            </ul>
                          ) : (
                            "No hobbies available"
                          )}

                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Language:
                          </strong>
                        </td>
                        <td>
                          {selectedUser.language}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Making Friend Intention:
                          </strong>
                        </td>
                        <td>
                          {selectedUser.making_friend_intention}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>
                            Occupation:
                          </strong>
                        </td>
                        <td>
                          {selectedUser.occupation}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Website:
                          </strong>
                        </td>
                        <td>
                          {selectedUser.website}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Account Created Date
                          </strong>
                        </td>
                        <td>
                          {new Date(selectedUser.creationDate).toLocaleDateString('en-GB')}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Location
                          </strong>
                        </td>
                        <td>
                          {selectedUser.Location},{selectedUser.LiveCity}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Device Info
                          </strong>
                        </td>
                        <td>
                          <ul>
                            <li>DeviceBrand :- {selectedUser.deviceBrand}</li>
                            <li> DeviceModel :- {selectedUser.deviceModel}</li>
                            <li> DeviceOsVersion :- {selectedUser.osVersion}</li>
                            <li>DeviceName :- {selectedUser.deviceName}</li>
                          </ul>
                        </td>
                      </tr>

                      {/* ... (your existing code for user details country) */}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        )}


        <div className="pagination">
          <button onClick={handleFirstPage} disabled={currentPage === 1}>
            First
          </button>
          <button onClick={handlePrevious} disabled={currentPage === 1}>
            Previous
          </button>
          {Array.from(
            {
              length: Math.min(pageWindow.end - pageWindow.start + 1, totalPages)
            },
            (_, index) => pageWindow.start + index
          ).map((page) => (
            <button
              key={page}
              onClick={() => handlePageClick(page)}
              className={currentPage === page ? 'active' : ''}
              style={{
                backgroundColor: currentPage === page ? 'red' : '',
                color: 'white',
              }}
            >
              {page}
            </button>
          ))}
          <button onClick={handleNext} disabled={currentPage === totalPages}>
            Next
          </button>
          <button onClick={handleLastPage} disabled={currentPage === totalPages}>
            Last
          </button>
        </div>



        {/* direct page */}
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
          <input
            type="number"
            value={inputPageNumber}
            onChange={(e) => setInputPageNumber(e.target.value)}
            placeholder="Page No"
            style={{
              padding: '5px',
              fontSize: '14px',
              // border: '1px solid #ccc',
              border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

              borderRadius: '4px 0 0 4px',
              outline: 'none',
              width: '90px'
            }}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
          />
          <button
            onClick={handleGoToPage}
            style={{
              padding: '6.5px 10px',
              fontSize: '14px',
              border: '1px solid #ccc',
              borderLeft: 'none',
              borderRadius: '0 4px 4px 0',
              // backgroundColor: '#007bff',

              backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
              color: 'white',
              cursor: 'pointer',
              outline: 'none',
            }}


          >
            Go
          </button>
        </div>



      </div>

    </IncludeSideBar >
  )
}

export default BusinessUsers


const SendBusinessCoinsModal = ({ isOpen, onClose, onSend, userId }) => {
  const [amount, setAmount] = useState('');

  const handleSend = () => {
    if (amount.trim() !== "") {
      onSend(userId, amount);
      setAmount('');
      onClose();
    } else {
      alert("Please enter a valid amount.");
    }
  };

  if (!isOpen) return null;

  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    background: 'white',
    padding: '30px',
    borderRadius: '8px',
    width: '300px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '16px',
  };

  const buttonStyle = {
    padding: '10px 20px',
    margin: '10px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
  };

  const sendButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#4CAF50',
    color: 'white',
  };

  const cancelButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#f44336',
    color: 'white',
  };

  return (
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <h2>Send Coins</h2>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Enter amount"
          style={inputStyle}
        />
        <div>
          <button onClick={handleSend} style={sendButtonStyle}>Send</button>
          <button onClick={onClose} style={cancelButtonStyle}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

const BusinessAccountTypeModal = ({ isOpen, onClose, onSave, currentAccountType }) => {
  const [selectedAccountType, setSelectedAccountType] = React.useState(currentAccountType);

  const handleSave = () => {
    onSave(selectedAccountType);
  };

  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999
  };

  const modalContentStyle = {
    background: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '300px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    textAlign: 'center',
    position: 'relative'
  };

  const selectStyle = {
    width: '100%',
    padding: '10px',
    marginBottom: '20px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '16px',
    boxSizing: 'border-box',
    outline: 'none'
  };

  const buttonStyle = {
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    fontSize: '16px',
    transition: 'background-color 0.3s ease',
    marginRight: '10px'
  };

  const cancelButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#6c757d'
  };

  return isOpen ? (
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <h2 style={{ marginBottom: '20px' }}>Change Account Type</h2>
        <select
          value={selectedAccountType}
          onChange={(e) => setSelectedAccountType(e.target.value)}
          style={selectStyle}
        >
          <option value="Select Account">Select Account Type</option>
          <option value="basic">Basic</option>
          <option value="premium">Premium</option>
        </select>
        <button onClick={handleSave} style={buttonStyle}>Save</button>
        <button onClick={onClose} style={cancelButtonStyle}>Cancel</button>
      </div>
    </div>
  ) : null;
};

const VerificationModal = ({
  isVerificationModalOpen,
  selectedVerificationType,
  handleVerificationTypeChange,
  handleGenerateVerification,
  verificationLink,
  handleVerificationRequest,
  handleCloseVerificationModal,
  handleDirectApprove
}) => {
  const [selectedBadge, setSelectedBadge] = useState('');

  const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '500px',
    textAlign: 'center',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  };

  const dropdownStyle = {
    padding: '10px',
    width: '100%',
    borderRadius: '4px',
    border: '1px solid #ccc',
    margin: '10px 0',
  };

  const buttonStyle = {
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
    marginTop: '10px',

  };
  const buttongenwrap = {
    marginRight: '5px'
  }
  const buttonwraper = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  }
  const badgeOptions = [
    'Simple',
    'Verified Top 1',
    // 'Business Account',
    'Premium Users',
    'Agent'
  ];

  const handleBadgeChange = (e) => {
    setSelectedBadge(e.target.value);
  };

  const handleDirectApproveClick = () => {
    handleDirectApprove(selectedBadge);
  };

  return (
    isVerificationModalOpen && (
      <div style={modalStyle}>
        <div style={modalContentStyle}>
          <h2>Verification Request</h2>
          <div>
            <div>
              <input
                type="radio"
                id="link"
                name="verificationType"
                value="link"
                checked={selectedVerificationType === 'link'}
                onChange={handleVerificationTypeChange}
              />
              <label htmlFor="link">Generate Verification Link</label>
            </div>
            <div>
              <input
                type="radio"
                id="scan"
                name="verificationType"
                value="scan"
                checked={selectedVerificationType === 'scan'}
                onChange={handleVerificationTypeChange}
              />
              <label htmlFor="scan">Generate Scan Document Link</label>
            </div>
            <div style={buttonwraper}>
              <div style={buttongenwrap}>
                <button onClick={handleGenerateVerification} style={buttonStyle}>
                  Generate Link
                </button>
              </div>


              <button onClick={handleVerificationRequest} style={buttonStyle}>
                Send Verification Request
              </button>
            </div>
            {verificationLink && <p>{verificationLink}</p>}

          </div>
          <div style={{ marginTop: '20px' }}>
            <h3>Select Badge for Direct Approval</h3>
            <select
              value={selectedBadge}
              onChange={handleBadgeChange}
              style={dropdownStyle}
            >
              <option value="" disabled>Select a badge</option>
              {badgeOptions.map((badge, index) => (
                <option key={index} value={badge}>
                  {badge}
                </option>
              ))}
            </select>
            <button onClick={handleDirectApproveClick} style={buttonStyle} disabled={!selectedBadge}>
              Direct Approve
            </button>
          </div>
          <button onClick={handleCloseVerificationModal} style={{ ...buttonStyle, backgroundColor: '#6c757d' }}>
            Close
          </button>
        </div>
      </div>
    )
  );
};
