import { React, useEffect, useState } from "react";
import IncludeSideBar from "../../Components/Sidebar/IncludeSideBar";

function UpdatePermission() {
  const EmployeeApis = require('../../apis/employee')
  const [loading, setLoading] = useState(false);

  const [sections, setSections] = useState([]); // State to hold sections and features
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [PermissionDataByid, setPermissionDataByid] = useState([])
  const getAllAdminPermissionList = async () => {
    try {
      const response = await EmployeeApis.GetAllAdminPermissionsData();
      console.log(response.permissions, "response all permission data");

      setSections(response.permissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  useEffect(() => {
    getAllAdminPermissionList();
  }, []); // Fetch permissions when the component mounts


  const handleOpenFormModal = () => {
    setIsFormModalOpen(true);
  };

  const handleCloseFormModal = () => {
    setIsFormModalOpen(false);
  };

  const handlePermissionSubmitForm = async (formData) => {

    // Set loading to true when the submission starts
    setLoading(true);
    console.log(formData, 'formDataformDataformData update')
    try {
      // Make API call to submit form data
      const response = await EmployeeApis.UpdateFeatureAndPermission(formData);

      // Check for success status in the response
      if (response.success) {
        // Display a success message
        alert('Permission Updated successfully!');

        setIsFormModalOpen(false);
      }

    } catch (error) {
      // Log the error and display an error message to the user
      console.error('Error adding permission:', error.message);
      alert('Error adding permission. Please try again.');

    } finally {
      // Hide the loader once the process is complete
      setLoading(false);
    }
  };

  const GetPermission = async (id) => {
    try {
      const response = await EmployeeApis.GetPermissionById(id);
      console.log(response.permissionDetail, 'GetPermissionGetPermissionGetPermission')
      setPermissionDataByid(response.permissionDetail)
    } catch (e) {
      console.log(e)
    }
  }

  const deletepermissionSection = async (id) => {
    try {
      // Display a confirmation alert before proceeding with deletion
      const confirmDelete = window.confirm("Are you sure you want to delete this Section ?");

      if (confirmDelete) {
        await EmployeeApis.DeletePermissionById(id);
        // Refresh the employee list after deletion
        getAllAdminPermissionList();
      } else {
        // If the user cancels the deletion, you can perform any other action here or simply return
        console.log('Deletion canceled by the user.');
        return;
      }
    } catch (error) {
      console.error('Error deleting employee:', error);
    }
  };


  return (
    <IncludeSideBar>
      <div>
        <div className="Header"> UPDATE PERMISSION SECTION</div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Section</th>
              <th>Feature</th>
              <th>Action</th>


            </tr>
          </thead>
          <tbody>
            {sections.map((sections) => (
              <tr key={sections.id}>
                <td>{sections.id}</td>

                <td>{sections.section}</td>
                <td>
                  {Array.isArray(sections.feature) ? (
                    <ul>
                      {sections.feature.map((feature, index) => (
                        <li key={index}>{feature.value}</li>
                      ))}
                    </ul>
                  ) : (
                    <span>{sections.feature}</span> // For cases where `feature` is not an array
                  )}
                </td>

                <td>
                  <button onClick={() => {
                    handleOpenFormModal(); GetPermission(sections.id)
                  }} style={{marginRight:'10px'}} >Update permission</button>
                   <button onClick={()=>{
                    deletepermissionSection(sections.id)
                   }}
                  >Delete</button>
                </td>
               

              </tr>
            ))}
          </tbody>
        </table>


      </div>
      <UpdateFeatureAndPermission
        isFormModalOpen={isFormModalOpen}
        handleCloseFormModal={handleCloseFormModal}
        handlePermissionSubmitForm={handlePermissionSubmitForm}
        PermissionDataByid={PermissionDataByid}
      />
    </IncludeSideBar>
  );
}

export default UpdatePermission;

// const UpdateFeatureAndPermission = ({
//   isFormModalOpen,
//   handleCloseFormModal,
//   handlePermissionSubmitForm,
//   PermissionDataByid
// }) => {
//   const [formData, setFormData] = useState({
//     addSection: '',
//     features: [{ value: '', enabled: false }], // Initialize with one feature object
//   });

//   // Populate form when PermissionDataByid is available
//   useEffect(() => {
//     if (PermissionDataByid) {
//       setFormData({
//         addSection: PermissionDataByid.section || '', // Set the section from PermissionDataByid
//         features: PermissionDataByid.feature?.map((feature) => ({
//           value: feature.value || '',
//           enabled: feature.enabled || false
//         })) || [{ value: '', enabled: false }], // Populate features or initialize with one
//         id: PermissionDataByid.id || '', // Ensure the ID is also included

//       });
//     }
//   }, [PermissionDataByid]);

//   const modalStyle = {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     zIndex: 1000,
//   };

//   const modalContentStyle = {
//     backgroundColor: '#fff',
//     padding: '20px',
//     borderRadius: '8px',
//     width: '600px',
//     textAlign: 'center',
//     boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
//   };

//   const inputStyle = {
//     padding: '10px',
//     width: '50%',
//     borderRadius: '4px',
//     border: '1px solid #ccc',
//     margin: '10px 5px 10px 0',
//   };

//   const buttonStyle = {
//     padding: '10px 20px',
//     borderRadius: '4px',
//     border: 'none',
//     backgroundColor: '#007bff',
//     color: '#fff',
//     cursor: 'pointer',
//     marginTop: '10px',
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleFeatureChange = (index, value) => {
//     const updatedFeatures = [...formData.features];
//     updatedFeatures[index].value = value;
//     setFormData({ ...formData, features: updatedFeatures });
//   };

//   const handleAddFeature = () => {
//     setFormData((prevData) => ({
//       ...prevData,
//       features: [...prevData.features, { value: '', enabled: false }]
//     }));
//   };

//   const handleRemoveFeature = (index) => {
//     const updatedFeatures = formData.features.filter((_, i) => i !== index);
//     setFormData({ ...formData, features: updatedFeatures });
//   };

//   const handleupdateFormSubmit = (e) => {
//     e.preventDefault();
//     handlePermissionSubmitForm({
//       id: PermissionDataByid.id, // Send the ID along with the form data
//       ...formData // Spread the rest of the form data
//     });
//     setFormData({
//       addSection: '',
//       features: [{ value: '', enabled: false }]
//     });
//   };

//   return (
//     isFormModalOpen && (
//       <div style={modalStyle}>
//         <div style={modalContentStyle}>
//           <h2>Update Feature And Permission</h2>
//           <form onSubmit={handleupdateFormSubmit}>
//             <input
//               type="text"
//               name="addSection"
//               value={formData.addSection}
//               onChange={handleChange}
//               placeholder="Add Section"
//               style={inputStyle}
//               required
//             />
//             <div style={{ maxHeight: '200px', overflowY: 'auto', margin: '10px 0' }}>
//               {formData.features.map((feature, index) => (
//                 <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
//                   <input
//                     type="text"
//                     value={feature.value}
//                     onChange={(e) => handleFeatureChange(index, e.target.value)}
//                     placeholder={`Feature ${index + 1}`}
//                     style={inputStyle}
//                     required
//                   />
//                   <button
//                     type="button"
//                     onClick={() => handleRemoveFeature(index)}
//                     style={{ ...buttonStyle, backgroundColor: '#dc3545', marginLeft: '5px' }}
//                   >
//                     Remove
//                   </button>
//                   {index === formData.features.length - 1 && ( // Only show Add Feature for the last item
//                     <button
//                       type="button"
//                       onClick={handleAddFeature}
//                       style={{ ...buttonStyle, marginLeft: '5px' }}
//                     >
//                       Add Feature
//                     </button>
//                   )}
//                 </div>
//               ))}
//             </div>
//             <button type="submit" style={buttonStyle}>
//               Submit
//             </button>
//           </form>
//           <button onClick={handleCloseFormModal} style={{ ...buttonStyle, backgroundColor: '#6c757d' }}>
//             Close
//           </button>
//         </div>
//       </div>
//     )
//   );
// };

const UpdateFeatureAndPermission = ({
  isFormModalOpen,
  handleCloseFormModal,
  handlePermissionSubmitForm,
  PermissionDataByid
}) => {
  const [formData, setFormData] = useState({
    addSection: '',
    features: [{ value: '', enabled: false, subfeatures: [{ value: '', enabled: false }] }], // Initialize with one feature object containing subfeatures
  });

  // Populate form when PermissionDataByid is available
  useEffect(() => {
    if (PermissionDataByid) {
      setFormData({
        addSection: PermissionDataByid.section || '',
        features: PermissionDataByid.feature?.map((feature) => ({
          value: feature.value || '',
          enabled: feature.enabled || false,
          subfeatures: feature.subfeatures?.map(sub => ({
            value: sub.value || '',
            enabled: sub.enabled || false,
          })) || [{ value: '', enabled: false }], // Populate subfeatures or initialize with one
        })) || [{ value: '', enabled: false, subfeatures: [{ value: '', enabled: false }] }], // Initialize with one feature object containing subfeatures
        id: PermissionDataByid.id || '',
      });
    }
  }, [PermissionDataByid]);

  const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '600px',
    textAlign: 'center',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  };

  const inputStyle = {
    padding: '10px',
    width: '50%',
    borderRadius: '4px',
    border: '1px solid #ccc',
    margin: '10px 5px 10px 0',
  };

  const buttonStyle = {
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
    marginTop: '10px',
  };

  const smallButtonStyle = {
    padding: '5px 10px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
    marginLeft: '5px',
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFeatureChange = (index, value) => {
    const updatedFeatures = [...formData.features];
    updatedFeatures[index].value = value;
    setFormData({ ...formData, features: updatedFeatures });
  };

  const handleAddFeature = () => {
    setFormData((prevData) => ({
      ...prevData,
      features: [...prevData.features, { value: '', enabled: false, subfeatures: [{ value: '', enabled: false }] }],
    }));
  };

  const handleRemoveFeature = (index) => {
    const updatedFeatures = formData.features.filter((_, i) => i !== index);
    setFormData({ ...formData, features: updatedFeatures });
  };

  const handleSubfeatureChange = (featureIndex, subfeatureIndex, value) => {
    const updatedFeatures = [...formData.features];
    updatedFeatures[featureIndex].subfeatures[subfeatureIndex].value = value;
    setFormData({ ...formData, features: updatedFeatures });
  };

  const handleAddSubfeature = (featureIndex) => {
    const updatedFeatures = [...formData.features];
    updatedFeatures[featureIndex].subfeatures.push({ value: '', enabled: false });
    setFormData({ ...formData, features: updatedFeatures });
  };

  const handleRemoveSubfeature = (featureIndex, subfeatureIndex) => {
    const updatedSubfeatures = formData.features[featureIndex].subfeatures.filter((_, i) => i !== subfeatureIndex);
    const updatedFeatures = [...formData.features];
    updatedFeatures[featureIndex].subfeatures = updatedSubfeatures;
    setFormData({ ...formData, features: updatedFeatures });
  };

  const handleupdateFormSubmit = (e) => {
    e.preventDefault();
    handlePermissionSubmitForm({
      id: PermissionDataByid.id,
      ...formData,
    });
    setFormData({
      addSection: '',
      features: [{ value: '', enabled: false, subfeatures: [{ value: '', enabled: false }] }],
    });
  };

  return (
    isFormModalOpen && (
      <div style={modalStyle}>
        <div style={modalContentStyle}>
          <h2>Update Feature And Permission</h2>
          <form onSubmit={handleupdateFormSubmit}>
            <input
              type="text"
              name="addSection"
              value={formData.addSection}
              onChange={handleChange}
              placeholder="Add Section"
              style={inputStyle}
              required
            />
            <div style={{ maxHeight: '200px', overflowY: 'auto', margin: '10px 0' }}>
              {formData.features.map((feature, featureIndex) => (
                <div key={featureIndex} style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      value={feature.value}
                      onChange={(e) => handleFeatureChange(featureIndex, e.target.value)}
                      placeholder={`Feature ${featureIndex + 1}`}
                      style={inputStyle}
                      required
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveFeature(featureIndex)}
                      style={{ ...smallButtonStyle, backgroundColor: '#dc3545' }}
                    >
                      Remove Feature
                    </button>
                    <button
                      type="button"
                      onClick={handleAddFeature}
                      style={smallButtonStyle}
                    >
                      Add Feature
                    </button>
                  </div>
                  {feature.subfeatures.map((subfeature, subfeatureIndex) => (
                    <div key={subfeatureIndex} style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                      <input
                        type="text"
                        value={subfeature.value}
                        onChange={(e) => handleSubfeatureChange(featureIndex, subfeatureIndex, e.target.value)}
                        placeholder={`Subfeature ${subfeatureIndex + 1}`}
                        style={inputStyle}
                      />
                      <button
                        type="button"
                        onClick={() => handleRemoveSubfeature(featureIndex, subfeatureIndex)}
                        style={{ ...smallButtonStyle, backgroundColor: '#dc3545' }}
                      >
                        Remove Subfeature
                      </button>
                      <button
                        type="button"
                        onClick={() => handleAddSubfeature(featureIndex)}
                        style={smallButtonStyle}
                      >
                        Add Subfeature
                      </button>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <button type="submit" style={buttonStyle}>
              Submit
            </button>
          </form>
          <button onClick={handleCloseFormModal} style={{ ...buttonStyle, backgroundColor: '#6c757d' }}>
            Close
          </button>
        </div>
      </div>
    )
  );
};
