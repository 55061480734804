

import React, { useState, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';
import * as authApi from '../../../apis/users';
import * as verifydocumentapi from '../../../apis/verifydocumentupload';
import { jwtDecode } from 'jwt-decode';

function VerificationDocument() {
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [cameraFacingMode, setCameraFacingMode] = useState('user'); // 'user' for front, 'environment' for back

  const [idProofType, setIdProofType] = useState('');
  const [passport, setPassport] = useState(null);
  const [passportPreview, setPassportPreview] = useState(null);

  const [drivingLicenceFront, setDrivingLicenceFront] = useState(null);
  const [drivingLicenceFrontPreview, setDrivingLicenceFrontPreview] = useState(null);

  const [drivingLicenceBack, setDrivingLicenceBack] = useState(null);
  const [drivingLicenceBackPreview, setDrivingLicenceBackPreview] = useState(null);

  const [otherIdProofFront, setOtherIdProofFront] = useState(null);
  const [otherIdProofFrontPreview, setOtherIdProofFrontPreview] = useState(null);

  const [otherIdProofBack, setOtherIdProofBack] = useState(null);
  const [otherIdProofBackPreview, setOtherIdProofBackPreview] = useState(null);

  const [userinfo, setUserinfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [webcamContext, setWebcamContext] = useState('');
  const [isBackCameraAvailable, setIsBackCameraAvailable] = useState(true);
  const [submitButtonStyle, setSubmitButtonStyle] = useState({ backgroundColor: 'blue' }); // Default style, you can change the color as needed

  const webcamRef = useRef(null);

  const handleFileChange = (event, setFile, setPreview) => {
    setFile(event.target.files[0]);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();

    fetch(imageSrc)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], "photo.jpg", { type: "image/jpeg" });

        switch (webcamContext) {
          case 'clearPhoto':
            setPhotoPreview(imageSrc);
            setPhoto(file);
            break;
          case 'passport':
            setPassportPreview(imageSrc);
            setPassport(file);
            break;
          case 'drivingLicenceFront':
            setDrivingLicenceFrontPreview(imageSrc);
            setDrivingLicenceFront(file);
            break;
          case 'drivingLicenceBack':
            setDrivingLicenceBackPreview(imageSrc);
            setDrivingLicenceBack(file);
            break;
          case 'otherIdProofFront':
            setOtherIdProofFrontPreview(imageSrc);
            setOtherIdProofFront(file);
            break;
          case 'otherIdProofBack':
            setOtherIdProofBackPreview(imageSrc);
            setOtherIdProofBack(file);
            break;
          default:
            console.error('Unknown webcam context:', webcamContext);
        }
      });

    setShowWebcam(false);
  };

  const switchCamera = () => {
    if (!isBackCameraAvailable && cameraFacingMode === 'environment') {
      alert('No back camera available on this system.');
    } else {
      setCameraFacingMode(prevMode => (prevMode === 'user' ? 'environment' : 'user'));
    }
  };

  const checkBackCameraAvailability = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    const hasBackCamera = videoDevices.some(device => device.label.toLowerCase().includes('back') || device.label.toLowerCase().includes('environment'));
    setIsBackCameraAvailable(hasBackCamera);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('photo', photo);
    formData.append('UserId', userinfo[0].id);

    if (idProofType === 'passport') {
      formData.append('passport', passport);
    } else if (idProofType === 'drivingLicence') {
      formData.append('drivingLicenceFront', drivingLicenceFront);
      formData.append('drivingLicenceBack', drivingLicenceBack);
    } else if (idProofType === 'otherIdProof') {
      formData.append('otherIdProofFront', otherIdProofFront);
      formData.append('otherIdProofBack', otherIdProofBack);
    }

    try {
      const response = await verifydocumentapi.uploaddocument(formData);
      setPhoto(null);
      setPhotoPreview(null);
      setPassport(null);
      setPassportPreview(null);
      setDrivingLicenceFront(null);
      setDrivingLicenceFrontPreview(null);
      setDrivingLicenceBack(null);
      setDrivingLicenceBackPreview(null);
      setOtherIdProofFront(null);
      setOtherIdProofFrontPreview(null);
      setOtherIdProofBack(null);
      setOtherIdProofBackPreview(null);
      setSubmitButtonStyle({ backgroundColor: 'green' });

      if (response.success) {
        alert("Document uploaded and saved successfully");
      } else {
        alert(response.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    const getTokenFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('token');
    };

    const fetchUserinfo = async (userId) => {
      setIsLoading(true);

      try {
        const result = await authApi.getfordocumentverificationUserById(userId);
        setUserinfo(result.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    const token = getTokenFromUrl();
    if (token) {
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.verificationUserId;
      fetchUserinfo(userId);

      const currentTimestamp = Date.now() / 1000;
      if (decodedToken.exp < currentTimestamp) {
        setError(true);
      }
    }
    checkBackCameraAvailability();

  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return (
      <div style={containerStyle}>
        <h2 style={headerStyle}>Dream Document Verification</h2>
        <p>Link Expired</p>
      </div>
    );
  }

  return (
    <div style={containerStyle}>
      <h2 style={headerStyle}>Dream Scan Document Verification</h2>
      <p>Please upload the scan documents for verification:</p>
      {userinfo && (
        <div style={profileContainerStyle}>
          <img src={userinfo[0].profile_pic} alt="Profile" style={profilePicStyle} />
          <div style={userInfoStyle}>
            <span><strong>ID:</strong> {userinfo[0].id}</span>
            <span><strong>Username:</strong> {userinfo[0].username}</span>
            <span><strong>Email:</strong> {userinfo[0].email}</span>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit} style={formStyle}>
        <div style={formGroupStyle}>
          <label style={labelStyle}>Clear Photo:</label>
          <button type="button" onClick={() => { setShowWebcam(true); setWebcamContext('clearPhoto'); }} style={buttonStyle}>Open Camera</button>
          {showWebcam && webcamContext === 'clearPhoto' && (
            <div style={webcamContainer}>
              {!isBackCameraAvailable && cameraFacingMode === 'environment' && (
                <p style={{ color: 'red' }}>No back camera available on this system.</p>
              )}
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                style={webcamStyle}
                videoConstraints={{ facingMode: cameraFacingMode }}
              />
              <div style={{ display: 'flex', gap: '10px' }}>
                <button type="button" onClick={handleCapture} style={buttonStyle}>Capture Photo</button>
                <button type="button" onClick={() => setShowWebcam(false)} style={buttonStylewebclose}>Close Webcam</button>
                <button type="button" onClick={switchCamera} style={buttonStyle}>Switch Camera</button>
              </div>
            </div>
          )}
          {photoPreview && (
            <div style={previewContainerStyle}>
              <img src={photoPreview} alt="Clear Photo Preview" style={previewStyle} />
              {/* <button onClick={() => setPhotoPreview(null)} style={buttonStyle}>Clear Photo</button> */}
            </div>
          )}
        </div>

        <div style={formGroupStyle}>
          <label style={labelStyle}>ID Proof Type:</label>
          <select value={idProofType} onChange={e => setIdProofType(e.target.value)} style={selectStyle}>
            <option value="">Select ID Proof Type</option>
            <option value="passport">Passport</option>
            <option value="drivingLicence">Driving Licence</option>
            <option value="otherIdProof">Other ID Proof</option>
          </select>
        </div>

        {idProofType === 'passport' && (
          <div style={formGroupStyle}>
            <label style={labelStyle}>Passport:</label>
            <button type="button" onClick={() => { setShowWebcam(true); setWebcamContext('passport'); }} style={buttonStyle}>Open Camera</button>
            {showWebcam && webcamContext === 'passport' && (
              <div style={webcamContainer}>
                {!isBackCameraAvailable && cameraFacingMode === 'environment' && (
                  <p style={{ color: 'red' }}>No back camera available on this system.</p>
                )}
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  style={webcamStyle}
                  videoConstraints={{ facingMode: cameraFacingMode }}
                />
                <div style={{ display: 'flex', gap: '10px' }}>
                  <button type="button" onClick={handleCapture} style={buttonStyle}>Capture Photo</button>
                  <button type="button" onClick={() => setShowWebcam(false)} style={buttonStylewebclose}>Close Webcam</button>
                  <button type="button" onClick={switchCamera} style={buttonStyle}>Switch Camera</button>
                </div>
              </div>
            )}
            {passportPreview &&
              <div style={previewContainerStyle}>
                <img src={passportPreview} alt="Passport" style={previewStyle} />

              </div>
            }


          </div>
        )}

        {idProofType === 'drivingLicence' && (
          <div>
            <div style={formGroupStyle}>
              <label style={labelStyle}>Driving Licence (Front):</label>
              <button type="button" onClick={() => { setShowWebcam(true); setWebcamContext('drivingLicenceFront'); }} style={buttonStyle}>Open Camera</button>
              {showWebcam && webcamContext === 'drivingLicenceFront' && (
                <div style={webcamContainer}>
                  {!isBackCameraAvailable && cameraFacingMode === 'environment' && (
                    <p style={{ color: 'red' }}>No back camera available on this system.</p>
                  )}
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    style={webcamStyle}
                    videoConstraints={{ facingMode: cameraFacingMode }}
                  />
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <button type="button" onClick={handleCapture} style={buttonStyle}>Capture Photo</button>
                    <button type="button" onClick={() => setShowWebcam(false)} style={buttonStylewebclose}>Close Webcam</button>
                    <button type="button" onClick={switchCamera} style={buttonStyle}>Switch Camera</button>
                  </div>
                </div>
              )}
              {drivingLicenceFrontPreview && <img src={drivingLicenceFrontPreview} alt="Driving Licence Front" style={previewStyle} />}
            </div>
            <div style={formGroupStyle}>
              <label style={labelStyle}>Driving Licence (Back):</label>
              <button type="button" onClick={() => { setShowWebcam(true); setWebcamContext('drivingLicenceBack'); }} style={buttonStyle}>Open Camera</button>
              {showWebcam && webcamContext === 'drivingLicenceBack' && (
                <div style={webcamContainer}>
                  {!isBackCameraAvailable && cameraFacingMode === 'environment' && (
                    <p style={{ color: 'red' }}>No back camera available on this system.</p>
                  )}
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    style={webcamStyle}
                    videoConstraints={{ facingMode: cameraFacingMode }}
                  />
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <button type="button" onClick={handleCapture} style={buttonStyle}>Capture Photo</button>
                    <button type="button" onClick={() => setShowWebcam(false)} style={buttonStylewebclose}>Close Webcam</button>
                    <button type="button" onClick={switchCamera} style={buttonStyle}>Switch Camera</button>
                  </div>
                </div>
              )}
              {drivingLicenceBackPreview && <img src={drivingLicenceBackPreview} alt="Driving Licence Back" style={previewStyle} />}
            </div>
          </div>
        )}

        {idProofType === 'otherIdProof' && (
          <div>
            <div style={formGroupStyle}>
              <label style={labelStyle}>Other ID Proof (Front):</label>
              <button type="button" onClick={() => { setShowWebcam(true); setWebcamContext('otherIdProofFront'); }} style={buttonStyle}>Open Camera</button>
              {showWebcam && webcamContext === 'otherIdProofFront' && (
                <div style={webcamContainer}>
                  {!isBackCameraAvailable && cameraFacingMode === 'environment' && (
                    <p style={{ color: 'red' }}>No back camera available on this system.</p>
                  )}
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    style={webcamStyle}
                    videoConstraints={{ facingMode: cameraFacingMode }}
                  />
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <button type="button" onClick={handleCapture} style={buttonStyle}>Capture Photo</button>
                    <button type="button" onClick={() => setShowWebcam(false)} style={buttonStylewebclose}>Close Webcam</button>
                    <button type="button" onClick={switchCamera} style={buttonStyle}>Switch Camera</button>
                  </div>
                </div>
              )}
              {otherIdProofFrontPreview && <img src={otherIdProofFrontPreview} alt="Other ID Proof Front" style={previewStyle} />}
            </div>
            <div style={formGroupStyle}>
              <label style={labelStyle}>Other ID Proof (Back):</label>
              <button type="button" onClick={() => { setShowWebcam(true); setWebcamContext('otherIdProofBack'); }} style={buttonStyle}>Open Camera</button>
              {showWebcam && webcamContext === 'otherIdProofBack' && (
                <div style={webcamContainer}>
                  {!isBackCameraAvailable && cameraFacingMode === 'environment' && (
                    <p style={{ color: 'red' }}>No back camera available on this system.</p>
                  )}
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    style={webcamStyle}
                    videoConstraints={{ facingMode: cameraFacingMode }}
                  />
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <button type="button" onClick={handleCapture} style={buttonStyle}>Capture Photo</button>
                    <button type="button" onClick={() => setShowWebcam(false)} style={buttonStylewebclose}>Close Webcam</button>
                    <button type="button" onClick={switchCamera} style={buttonStyle}>Switch Camera</button>
                  </div>
                </div>
              )}
              {otherIdProofBackPreview && <img src={otherIdProofBackPreview} alt="Other ID Proof Back" style={previewStyle} />}
            </div>
          </div>
        )}
        <button type="submit" style={{ ...buttonStyle, ...submitButtonStyle }}>Submit</button>
      </form>
    </div>
  );
}

const containerStyle = {
  maxWidth: '600px',
  margin: '0 auto',
  padding: '20px',
  border: '1px solid #ccc',
  borderRadius: '5px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  fontFamily: 'Arial, sans-serif',
  marginTop:'50px'
};

const headerStyle = {
  textAlign: 'center',
  color: 'blue',
};

const formStyle = {
  display: 'flex',
  flexDirection: 'column',
};

const formGroupStyle = {
  marginBottom: '15px',
};

const labelStyle = {
  marginBottom: '5px',
  fontWeight: 'bold',
};

const inputStyle = {
  padding: '10px',
  fontSize: '16px',
  borderRadius: '4px',
  border: '1px solid #ccc',
};

const selectStyle = {
  padding: '10px',
  fontSize: '16px',
  borderRadius: '4px',
  border: '1px solid #ccc',
};

const buttonStyle = {
  padding: '10px 20px',
  fontSize: '16px',
  borderRadius: '4px',
  border: 'none',
  backgroundColor: 'blue',
  color: 'white',
  cursor: 'pointer',
};

const buttonStylewebclose = {
  padding: '10px 20px',
  fontSize: '16px',
  borderRadius: '4px',
  border: 'none',
  backgroundColor: 'red',
  color: 'white',
  cursor: 'pointer',
};

const previewContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
};

const previewStyle = {
  maxWidth: '50%',
  marginTop: '10px',
  marginBottom: '10px',
  borderRadius: '5px',
};

const webcamContainer = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '4px'
  // alignItems: 'center',

};

const webcamStyle = {
  width: '50%',
  maxWidth: '200px',
  marginBottom: '10px',
};

const submitButtonStyle = {
  padding: '10px 20px',
  fontSize: '18px',
  borderRadius: '4px',
  border: 'none',
  backgroundColor: 'green',
  color: 'white',
  cursor: 'pointer',
};
const profileContainerStyle = { display: 'flex', alignItems: 'center', marginBottom: '20px' };
const profilePicStyle = { width: '100px', height: '100px', borderRadius: '50%', marginRight: '20px' };
const userInfoStyle = { display: 'flex', flexDirection: 'column' };
export default VerificationDocument;









//new code


