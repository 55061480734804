import React, { useEffect, useState } from 'react';
import IncludeSideBar from '../../Components/Sidebar/IncludeSideBar';
import useAuth from "../../../src/useAuth";
import { FaUser, FaEnvelope, FaPhone, FaWallet, FaUserShield, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

function ViewProfile() {
  const { userId } = useParams();
  const [adminEmployees, setAdminEmployees] = useState([]);
  const [Adminprofile, setAdminprofile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openPermissions, setOpenPermissions] = useState({});

  const EmployeeApis = require('../../apis/employee');

  const getEmployee = async () => {
    try {
      const response = await EmployeeApis.getAllEmployee();
      const adminEmployees = response.employees.filter(employee => employee.role === 'admin');
      setAdminEmployees(adminEmployees);
    } catch (error) {
      console.error("Error fetching admin employees:", error);
    }
  };

  const getAdminProfile = async () => {
    try {
      setLoading(true);
      const response = await EmployeeApis.getProfile(userId);
      setAdminprofile(response.AdminProfile);
    } catch (error) {
      console.error("Error fetching admin profile:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmployee();
    getAdminProfile();
  }, [userId]);

  const togglePermissionCategory = (category) => {
    setOpenPermissions(prevState => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  const tableContainerStyle = {
    padding: '20px',
    backgroundColor: '#f4f7f9',
    minHeight: '100vh',
    textAlign: 'center',
  };

  const tableStyle = {
    width: '80%',
    margin: '20px auto',
    borderCollapse: 'collapse',
    backgroundColor: '#ffffff',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
  };

  const thTdStyle = {
    border: '1px solid #ddd',
    padding: '12px',
    textAlign: 'left',
    fontSize: '1.2em',
    color: '#34495e',
  };

  const thStyle = {
    backgroundColor: '#007BFF',
    color: '#ffffff',
    padding: '12px',
  };

  const dropdownHeaderStyle = {
    cursor: 'pointer',
    padding: '15px',
    backgroundColor: 'red',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '1.2em',
    borderRadius: '8px',
    marginTop: '10px',
    marginBottom: '10px',
  };

  return (
    <IncludeSideBar>
      <div style={tableContainerStyle}>
        <h1 style={{ fontSize: '2.5em', marginBottom: '20px', color: '#2c3e50' }}>
          Welcome to {Adminprofile.first_name} Profile
        </h1>

        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(255, 255, 255, 0.7)', position: 'absolute', width: '100%' }}>
            <div style={{ border: '5px solid #f3f3f3', borderRadius: '50%', borderTop: '5px solid #3498db', width: '60px', height: '60px', animation: 'spin 1s linear infinite' }}></div>
          </div>
        )}

        {!loading && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={thStyle}>Field</th>
                <th style={thStyle}>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={thTdStyle}><FaUser style={{ marginRight: '10px' }} />Name</td>
                <td style={thTdStyle}>{Adminprofile.first_name} {Adminprofile.last_name}</td>
              </tr>
              <tr>
                <td style={thTdStyle}><FaEnvelope style={{ marginRight: '10px' }} />Email</td>
                <td style={thTdStyle}>{Adminprofile.email}</td>
              </tr>
              <tr>
                <td style={thTdStyle}><FaUserShield style={{ marginRight: '10px' }} />Role</td>
                <td style={thTdStyle}>{Adminprofile.role}</td>
              </tr>
              <tr>
                <td style={thTdStyle}><FaPhone style={{ marginRight: '10px' }} />Mobile No</td>
                <td style={thTdStyle}>{Adminprofile.phone_number || 'N/A'}</td>
              </tr>
              <tr>
                <td style={thTdStyle}><FaWallet style={{ marginRight: '10px' }} />Wallet Money</td>
                <td style={thTdStyle}>{Adminprofile.wallet}</td>
              </tr>
            </tbody>
          </table>
        )}

        <h2 style={{ fontSize: '1.5em', color: '#2980b9', marginTop: '30px', marginBottom: '15px' }}>
          Permissions
        </h2>

        {!loading && Adminprofile.permissions && (
          Object.entries(Adminprofile.permissions).map(([category, actions]) => (
            <div key={category}>
              <div style={dropdownHeaderStyle} onClick={() => togglePermissionCategory(category)}>
                <span>{category} Permissions</span>
                {openPermissions[category] ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              {openPermissions[category] && (
                <table style={{ ...tableStyle, width: '70%', margin: '10px auto' }}>
                  <thead>
                    <tr>
                      <th style={thStyle}>Action</th>
                      <th style={thStyle}>Granted Subfeatures</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(actions).map(([action, actionDetails]) => {
                      if (actionDetails?.selected) {
                        const grantedSubfeatures = Object.entries(actionDetails?.subfeatures || {})
                          .filter(([_, isGranted]) => isGranted)
                          .map(([subfeature]) => subfeature);
                        return (
                          <tr key={action}>
                            <td style={thTdStyle}>{action}</td>
                            <td style={thTdStyle}>
                              {grantedSubfeatures.length > 0 ? (
                                <ul>
                                  {grantedSubfeatures.map(subfeature => (
                                    <li key={subfeature}>{subfeature}</li>
                                  ))}
                                </ul>
                              ) : (
                                'No subfeatures granted'
                              )}
                            </td>
                          </tr>
                        );
                      }
                      return null;
                    })}
                  </tbody>
                </table>
              )}
            </div>
          ))
        )}
      </div>
    </IncludeSideBar>
  );
}

export default ViewProfile;
