import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar'
import React, { useEffect, useState } from 'react';

import useDebounce from '../../../utils/useDebounce';

function BadgeRequest() {
  const allbadgerequestApis = require("../../../apis/badge_request");

  // console.log(hassubfetureEditComment,'hassubfetureEditComment')
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Add a loading state
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [AllFeed, setAllfeed] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [pageRange, setPageRange] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]); // Visible page range
  const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);
  const [expandedPosts, setExpandedPosts] = useState({});
  const [openfeedmodel, setopenfeedmodel] = useState(false)
  const [feedinfo, setfeedinfo] = useState([])
  const [selectedRow, setSelectedRow] = useState(null); // State to track selected row
  const [isBlockModelOpen, SetisBlockModelOpen] = useState(false)
  const [searchresult, setsearcresult] = useState([])
  // /Block Feed State 
  const [isfeedModalOpen, setIsfeedModalOpen] = useState(false);
  const [selectedPostId, setselectedPostId] = useState(null)
  // /Block Feed State 
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const FeatchAllBadgeRequestInfo = async (page, searchTerm = "") => {
    try {
      setLoading(true);

      const response = await allbadgerequestApis.getAllBadgeRequestInfo(page, searchTerm);
      setAllfeed(response?.data?.badgeRequests || []);
      console.log(response?.data?.badgeRequests, 'response?.data?.posts')
      setTotalPages(response?.data?.pagination?.totalPages || 1);
      setFilteredUsers(response?.data?.badgeRequests || [])

    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FeatchAllBadgeRequestInfo(currentPage, debouncedSearchTerm);
  }, [currentPage, debouncedSearchTerm]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    const filtered = AllFeed.filter(
      (user) =>
        user?.user?.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredUsers(filtered);
  };

  useEffect(() => {
    const initialRange = [];
    const rangeSize = 10;
    const totalVisiblePages = Math.min(rangeSize, totalPages);

    for (let i = 1; i <= totalVisiblePages; i++) {
      initialRange.push(i);
    }
    setPageRange(initialRange);
  }, [totalPages]);

  const updatePageRange = (startPage) => {
    const rangeSize = 10; // Number of pages visible at a time
    const newRange = [];

    for (
      let i = startPage;
      i < startPage + rangeSize && i <= totalPages;
      i++
    ) {
      newRange.push(i);
    }

    setPageRange(newRange);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);

    // If the clicked page is the last in the current range, load the next range
    if (page === pageRange[pageRange.length - 1] && page < totalPages) {
      updatePageRange(page);
    }

    // If the clicked page is the first in the current range and not the first page
    if (page === pageRange[0] && page > 1) {
      updatePageRange(page - 9);
    }
  };

  const handleFirst = () => {
    setCurrentPage(1);
    updatePageRange(1);
  };

  const handleLast = () => {
    setCurrentPage(totalPages);
    const lastRangeStart = Math.max(
      1,
      Math.floor((totalPages - 1) / 10) * 10 + 1
    );
    updatePageRange(lastRangeStart);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      // Check if we are at the start of the current visible range
      if (currentPage === pageRange[0]) {
        // If we are at the first page of the range, move to the previous set of pages
        const previousRangeStart = Math.max(pageRange[0] - 10, 1); // Ensure start page is >= 1
        updatePageRange(previousRangeStart);
      } else {
        setCurrentPage(currentPage - 1);
      }
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      // If we are at the end of the current visible range, load the next range
      if (currentPage === pageRange[pageRange.length - 1] && currentPage < totalPages) {
        updatePageRange(pageRange[pageRange.length - 1]);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const handleGoToPage = () => {
    const page = parseInt(inputPageNumber, 10);

    // Ensure the page number is a valid number and within the valid range
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);

      // Calculate the range for the current page
      const startPage = Math.floor((page - 1) / 10) * 10 + 1;
      updatePageRange(startPage);
    } else {
      alert('Please enter a valid page number!');
    }
  };
  const [clickedButton, setClickedButton] = useState(null);

  const handleButtonClick = (postid, buttonType) => {
    setClickedButton({ postid, buttonType }); // Set the clicked button
  };

  const handleRowClick = (transactionId) => {
    setSelectedRow(transactionId);
  };

  const handleApprovedBadgeRequest = async (id) => {
    const confirmApproved = window.confirm("Are you sure you want to Approved ?");

    if (confirmApproved) {
      try {
        const response = await allbadgerequestApis.approvedBadgeRequest(id);

        if (response.success) {
          alert(response.message); // Show success message
          FeatchAllBadgeRequestInfo(currentPage, searchTerm); // Refresh the list
        }

      } catch (error) {
        console.error("Error unmuting comment:", error);
      }
    } else {
      console.log("Unmute action canceled.");
    }
  };
  return (

    <IncludeSideBar>
      <h1> Display All Badge Request Info</h1>


      <div>
        <input
          type="text"
          placeholder="Search by username"
          value={searchTerm}
          onChange={handleSearch}
        />
        {loading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
        {!loading && (
          <table>
            <thead>
              <tr>
                <th> ID</th>
                <th>User Id</th>
                <th>Username</th>
                <th>
                  Requested Badge Type</th>
                <th>
                  Requested Time
                </th>
                <th>
                  status                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.length > 0 ? (
                filteredUsers.map((badge) => {
                  return (
                    <tr key={badge.id}
                      onClick={() => handleRowClick(badge.id)}

                      style={{
                        backgroundColor: selectedRow === badge.id ? '#91b7e6' : 'transparent',
                        color: selectedRow === badge.id ? 'white' : 'black',
                        cursor: 'pointer', // Change cursor to pointer for better UX
                      }}
                    >
                      <td>{badge?.id}</td>

                      <td>{badge?.user_id || "N/A"}</td>
                      <td>{badge?.username || "N/A"}</td>
                      <td>{badge?.requested_badge_type || "N/A"}</td>

                      <td>
                        {badge.requested_time

                          ? new Date(badge.requested_time).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td>{badge?.status || "N/A"}</td>

                      <td>
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <button
                            onClick={() => handleApprovedBadgeRequest(badge?.id)}
                            disabled={badge?.status === 'approved'}
                            style={{
                              backgroundColor: badge?.status === 'approved' ? 'green' : '#007bff',
                              color: 'white',
                              padding: '5px 10px',
                              border: 'none',
                              borderRadius: '5px',
                              cursor: badge?.status === 'approved' ? 'not-allowed' : 'pointer',
                            }}
                          >
                            {badge?.status === 'approved' ? 'Approved' : 'Approve Request'}
                          </button>





                        </div>

                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="11">No Request found</td>
                </tr>
              )}

            </tbody>
          </table>
        )}
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <button onClick={handleFirst} disabled={currentPage === 1}>
              First
            </button>
            <button onClick={handlePrevious} disabled={currentPage === 1}>
              Previous
            </button>
            {pageRange.map((page) => (
              <button
                key={page}
                onClick={() => handlePageClick(page)}
                style={{
                  padding: "5px 10px",
                  backgroundColor: currentPage === page ? "red" : "#f8f9fa",
                  color: currentPage === page ? "white" : "black",
                  border: "1px solid #ddd",
                  cursor: "pointer",
                }}
              >
                {page}
              </button>
            ))}
            <button onClick={handleNext} disabled={currentPage === totalPages}>
              Next
            </button>
            <button onClick={handleLast} disabled={currentPage === totalPages}>
              Last
            </button>
          </div>
          {/* direct page */}
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
            <input
              type="number"
              value={inputPageNumber}
              onChange={(e) => setInputPageNumber(e.target.value)}
              placeholder="Page No"
              style={{
                padding: '5px',
                fontSize: '14px',
                // border: '1px solid #ccc',
                border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

                borderRadius: '4px 0 0 4px',
                outline: 'none',
                width: '90px'
              }}
              onFocus={() => setInputFocus(true)}
              onBlur={() => setInputFocus(false)}
            />
            <button
              onClick={handleGoToPage}
              style={{
                padding: '6.5px 10px',
                fontSize: '14px',
                border: '1px solid #ccc',
                borderLeft: 'none',
                borderRadius: '0 4px 4px 0',
                // backgroundColor: '#007bff',

                backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
                color: 'white',
                cursor: 'pointer',
                outline: 'none',
              }}


            >
              Go
            </button>
          </div>

        </div>

      </div>
    </IncludeSideBar>


  )
}

export default BadgeRequest


