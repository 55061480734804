import React, { useState, useEffect } from 'react';
// Assuming getRoseTransaction is the function that fetches the transaction data
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar'
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import useDebounce from '../../../utils/useDebounce';

function FeedRoseTransation() {
  const getfeedtransation = require('../../../apis/diamond_transaction');
  const [transactions, setTransactions] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isFocused, setIsFocused] = useState(false);
  // New state for date range filter
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null); // State to track selected row
  const [pageRange, setPageRange] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]); // Visible page range
  const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500); // Delay of 500ms

  const fetchTransactions = async (page, searchTerm = '', startDate = null, endDate = null) => {
    setIsLoading(true);
    try {
      const filters = { page, searchTerm };

      if (startDate) {
        filters.startDate = startDate.toISOString();
      }

      if (endDate) {
        filters.endDate = endDate.toISOString();
      }
      const response = await getfeedtransation.getFeedRoseTransation(filters);
      console.log(response, 'response admin'); // Check the structure of the response

      // // Assuming response.data.transactions contains the list of transactions
      const transactionsData = response.data.transactions || [];
      setTransactions(transactionsData);
      setFilteredUsers(transactionsData); // Set filteredUsers to transactions initially
      setTotalPages(response?.data?.totalPages);
      console.log(response?.data?.pagination, 'totalPagestotalPagestotalPagestotalPages')

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching Diamond Transactions:", error);
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchTransactions(currentPage, searchTerm);
  // }, [currentPage, searchTerm]);
  useEffect(() => {
    fetchTransactions(currentPage, debouncedSearchTerm, startDate, endDate);
  }, [currentPage, debouncedSearchTerm, startDate, endDate]);

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    return date.toLocaleString(); // Adjust toLocaleString parameters for custom formatting
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    const filtered = transactions.filter(
      (user) =>
        (user?.sendername?.toLowerCase().includes(searchTerm.toLowerCase())
        ) ||
        (user?.reciverusername.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    setFilteredUsers(filtered);
  };
  //   // Dynamically update the initial page range based on totalPages
  useEffect(() => {
    const initialRange = [];
    const rangeSize = 10;
    const totalVisiblePages = Math.min(rangeSize, totalPages);

    for (let i = 1; i <= totalVisiblePages; i++) {
      initialRange.push(i);
    }
    setPageRange(initialRange);
  }, [totalPages]);

  const updatePageRange = (startPage) => {
    const rangeSize = 10; // Number of pages visible at a time
    const newRange = [];

    for (
      let i = startPage;
      i < startPage + rangeSize && i <= totalPages;
      i++
    ) {
      newRange.push(i);
    }

    setPageRange(newRange);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);

    // If the clicked page is the last in the current range, load the next range
    if (page === pageRange[pageRange.length - 1] && page < totalPages) {
      updatePageRange(page);
    }

    // If the clicked page is the first in the current range and not the first page
    if (page === pageRange[0] && page > 1) {
      updatePageRange(page - 9);
    }
  };

  const handleFirst = () => {
    setCurrentPage(1);
    updatePageRange(1);
  };

  const handleLast = () => {
    setCurrentPage(totalPages);
    const lastRangeStart = Math.max(
      1,
      Math.floor((totalPages - 1) / 10) * 10 + 1
    );
    updatePageRange(lastRangeStart);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      // Check if we are at the start of the current visible range
      if (currentPage === pageRange[0]) {
        // If we are at the first page of the range, move to the previous set of pages
        const previousRangeStart = Math.max(pageRange[0] - 10, 1); // Ensure start page is >= 1
        updatePageRange(previousRangeStart);
      } else {
        setCurrentPage(currentPage - 1);
      }
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      // If we are at the end of the current visible range, load the next range
      if (currentPage === pageRange[pageRange.length - 1] && currentPage < totalPages) {
        updatePageRange(pageRange[pageRange.length - 1]);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const handleGoToPage = () => {
    const page = parseInt(inputPageNumber, 10);

    // Ensure the page number is a valid number and within the valid range
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);

      // Calculate the range for the current page
      const startPage = Math.floor((page - 1) / 10) * 10 + 1;
      updatePageRange(startPage);
    } else {
      alert('Please enter a valid page number!');
    }
  };
  const clean = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchTerm('');
  
    // Use a callback in the state setter to ensure it fetches with updated values
    setTimeout(() => {
      fetchTransactions(currentPage, '', null, null);
    }, 0); // Timeout ensures state updates are applied first
  };
  const loaderStyle = {
    border: '8px solid #f3f3f3', // Light grey
    borderTop: '8px solid #3498db', // Blue
    borderRadius: '50%',
    width: '30px', // Size of the loader
    height: '30px', // Size of the loader
    animation: 'spin 1s linear infinite',
    alignItems: 'center',
    margin: '0 auto', // Center the loader



  };

  return (
    <IncludeSideBar>
      <div>
        <h2 style={{ textAlign: 'center' }}>Feed Rose Transactions</h2>
        <input
          type="text"
          placeholder="Search by username or email"
          value={searchTerm}
          onChange={handleSearch}


        />
 <div style={{ marginBottom: '20px', display: 'flex',  alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              maxDate={new Date()} // Ensure start date is not in the future
              dateFormat="dd:MM:yyyy" // Format date as dd:mm:yyyy

              style={{
                padding: '5px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                fontSize: '14px',
              }}
            />
            <span style={{ margin: '0 5px' }}>to</span>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              placeholderText="End Date"
              minDate={startDate} // Ensure end date is not before start date
              maxDate={new Date()} // Disable selecting future dates
              dateFormat="dd:MM:yyyy" // Format date as dd:mm:yyyy

              style={{
                padding: '5px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                fontSize: '14px',
              }}
            />
           
          </div>
          <button
              style={{
                padding: '6px 12px',
                marginLeft:'3px',
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
              onClick={clean}
            >
              clean
            </button>
        </div>

        {isLoading && <div style={loaderStyle}></div>} {/* Circular Loader */}

        <table>
          <thead>
            <tr>
              <th> Comment ID</th>
              <th>Post ID</th>
              <th>Transaction Date & Time</th>
              <th>Transaction Rose Diamonds</th>

              <th>Receiver Username</th>


              <th>Sender Username</th>

            </tr>
          </thead>
          <tbody>
            {Array.isArray(filteredUsers) && filteredUsers.length > 0 ? (
              filteredUsers.map((transaction) => (
                <tr key={transaction.id}
                // onClick={() => handleRowClick(transaction.id)}

                // style={{
                //   backgroundColor: selectedRow === transaction.id ? '#007bff' : 'transparent',
                //   color: selectedRow === transaction.id ? 'white' : 'black',
                //   cursor: 'pointer', // Change cursor to pointer for better UX
                // }}
                >
                  <td

                  >{transaction?.commentid}</td>
                  <td>{transaction?.postid}</td>
                  <td>{formatCreatedAt(transaction?.Createdat)}</td>
                  <td>{transaction?.rosetransaction}</td>

                  <td>{transaction?.reciverusername}</td>


                  <td>{transaction?.sendername}</td>



                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">No transactions found</td>
              </tr>
            )}
          </tbody>

        </table>
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <button onClick={handleFirst} disabled={currentPage === 1}>
              First
            </button>
            <button onClick={handlePrevious} disabled={currentPage === 1}>
              Previous
            </button>
            {pageRange.map((page) => (
              <button
                key={page}
                onClick={() => handlePageClick(page)}
                style={{
                  padding: "5px 10px",
                  backgroundColor: currentPage === page ? "red" : "#f8f9fa",
                  color: currentPage === page ? "white" : "black",
                  border: "1px solid #ddd",
                  cursor: "pointer",
                }}
              >
                {page}
              </button>
            ))}
            <button onClick={handleNext} disabled={currentPage === totalPages}>
              Next
            </button>
            <button onClick={handleLast} disabled={currentPage === totalPages}>
              Last
            </button>
          </div>
          {/* direct page */}
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
            <input
              type="number"
              value={inputPageNumber}
              onChange={(e) => setInputPageNumber(e.target.value)}
              placeholder="Page No"
              style={{
                padding: '5px',
                fontSize: '14px',
                // border: '1px solid #ccc',
                border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

                borderRadius: '4px 0 0 4px',
                outline: 'none',
                width: '90px'
              }}
              onFocus={() => setInputFocus(true)}
              onBlur={() => setInputFocus(false)}
            />
            <button
              onClick={handleGoToPage}
              style={{
                padding: '6.5px 10px',
                fontSize: '14px',
                border: '1px solid #ccc',
                borderLeft: 'none',
                borderRadius: '0 4px 4px 0',
                // backgroundColor: '#007bff',

                backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
                color: 'white',
                cursor: 'pointer',
                outline: 'none',
              }}


            >
              Go
            </button>
          </div>
        </div>

      </div>
    </IncludeSideBar>
  );
}

export default FeedRoseTransation;
