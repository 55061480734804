import { SERVER_API_URL, SERVER_DOMAIN } from "../constants/constants";
import axios from "axios";
import moment from 'moment';

// const getRoseTransaction = async () => {
//   const getToken = localStorage.getItem("token");
//   // console.log(page,perPage,getToken)
//   const config = {
//     headers: { Authorization: `Bearer ${getToken}` },
//   };
//   const url = `${SERVER_API_URL}/admin/DiamondTransaction/getRoseTransaction`;
//   const result = await axios.get(url, config);
//   return result;
// };

const getRoseTransaction = async (filters) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  const { page, searchTerm = '', startDate = null, endDate = null } = filters;
  const encodedSearchTerm = encodeURIComponent(searchTerm);
  let encodedStartDate = '';
  let encodedEndDate = '';
  if (startDate) {
    encodedStartDate = moment.utc(startDate).add(1, 'day').endOf('day').toISOString(); // Keep startDate as is
  }
  if (endDate) {
    encodedEndDate = moment.utc(endDate).add(1, 'day').endOf('day').toISOString(); // Add 1 day to endDate
  }

  let url = `${SERVER_API_URL}/admin/DiamondTransaction/getRoseTransaction?page=${page}&search=${encodedSearchTerm}`;
  if (encodedStartDate) {
    url += `&startDate=${encodeURIComponent(encodedStartDate)}`;
  }
  if (encodedEndDate) {
    url += `&endDate=${encodeURIComponent(encodedEndDate)}`;
  }
  // Make the API request
  const result = await axios.get(url, config);
  return result;
};






const getMessageSubscriptionTransaction = async (filters) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  const { page, searchTerm = '', startDate = null, endDate = null } = filters;
  const encodedSearchTerm = encodeURIComponent(searchTerm);

  let encodedStartDate = '';
  let encodedEndDate = '';
  if (startDate) {
    encodedStartDate = moment.utc(startDate).add(1, 'day').endOf('day').toISOString(); // Keep startDate as is
  }
  if (endDate) {
    encodedEndDate = moment.utc(endDate).add(1, 'day').endOf('day').toISOString(); // Add 1 day to endDate
  }
  let url = `${SERVER_API_URL}/admin/DiamondTransaction/getMessageSubscriptionTransaction?page=${page}&search=${encodedSearchTerm}`;

  if (encodedStartDate) {
    url += `&startDate=${encodeURIComponent(encodedStartDate)}`;
  }
  if (encodedEndDate) {
    url += `&endDate=${encodeURIComponent(encodedEndDate)}`;
  }

  const result = await axios.get(url, config);
  return result;
};


const getVideoGiftTransaction = async (filters) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  const { page = 1, searchTerm = '', startDate = null, endDate = null } = filters;

  const encodedSearchTerm = encodeURIComponent(searchTerm);

  let encodedStartDate = '';
  let encodedEndDate = '';

  if (startDate) {
    encodedStartDate = moment.utc(startDate).add(1, 'day').endOf('day').toISOString(); // Keep startDate as is
  }
  if (endDate) {
    encodedEndDate = moment.utc(endDate).add(1, 'day').endOf('day').toISOString(); // Add 1 day to endDate
  }

  let url = `${SERVER_API_URL}/admin/DiamondTransaction/getVideoGiftTransaction?page=${page}&search=${encodedSearchTerm}`;

  if (encodedStartDate) {
    url += `&startDate=${encodeURIComponent(encodedStartDate)}`;
  }
  if (encodedEndDate) {
    url += `&endDate=${encodeURIComponent(encodedEndDate)}`;
  }

  try {
    // Make the API request
    const result = await axios.get(url, config);
    return result;
  } catch (error) {
    // Handle errors gracefully
    console.error("Error fetching video gift transactions:", error);
    return { error: true, message: error.response?.data || error.message };
  }
};
const getAdminTransation = async (filters) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  const { page, searchTerm = '', startDate = null, endDate = null } = filters;
  const encodedSearchTerm = encodeURIComponent(searchTerm);

  let encodedStartDate = '';
  let encodedEndDate = '';

  if (startDate) {
    encodedStartDate = moment.utc(startDate).add(1, 'day').endOf('day').toISOString(); // Keep startDate as is
  }
  if (endDate) {
    encodedEndDate = moment.utc(endDate).add(1, 'day').endOf('day').toISOString(); // Add 1 day to endDate
  }

  let url = `${SERVER_API_URL}/admin/DiamondTransaction/getAdminToUserTransation?page=${page}&search=${encodedSearchTerm}`;

  if (encodedStartDate) {
    url += `&startDate=${encodeURIComponent(encodedStartDate)}`;
  }
  if (encodedEndDate) {
    url += `&endDate=${encodeURIComponent(encodedEndDate)}`;
  }

  const result = await axios.get(url, config);
  return result;
}

const getFeedRoseTransation = async (filters) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  // Extract parameters from filters object
  const { page, searchTerm = '', startDate = null, endDate = null } = filters;
  const encodedSearchTerm = encodeURIComponent(searchTerm);

  let encodedStartDate = '';
  let encodedEndDate = '';
  if (startDate) {
    encodedStartDate = moment.utc(startDate).add(1, 'day').endOf('day').toISOString(); // Keep startDate as is
  }
  if (endDate) {
    encodedEndDate = moment.utc(endDate).add(1, 'day').endOf('day').toISOString(); // Add 1 day to endDate
  }

  let url = `${SERVER_API_URL}/admin/DiamondTransaction/getfeedrosetransation?page=${page}&search=${encodedSearchTerm}`;

  if (encodedStartDate) {
    url += `&startDate=${encodeURIComponent(encodedStartDate)}`;
  }
  if (encodedEndDate) {
    url += `&endDate=${encodeURIComponent(encodedEndDate)}`;
  }


  const result = await axios.get(url, config);
  return result;
}


export { getRoseTransaction, getMessageSubscriptionTransaction, getVideoGiftTransaction, getAdminTransation, getFeedRoseTransation };
