import { SERVER_API_URL, SERVER_DOMAIN } from "../constants/constants";
import axios from "axios";
import moment from 'moment';

const GetAllFeed = async (page, searchTerm = '') => {
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/feed/getallfeed?page=${page}&search=${searchTerm}`;


  const result = await axios.get(url, config);
  // console.log(result)
  return result.data;
};


const GetAllfeedCommentById = async (id) => {
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/feed/getallfeedcommentbyid/${id}`;


  const result = await axios.get(url, config);
  // console.log(result)
  return result.data;
}


const EditFeedcomment = async (data) => {
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/feed/editfeedcomment`;


  const result = await axios.put(url, data, config);
  // console.log(result)
  return result.data;
}

const DeleteFeedComment = async (id) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },

  }
  const url = `${SERVER_API_URL}/admin/feed/deletefeedcomment/${id}`
  const result = await axios.delete(url, config);
  return result.data
}
const FetchUserInfo = async (username) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },

  }

  const url = `${SERVER_API_URL}/admin/feed/featchuser?username=${username}`
  const result = await axios.get(url, config);
  return result.data
}


const GetMutedUser = async (filters) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  console.log(filters, "filtersfilters");

  // Extract parameters from filters object
  const { page, searchTerm = '', startDate = null, endDate = null } = filters;

  // Build the base URL with page and searchTerm
  let url = `${SERVER_API_URL}/admin/feed/getmutedusersdata?page=${page}&search=${searchTerm}`;

  // Append startDate and endDate if they exist
  if (startDate) {
    url += `&startDate=${(startDate)}`;
  }
  if (endDate) {
    url += `&endDate=${(endDate)}`;
  }

  try {
    // Make the API request
    const result = await axios.get(url, config);
    return result.data;
  } catch (error) {
    console.error("Error fetching muted users:", error);
    throw error;
  }
};



const UnMuteComment = async (id) => {
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/feed/unmutecomment/${id}`;


  const result = await axios.delete(url, config);
  // console.log(result)
  return result.data;
}


const handleBlockFeed = async (id, formdata) => {
  const getToken = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/feed/handleBlockFeed/${id}`;

  try {
    const result = await axios.post(url, formdata, config);
    //  console.log(result, "resultfromfrontend");
    return result.data;
  } catch (error) {
    throw error;
  }
}


const getAllBlockFeedDetails = async (filters) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  const { page, searchTerm = '', startDate = null, endDate = null } = filters;

  const encodedSearchTerm = encodeURIComponent(searchTerm);
  let encodedStartDate = '';
  let encodedEndDate = '';
  if (startDate) {
    encodedStartDate = moment.utc(startDate).add(1, 'day').endOf('day').toISOString(); // Keep startDate as is
  }
  if (endDate) {
    encodedEndDate = moment.utc(endDate).add(1, 'day').endOf('day').toISOString(); // Add 1 day to endDate
  }

  let url = `${SERVER_API_URL}/admin/feed/getAllBlockFeedDetails?page=${page}&search=${encodedSearchTerm}`;
  if (encodedStartDate) {
    url += `&startDate=${encodeURIComponent(encodedStartDate)}`;
  }
  if (encodedEndDate) {
    url += `&endDate=${encodeURIComponent(encodedEndDate)}`;
  }
  const result = await axios.get(url, config);
  return result.data;

}

const UnBlockFeed=async(id)=>{
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/feed/UnBlockFeed/${id}`;


  const result = await axios.patch(url, null, config);
  // console.log(result)
  return result.data;
}
export {
  GetAllFeed,
  GetAllfeedCommentById,
  EditFeedcomment,
  DeleteFeedComment,
  FetchUserInfo,
  GetMutedUser,
  UnMuteComment,
  handleBlockFeed,
  getAllBlockFeedDetails,
  UnBlockFeed
}