import { SERVER_API_URL, SERVER_DOMAIN } from "../constants/constants";
import axios from "axios";

const generateReport = async (filters) => {
    // const getToken = localStorage.getItem("token");
    // const config = {
    //     headers: { Authorization: `Bearer ${getToken}` },
    // };
    // const url = `${SERVER_API_URL}/admin/admin_video_report/getVideoReport?page=${page}&search=${searchTerm}`;
    // const result = await axios.get(url, config);
    // return result.data;
    const getToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${getToken}` },
    };
  
    // Extract parameters from filters object
    const { page, searchTerm = '', startDate = null, endDate = null } = filters;
  
    // Build the base URL with page and searchTerm
    let url = `${SERVER_API_URL}/admin/admin_video_report/getVideoReport?page=${page}&search=${searchTerm}`;
  
    // Append startDate and endDate if they exist
    if (startDate) {
      url += `&startDate=${startDate}`;
    }
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
  
    // Make the API request
    const result = await axios.get(url, config);
    return result.data;
};
 

export {
    generateReport
};