import React, { useCallback, useEffect, useState } from 'react';
import IncludeSideBar from '../../Components/Sidebar/IncludeSideBar';
import './index.css';
import { Status } from './enum/Status';
import RenderTableData from './components/RenderTableData';
const allwithdrawmoneyrequest = require('../../apis/withdraw_money_request');

function WithdrawelRequest() {
  const [withdrawmoneydata, setwithdrawmoneydata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const ITEMS_PER_PAGE = 5;

  const getwithdrawmoneydata = useCallback(async (page, filterStatus, query, start, end) => {
    setLoading(true);
    try {
      const response = await allwithdrawmoneyrequest.getwithdrawmoneyrequest(
        page,
        ITEMS_PER_PAGE,
        filterStatus,
        query,
        start,
        end);
      setwithdrawmoneydata(response?.data || []);
      setTotalPages(response?.pagination?.totalPages || 1);
    } catch (error) {
      console.log("Error fetching during withdraw money", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getwithdrawmoneydata(currentPage, statusFilter, searchQuery, startDate, endDate);
  }, [getwithdrawmoneydata, currentPage, statusFilter, searchQuery, startDate, endDate]);

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') setStartDate(value);
    if (name === 'endDate') setEndDate(value);
    setCurrentPage(1);
  };

  return (
    <IncludeSideBar>
      <div className="withdraw-container">
        <h2>Withdraw Money Request Details</h2>

        {/* Search and Filter Section */}
        <div className="filter-container">

          <div className="search-filter">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search by username, nickname, or email"
            />
          </div>

          <div className="date-filter">
            <label>Start Date: </label>
            <input
              type="date"
              name="startDate"
              value={startDate}
              onChange={handleDateChange}
            />
            <label>End Date: </label>
            <input
              type="date"
              name="endDate"
              value={endDate}
              onChange={handleDateChange}
            />
          </div>

          <div className="status-filter">
            <label>Filter by Status: </label>
            <select value={statusFilter} onChange={handleStatusChange}>
              <option value="">All</option>
              <option value={Status.PENDING}>Pending</option>
              <option value={Status.APPROVED}>Approved</option>
              <option value={Status.REJECTED}>Rejected</option>
              <option value={Status.PROCESSING}>Processing</option>
            </select>
          </div>


        </div>

        {loading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <table className="withdraw-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>User ID</th>
                  <th>Username</th>
                  <th>Nickname</th>
                  <th>Paypal Account ID</th>
                  <th>Paypal Email</th>
                  <th>Paypal Account Legal Name</th>
                  <th>Amount In Dollar</th>
                  <th>Total Diamonds</th>
                  <th>Creation Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {withdrawmoneydata.map((withdrawdata, index) => (
                  <RenderTableData withdrawdata={withdrawdata} index={index} />
                ))}
              </tbody>
            </table>
            <div className="pagination">
              <button
                className="pagination-btn"
                disabled={currentPage === 1}
                onClick={handlePrevious}>
                Previous
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                className="pagination-btn"
                disabled={currentPage === totalPages}
                onClick={handleNext}>
                Next
              </button>
            </div>
          </>
        )}
      </div>
    </IncludeSideBar>
  );
}

export default WithdrawelRequest;
