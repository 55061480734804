import {DateTime} from 'luxon';

const formattedDateAndTime = (date) => {
  const inputDate = DateTime.fromISO(date);
  return inputDate.toFormat("dd-LL-yyyy 'at' HH:mm");
};

const formatDateAndTimeForVideo = (date) => {
  const inputDate = DateTime.fromISO(date);
  return inputDate.toFormat('HH:mm d-MMM-yyyy');
};

export {formattedDateAndTime, formatDateAndTimeForVideo};
