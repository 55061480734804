import { SERVER_API_URL } from "../constants/constants";
import axios from "axios";
import moment from 'moment';

const getAllBasicUsers = async (page, searchTerm = '') => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  // const url = `${SERVER_API_URL}/admin/users/getBasicUsers`;
  const url = `${SERVER_API_URL}/admin/users/getBasicUsers?page=${page}&search=${searchTerm}`;

  const result = await axios.get(url, config);
  return result.data;
};
const getAllPremiumUsers = async (page,searchTerm = '') => {
  console.log(page,'page....')
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/getPremiumUsers?page=${page}&search=${searchTerm}`;
  // const url = `${SERVER_API_URL}/admin/users/getPremiumUsers`;

  const result = await axios.get(url, config);
  return result.data;
};

// const getAllUsers = async (page, searchTerm = '') => {
//   try {
//     const getToken = localStorage.getItem("token");
//     const config = {
//       headers: { Authorization: `Bearer ${getToken}` },
//     };
//     const url = `${SERVER_API_URL}/admin/users/getUsers?page=${page}&search=${searchTerm}`;
//     const response = await axios.get(url, config);
//     console.log(response)
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching users:", error);
//     throw error;
//   }
// };

const getAllUsers=async(filters)=>{
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  // Extract parameters from filters object
  const { page, searchTerm = '', startDate = null, endDate = null } = filters;
  // URL encode search term
  const encodedSearchTerm = encodeURIComponent(searchTerm);

  // Format and encode startDate and endDate using moment
  let encodedStartDate = '';
  let encodedEndDate = '';

  // Adjust endDate by adding 1 day to include the entire selected day
  if (startDate) {
    encodedStartDate = moment.utc(startDate).add(1, 'day').endOf('day').toISOString(); // Keep startDate as is
  }
  if (endDate) {
    encodedEndDate = moment.utc(endDate).add(1, 'day').endOf('day').toISOString(); // Add 1 day to endDate
  }

  // Build the base URL with page and searchTerm
    let url = `${SERVER_API_URL}/admin/users/getUsers?page=${page}&search=${encodedSearchTerm}`;
// Append startDate and endDate if they exist
if (encodedStartDate) {
  url += `&startDate=${encodeURIComponent(encodedStartDate)}`;
}
if (encodedEndDate) {
  url += `&endDate=${encodeURIComponent(encodedEndDate)}`;
}
  // // Append startDate and endDate if they exist
  // if (startDate) {
  //   url += `&startDate=${startDate}`;
  // }
  // if (endDate) {
  //   url += `&endDate=${endDate}`;
  // }

  // Make the API request
  const result = await axios.get(url, config);
  return result.data;
}


const deleteUser = async (id) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/deleteUsers/${id}`;

  try {
    const result = await axios.delete(url, config);
    return result.data;
  } catch (error) {
    throw error; // Propagate the error for handling in your component
  }
};



const updateResourceActiveStatus = async (id, isActive, role,totalSeconds,reason) => {
  console.log(role,'rolerole')
  const getToken = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/updateUserActiveStatus/${id}`;

  try {
    const result = await axios.patch(url, { isActive, role, totalSeconds,reason}, config);
    console.log(result, "resultfromfrontend");
    return result.data;
  } catch (error) {
    throw error; // Propagate the error for handling in your component
  }
};






const getBlockUsers = async (page, perPage) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/getBlockedusers/${page}/${perPage}`;
  const result = await axios.get(url, config);
  return result.data;
};


const updateUserStatus = async (userId, status) => {
  console.log(userId, status, "frontend")
  const authToken = localStorage.getItem('token'); // Assuming you store the user's token in localStorage
  const apiUrl = `${SERVER_API_URL}/admin/users/updateUserStatus/${userId}`;

  try {
    const response = await axios.patch(apiUrl, { status }, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response;
  } catch (error) {
    throw error; // You can handle the error in your component
  }
};
const getUserVideos = async (id) => {
  console.log(id)
  try {
    const getToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${getToken}` },
    };
    const url = `${SERVER_API_URL}/admin/users/getUsersVideo/${id}`;
    const response = await axios.get(url, config);
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching user videos:", error);
    throw error;
  }
};

const SendGift = async (data) => {
  console.log(data, "datainuserfrontend")
  try {
    const getToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${getToken}` },
    };
    const url = `${SERVER_API_URL}/admin/users/sendGift`;


    const response = await axios.post(url, data, config);
    // console.log(response,"response")
    return response;
  } catch (error) {
    throw error;
  }
};

const getAllUsersPost = async (id) => {
  console.log(id, "fromfrontend")
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/getuser_photo_post/${id}`;
  const result = await axios.get(url, config);
  return result.data;
};

const changeUserAccountType = async (id, account_type) => {
  console.log(id, account_type, "changeAccount_typeFrontend")
  const getToken = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/changeUserAccount_type/${id}`;

  try {
    const result = await axios.patch(url, { account_type }, config);
    console.log(result, "resultfromfrontend");
    return result.data;
  } catch (error) {
    throw error; // Propagate the error for handling in your component
  }
};



const getlikeintraction = async (id) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  // const url = `${SERVER_API_URL}/admin/users/getBasicUsers`;
  const url = `${SERVER_API_URL}/admin/users/getLikeinteraction/${id}`;

  const result = await axios.get(url, config);
  return result.data;
};

const getsendlikeintraction=async(id)=>{
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
const url=`${SERVER_API_URL}/admin/users/getsenderlikedata/${id}`
const result=await axios.get(url,config);
return result.data;
}

const getcommentintraction = async (id) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  // const url = `${SERVER_API_URL}/admin/users/getBasicUsers`;
  const url = `${SERVER_API_URL}/admin/users/getPostCommentInteraction/${id}`;

  const result = await axios.get(url, config);
  return result.data;
};
const getcommentReplyintraction=async(id)=>{
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  // const url = `${SERVER_API_URL}/admin/users/getBasicUsers`;
  const url = `${SERVER_API_URL}/admin/users/getreplyCommentInteraction/${id}`;

  const result = await axios.get(url, config);
  return result.data;
}
const getrecivedcommetintraction=async(id)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization: `Bearer ${getToken}`},
  }
  const url = `${SERVER_API_URL}/admin/users/getrecivedcomment/${id}`;
  const result=await axios.get(url,config)
  return result.data
}


const getdiamondintraction=async(id)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers: { Authorization: `Bearer ${getToken}`},
  }
  const url= `${SERVER_API_URL}/admin/users/getdiamondinteraction/${id}`
  const result=await axios.get(url,config);
  return result.data
}
const getPostCommentRoseDiamond=async(id)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers: { Authorization: `Bearer ${getToken}`},
  }
  const url= `${SERVER_API_URL}/admin/users/getpostCommentrosediamondinteraction/${id}`
  const result=await axios.get(url,config);
  return result.data
}

const getReplyPostCommentRoseDiamond=async(id)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers: { Authorization: `Bearer ${getToken}`},
  }
  const url= `${SERVER_API_URL}/admin/users/getreplyCommentrosediamondinteraction/${id}`
  const result=await axios.get(url,config);
  return result.data
}

const getAdminDiamond=async(id)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers: { Authorization: `Bearer ${getToken}`},
  }
  const url= `${SERVER_API_URL}/admin/users/getadmindiamoond/${id}`
  const result=await axios.get(url,config);
  return result.data
}
const getshareintraction=async(id)=>{
const getToken=localStorage.getItem("token");
const config={
  headers:{Authorization:`Bearer ${getToken}`},
}
const url=`${SERVER_API_URL}/admin/users/getshareintraction/${id}`
const result=await axios.get(url,config);
return result.data

}
const getSendGiftDiamond=async(id)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`},
  }
  const url=`${SERVER_API_URL}/admin/users/getsendgiftdiamond/${id}`
  const result=await axios.get(url,config);
  return result.data
  
}

const getSendCommentRoseDiamond=async(id)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`},
  }
  const url=`${SERVER_API_URL}/admin/users/getsendcommentroseDiamond/${id}`
  const result=await axios.get(url,config);
  return result.data

}
const getSendReplyCommentRoseDiamond=async(id)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`},
  }
  const url=`${SERVER_API_URL}/admin/users/getsendrplycommentroseDiamond/${id}`
  const result=await axios.get(url,config);
  return result.data
}
const getsenddiamond=async(id)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers: { Authorization: `Bearer ${getToken}`},
  }
  const url= `${SERVER_API_URL}/admin/users/getsenderdiamond/${id}`
  const result=await axios.get(url,config);
  return result.data

}

const getrecivedshare=async(id)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`}
  }
  const url= `${SERVER_API_URL}/admin/users/getrecivedshare/${id}`
  const result=await axios.get(url,config);
  return result.data

}

const getRecivedCommentReplyintraction=async(id)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`}
  }
  const url= `${SERVER_API_URL}/admin/users/getrecivedreplycomment/${id}`
  const result=await axios.get(url,config);
  return result.data
}

const AccountDeletionRequest=async(data)=>{
  // console.log(data,'data')
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`}
  }
  const url= `${SERVER_API_URL}/admin/users/accountdeletionrequestfromadmin`
  const result=await axios.post(url,data,config);
  return result.data

}


const getdeletionrequestdata=async(page, searchTerm = '')=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`}
  }
  // const url= `${SERVER_API_URL}/admin/users/getAccountDeletionRequestFromAdmin`
  const url = `${SERVER_API_URL}/admin/users/getAccountDeletionRequestFromAdmin?page=${page}&search=${searchTerm}`;

  const result=await axios.get(url,config);
  return result.data
}

const Deletedeltionrequest=async(user_id)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`}
  }
  const url= `${SERVER_API_URL}/admin/users/deleteDeletionrequst/${user_id}`
  const result=await axios.delete(url,config);
  return result.data
}



const getBlockUsersrequest = async (page,searchTerm = '') => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/getblockedusersrequest?page=${page}&search=${searchTerm}`;
  const result = await axios.get(url, config);
  return result.data;
};

const UserverificationAccountrequest = async (data) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/userverificationaccountrequest`;
  const result = await axios.post(url,data,config);
  return result.data;
};

const getallvirificationrequest = async (page, searchTerm = '') => {
  console.log(searchTerm,'searchTerm')
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  // const url = `${SERVER_API_URL}/admin/users/allverificationrequestdetail`;
  const url = `${SERVER_API_URL}/admin/users/allverificationrequestdetail?page=${page}&search=${searchTerm}`;

  const result = await axios.get(url, config);
  return result.data;
};

const generateVerificationLink=async(data)=>{
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/generateVerificationLink`;
  const result = await axios.post(url, data,config);
  return result.data;

}
const generateVerificationscanLink=async(data)=>{
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/generateVerificationscanLink`;
  const result = await axios.post(url, data,config);
  return result.data;
}
const getfordocumentverificationUserById=async(userId)=>{
  
  const url = `${SERVER_API_URL}/admin/users/userdetailforverification/${userId}`;
  const result = await axios.get(url);
  return result.data;
}

const updateuserfollower=async(id,follower)=>{
  console.log(id,follower,'follower')
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/updateuserfollowercount/${id}`;

  try {
    // Send the Diamond_value value as part of the request body
    const data = { follower :follower};

    const result = await axios.patch(url, data, config);
    return result.data;
  } catch (error) {
    throw error; // Propagate the error for handling in your component
  }

}

const updateuserunfollow=async(id,unfollow)=>{
  const getToken = localStorage.getItem("token");
  console.log(getToken);
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/updateuserunfollowcount/${id}?unfollow=${unfollow}`;

  try {
    const result = await axios.delete(url, config);
    console.log(result);
    return result.data;
  } catch (error) {
    throw error;
  }

}

const getAllBusinessUser=async(page, searchTerm = '')=>{
  // console.log(searchTerm,'searchTerm')
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/allbusinessuserdetails?page=${page}&search=${searchTerm}`;

  const result = await axios.get(url, config);
  return result.data;

}

const userIntraction=async(id)=>{
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/userintractioninfo/${id}`;

  const result = await axios.get(url, config);
  return result.data;
}



// const MuteUserComment = async (id,mutefor,totalSeconds,reason) => {
//   // console.log(role,'rolerole')
//   const getToken = localStorage.getItem('token');
//   const config = {
//     headers: { Authorization: `Bearer ${getToken}` },
//   };
//   const url = `${SERVER_API_URL}/admin/users/muteusercomment/${id}`;

//   try {
//     const result = await axios.post(url, { mutefor,totalSeconds,reason}, config);
//     console.log(result, "resultfromfrontend");
//     return result.data;
//   } catch (error) {
//     throw error; 
//   }
// };
const MuteUserComment = async (id,formdata) => {
  // console.log(role,'rolerole')
  const getToken = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/muteusercomment/${id}`;

  try {
    const result = await axios.post(url, formdata, config);
    console.log(result, "resultfromfrontend");
    return result.data;
  } catch (error) {
    throw error; 
  }
};


const GetPrivateParticipantsChatUser = async (id) => {
  // console.log(role,'rolerole')
  const getToken = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/getprivatechatuserinfo/${id}`;

  try {
    const result = await axios.get(url, config);
    console.log(result, "resultfromfrontend");
    return result.data;
  } catch (error) {
    throw error; 
  }
};

const GetChatInfoWithSelectedUser = async (selectduserId,ParticipantsuserId) => {
  // console.log(role,'rolerole')
  const getToken = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  // const url = `${SERVER_API_URL}/admin/users/getchatinfowithselecteduser/${id}`;
  const url = `${SERVER_API_URL}/admin/users/getchatinfowithselecteduser?selectduserId=${selectduserId}&ParticipantsuserId=${ParticipantsuserId}`;

  try {
    const result = await axios.get(url, config);
    console.log(result, "resultfromfrontend");
    return result.data;
  } catch (error) {
    throw error; 
  }
};

const EditPrivateChatMessage=async(data)=>{
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/editprivatechat`;

  try {

    const result = await axios.put(url, data, config);
    return result.data;
  } catch (error) {
    throw error; 
  }
}


const DeletePrivateChat=async(id)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`},

  }
  const url= `${SERVER_API_URL}/admin/users/deleteprivatechat/${id}`
  const result=await axios.delete(url,config);
  return result.data
}

const GetMutedPrivatechat=async(filters)=>{
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  console.log(filters, "filtersfilters");

  // Extract parameters from filters object
  const { page, searchTerm = '', startDate = null, endDate = null } = filters;

  // Build the base URL with page and searchTerm
  let url = `${SERVER_API_URL}/admin/users/getmutedprivatechat?page=${page}&search=${searchTerm}`;

  // Append startDate and endDate if they exist
  if (startDate) {
    url += `&startDate=${(startDate)}`;
  }
  if (endDate) {
    url += `&endDate=${(endDate)}`;
  }

  try {
    // Make the API request
    const result = await axios.get(url, config);
    return result.data;
  } catch (error) {
    console.error("Error fetching muted users:", error);
    throw error;
  }

}

const GetFeedUserInfo=async(id)=>{
  
  const getToken = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/users/getUserFeedInfo/${id}`;

  try {
    const result = await axios.get(url, config);
    console.log(result, "resultfromfrontend");
    return result.data;
  } catch (error) {
    throw error; 
  }
}
export { getAllUsers, deleteUser, updateResourceActiveStatus, getBlockUsers, updateUserStatus, getUserVideos, SendGift, getAllBasicUsers, getAllPremiumUsers, getAllUsersPost, changeUserAccountType, getlikeintraction,getcommentintraction,getdiamondintraction,getshareintraction ,getsenddiamond,getsendlikeintraction,getrecivedcommetintraction,getrecivedshare,AccountDeletionRequest,Deletedeltionrequest,getdeletionrequestdata,getBlockUsersrequest,UserverificationAccountrequest,getallvirificationrequest,generateVerificationLink,getfordocumentverificationUserById,generateVerificationscanLink,updateuserfollower,updateuserunfollow,getAllBusinessUser,userIntraction,getPostCommentRoseDiamond,getReplyPostCommentRoseDiamond,getAdminDiamond,getSendGiftDiamond,getSendCommentRoseDiamond,getSendReplyCommentRoseDiamond,getcommentReplyintraction,getRecivedCommentReplyintraction,MuteUserComment,GetPrivateParticipantsChatUser,GetChatInfoWithSelectedUser,EditPrivateChatMessage,DeletePrivateChat,GetMutedPrivatechat,GetFeedUserInfo};
