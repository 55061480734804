// import React, { useState, useEffect } from 'react';
// import IncludeSideBar from '../../Components/Sidebar/IncludeSideBar';
// import { FaGem } from 'react-icons/fa'; // Import diamond icon

// function DiamondRateConverstion() {
//   const allDiamondRateApi = require("../../../src/apis/diamondRate");

//   const [searchTerm, setSearchTerm] = useState('Germany');
//   const [countries, setCountries] = useState([]);
//   const [filteredCountries, setFilteredCountries] = useState([]);
//   const [selectedCountry, setSelectedCountry] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [regions, setRegions] = useState([]);  // To store unique regions
//   const [selectedRegion, setSelectedRegion] = useState('Europe');  // Default region is "Europe"

//   const [CurrencyData, setCurrencyData] = useState([]);

//   const [diamondValue, setDiamondValue] = useState(100);  
//   const [price, setPrice] = useState(1);  

//   const fetchCountryDetails = async () => {
//     try {
//       setLoading(true);
//       const response = await allDiamondRateApi.getCountryDetails();
//       setCountries(response.data);

//       // Extract unique regions
//       const uniqueRegions = [...new Set(response.data.map(country => country.region))];
//       setRegions(uniqueRegions);

//       // Automatically filter and set Germany as the default country
//       const germany = response.data.find(country => country.name === 'Germany');
//       if (germany) {
//         setSelectedCountry(germany);
//         setFilteredCountries(response.data.filter(country => country.region === 'Europe'));
//       }
//     } catch (error) {
//       console.error("Error fetching country details:", error);
//     } finally {
//       setLoading(false);  // Hide loader after data retrieval
//     }
//   };

//   useEffect(() => {
//     fetchCountryDetails();
//   }, []);

//   // Filter countries based on the search input and selected region
//   const handleSearch = (event) => {
//     setSearchTerm(event.target.value);
//     const filtered = countries.filter(country =>
//       country.region === selectedRegion &&
//       country.name.toLowerCase().includes(event.target.value.toLowerCase())
//     );
//     setFilteredCountries(filtered);
//   };

//   // Handle country selection
//   const handleCountrySelect = (country) => {
//     setSelectedCountry(country);
//     setSearchTerm(country.name);
//     setFilteredCountries([]);  // Close dropdown after selection
//   };

//   // Handle region selection
//   const handleRegionSelect = (event) => {
//     setSelectedRegion(event.target.value);
//     setFilteredCountries(countries.filter(country => country.region === event.target.value));
//     setSearchTerm('');  // Clear search term
//   };

//   // Handle diamond value change
//   const handleDiamondValueChange = (event) => {
//     const diamonds = event.target.value;
//     setDiamondValue(diamonds);

//     // Conversion: 100 diamonds = 1 rupee
//     const convertedPrice = (diamonds / 100).toFixed(2);
//     setPrice(convertedPrice);
//   };

//   const fetchDiamondAndCurrencyDetailsByCountryId = async (selectedCountry) => {
//     try {
//       setLoading(true);
//       const countryId = selectedCountry;

//       const response = await allDiamondRateApi.getDiamondAndCurrencyByCountryId(countryId);
//       setCurrencyData(response.data);
//       console.log(response,'responseresponseresponse')

//     } catch (error) {
//       console.error("Error fetching details:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (selectedCountry) {
//       fetchDiamondAndCurrencyDetailsByCountryId(selectedCountry); // Fetch data only if a country is selected
//     }
//   }, [selectedCountry]);

//   return (
//     <IncludeSideBar>
//       <h3 style={{ textAlign: 'center', marginTop: '20px' }}>Diamond Rate Converter</h3>
//       <div style={{ width: '100%', padding: '20px', boxSizing: 'border-box' }}>
//         <div style={{
//           display: 'flex',
//           justifyContent: 'center',
//           boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
//           padding: '20px',
//           borderRadius: '5px',
//           backgroundColor: '#fff',
//           width: '100%',
//         }}>
//           <div style={{ display: 'flex', gap: '10px', width: '100%', maxWidth: '1200px' }}>
//             {/* Region Selection Dropdown */}
//             <div style={{ flex: 1 }}>
//               <select
//                 id="region-select"
//                 value={selectedRegion}
//                 onChange={handleRegionSelect}
//                 style={{
//                   width: '100%',
//                   padding: '10px',
//                   borderRadius: '5px',
//                   border: '1px solid #ddd',
//                   outline: 'none',
//                 }}
//               >
//                 <option value="">--Select a Region--</option>
//                 {regions.map((region, index) => (
//                   <option key={index} value={region}>
//                     {region}
//                   </option>
//                 ))}
//               </select>
//             </div>

//             {/* Search Bar for Countries */}
//             <div style={{ flex: 1, position: 'relative' }}>
//               <input
//                 type="text"
//                 placeholder="Search for a country..."
//                 value={searchTerm}
//                 onChange={handleSearch}

//                 style={{
//                   width: '100%',
//                   padding: '10px',
//                   borderRadius: '5px',
//                   border: '1px solid #ddd',
//                   outline: 'none',
//                 }}
//               />
//               {/* Dropdown for showing filtered country list */}
//               {filteredCountries.length > 0 && searchTerm !== 'Germany' && (
//                 <ul style={{
//                   position: 'absolute',
//                   width: '100%',
//                   backgroundColor: '#fff',
//                   boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
//                   listStyle: 'none',
//                   padding: '0',
//                   margin: '0',
//                   zIndex: 1000,
//                   maxHeight: '200px',  // Approximately 10 countries (adjust as needed)
//                   overflowY: 'auto',  // Enable scrolling
//                   border: '1px solid #ddd',
//                   borderRadius: '5px'
//                 }}>
//                   {filteredCountries.map(country => (
//                     <li
//                       key={country.id}
//                       onClick={() => handleCountrySelect(country)}
//                       style={{
//                         padding: '10px',
//                         cursor: 'pointer',
//                         borderBottom: '1px solid #ddd',
//                         display: 'flex',
//                         alignItems: 'center',
//                       }}
//                     >
//                       <img
//                         src={country.flagurl}
//                         alt={country.name}
//                         style={{ width: '20px', marginRight: '10px' }}
//                       />
//                       {country.name} (+{country.phonecode})
//                     </li>
//                   ))}
//                 </ul>
//               )}
//             </div>
//           </div>
//         </div>

//         {/* Diamond conversion section */}
//         <div style={{ marginTop: '20px' }}>
//           <h4>Diamond to Currency Conversion</h4>
//           <label>Enter Diamonds:</label>
//           <input
//             type="number"
//             value={diamondValue}
//             onChange={handleDiamondValueChange}
//             style={{
//               padding: '10px',
//               margin: '10px 0',
//               borderRadius: '5px',
//               border: '1px solid #ddd',
//               outline: 'none',
//               width: '100px',
//             }}
//           />
//           <p>Equivalent Price: ₹ {price}</p>
//         </div>

//         {/* Full-width table for displaying selected country details */}
//         {selectedCountry && (
//           <>
//             <table style={{
//               width: '100%',
//               marginTop: '20px',
//               borderCollapse: 'collapse',
//               boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
//               borderRadius: '5px',
//               overflow: 'hidden',
//             }}>
//               <thead>
//                 <tr style={{ backgroundColor: '#f5f5f5' }}>
//                   <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Country Code</th>
//                   <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Flag Image</th>
//                   <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Capital</th>
//                   <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Currency</th>
//                   <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Region</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{selectedCountry.code}</td>
//                   <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
//                     <img src={selectedCountry.flagurl} alt={selectedCountry.name} style={{ width: '50px' }} />
//                   </td>
//                   <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{selectedCountry.capital}</td>
//                   <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{selectedCountry.currency}</td>
//                   <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{selectedCountry.region}</td>
//                 </tr>
//               </tbody>
//             </table>

//             {/* Add Diamond and Currency Data */}
//             <table style={{
//               width: '100%',
//               marginTop: '20px',
//               borderCollapse: 'collapse',
//               boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
//               borderRadius: '5px',
//               overflow: 'hidden',
//             }}>
//               <thead>
//                 <tr style={{ backgroundColor: '#f5f5f5' }}>
//                   <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Diamonds</th>
//                   <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Price in {selectedCountry.currency}</th>
//                   <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Region</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {/* {CurrencyData.map((currency, index) => (
//                   <tr key={index}>
//                     <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{currency.diamond_value}</td>
//                     <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{currency.currency_value}</td>
//                     <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{selectedCountry.region}</td>
//                   </tr>
//                 ))} */}
//               </tbody>
//             </table>
//           </>
//         )}
//       </div>
//     </IncludeSideBar>
//   );
// }

// export default DiamondRateConverstion;


import React, { useState, useEffect } from 'react';
import IncludeSideBar from '../../Components/Sidebar/IncludeSideBar';
import { FaGem } from 'react-icons/fa'; // Import diamond icon

function DiamondRateConversion() {
  const allDiamondRateApi = require("../../../src/apis/diamondRate");

  const [searchTerm, setSearchTerm] = useState('Germany');
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('Europe');
  const [CurrencyData, setCurrencyData] = useState({});
  const [diamondValue, setDiamondValue] = useState(100);
  const [price, setPrice] = useState(1);
  const [showConverter, setShowConverter] = useState(false); // Toggle state for showing converter

  // Fetch country details
  const fetchCountryDetails = async () => {
    try {
      setLoading(true);
      const response = await allDiamondRateApi.getCountryDetails();
      setCountries(response.data);
      const uniqueRegions = [...new Set(response.data.map(country => country.region))];
      setRegions(uniqueRegions);
      const germany = response.data.find(country => country.name === 'Germany');
      if (germany) {
        setSelectedCountry(germany);
        setFilteredCountries(response.data.filter(country => country.region === 'Europe'));
      }
    } catch (error) {
      console.error("Error fetching country details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCountryDetails();
  }, []);

  // Search handler
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const filtered = countries.filter(country =>
      country.region === selectedRegion &&
      country.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredCountries(filtered);
  };

  // Country select handler
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setSearchTerm(country.name);
    setFilteredCountries([]);
  };

  // Region select handler
  const handleRegionSelect = (event) => {
    setSelectedRegion(event.target.value);
    setFilteredCountries(countries.filter(country => country.region === event.target.value));
    setSearchTerm('');
  };

  // Fetch diamond and currency details by country ID
  const fetchDiamondAndCurrencyDetailsByCountryId = async () => {
    try {
      setLoading(true);
      if (selectedCountry) {
        const response = await allDiamondRateApi.getDiamondAndCurrencyByCountryId(selectedCountry.id);
        setCurrencyData(response.data);
        setDiamondValue(response.data.diamond_value); // Set initial diamond value from backend
        setPrice(response.data.currency_value); // Set initial price from backend
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    } finally {
      setLoading(false);
    }
  };

  // Diamond value change handler
  const handleDiamondValueChange = (event) => {
    const diamonds = event.target.value;
    setDiamondValue(diamonds);
    // Calculate new price based on the input diamond value and the fixed currency_value from backend
    const convertedPrice = ((diamonds / CurrencyData.diamond_value) * CurrencyData.currency_value).toFixed(2);
    setPrice(convertedPrice);
  };

  // Toggle converter visibility and fetch data on button click
  const handleOpenConverter = () => {
    setShowConverter(!showConverter);
    if (!showConverter && selectedCountry) {
      fetchDiamondAndCurrencyDetailsByCountryId(); // Fetch details only when the converter is opened
    }
  };

  return (
    <IncludeSideBar>
      <h3 style={{ textAlign: 'center', marginTop: '20px' }}>Diamond Rate Converter</h3>
      <div style={{ width: '100%', padding: '20px', boxSizing: 'border-box' }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          padding: '20px',
          borderRadius: '5px',
          backgroundColor: '#fff',
          width: '100%',
        }}>
          <div style={{ display: 'flex', gap: '10px', width: '100%', maxWidth: '1200px' }}>
            {/* Region Selection */}
            <div style={{ flex: 1 }}>
              <select
                value={selectedRegion}
                onChange={handleRegionSelect}
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ddd',
                  outline: 'none',
                }}
              >
                <option value="">--Select a Region--</option>
                {regions.map((region, index) => (
                  <option key={index} value={region}>{region}</option>
                ))}
              </select>
            </div>

            {/* Country Search Bar */}
            <div style={{ flex: 1, position: 'relative' }}>
              <input
                type="text"
                placeholder="Search for a country..."
                value={searchTerm}
                onChange={handleSearch}
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ddd',
                  outline: 'none',
                }}
              />
              {filteredCountries.length > 0 && searchTerm !== 'Germany' && (
                <ul style={{
                  position: 'absolute',
                  width: '100%',
                  backgroundColor: '#fff',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  listStyle: 'none',
                  padding: '0',
                  margin: '0',
                  zIndex: 1000,
                  maxHeight: '200px',
                  overflowY: 'auto',
                  border: '1px solid #ddd',
                  borderRadius: '5px'
                }}>
                  {filteredCountries.map(country => (
                    <li
                      key={country.id}
                      onClick={() => handleCountrySelect(country)}
                      style={{
                        padding: '10px',
                        cursor: 'pointer',
                        borderBottom: '1px solid #ddd',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <img src={country.flagurl} alt={country.name} style={{ width: '20px', marginRight: '10px' }} />
                      {country.name} (+{country.phonecode})
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        {selectedCountry && (
          <table
            style={{
              width: '100%',
              marginTop: '20px',
              borderCollapse: 'collapse',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '5px',
              overflow: 'hidden',
            }}
          >
            <thead>
              <tr style={{ backgroundColor: '#f5f5f5' }}>
                <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Country Code</th>
                <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Flag Image</th>
                <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Capital</th>
                <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Currency</th>
                <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Region</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>+ {selectedCountry.phonecode}</td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                  <img src={selectedCountry.flagurl} alt={selectedCountry.name} style={{ width: '50px' }} />
                </td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{selectedCountry.capital}</td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{selectedCountry.currency}</td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{selectedCountry.region}</td>
              </tr>
            </tbody>
          </table>
        )}

        {/* Button to open/close converter */}
        <button
          onClick={handleOpenConverter}
          style={{
            padding: '10px 20px',
            marginTop: '20px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          {showConverter ? 'Close Converter' : 'Open Converter'}
        </button>

        {/* Diamond conversion section */}
        {showConverter && (
          <div style={{ marginTop: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px', borderRadius: '5px', backgroundColor: '#fff' }}>
            <h4>Diamond to Currency Conversion</h4>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                <span style={{

                  // transform: 'translateY(-50%)',
                  color: '#888',
                  paddingRight: '20px'
                }}><b>Diamond Value =</b> <FaGem style={{
                  marginTop: '20px',
                  transform: 'translateY(-50%)',
                  color: '#edd30e',
                  fontSize: '20px'  // Increase the size of the icon
                }} />  {CurrencyData?.diamond_value}</span>
                <span style={{

                  // transform: 'translateY(-50%)',
                  color: '#888',
                }}><b>Price =</b> {selectedCountry?.currency}  {CurrencyData?.currency_value}</span>

               <div>
               <label>Enter Diamonds:</label>
                <input
                  type="number"
                  value={diamondValue}
                  onChange={handleDiamondValueChange}
                  style={{
                    padding: '10px',
                    margin: '10px 0',
                    borderRadius: '5px',
                    border: '1px solid #ddd',
                    outline: 'none',
                    width: '100px',
                  }}
                />
                <p>Conversion Currency Value = {selectedCountry.currency} {price}</p>
               </div>
              </>
            )}
          </div>
        )}
      </div>
    </IncludeSideBar>
  );
}

export default DiamondRateConversion;
