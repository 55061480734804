import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar'
import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import useAuth from "../../../useAuth";

import * as verifydocumentapi from '../../../apis/verifydocumentupload'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'; // Import FontAwesome icons

function Document_data() {
    const { user } = useAuth();
    const role = user ? user.role : null;
    const alluserverificationrequestapi = require('../../../apis/verification_request_by_user');

    const hasViewDocument = user?.permissions?.Verification?.['View Document']?.selected || user?.role === 'superadmin';
    const hasCancelApproved = user?.permissions?.Verification?.['Cancel Approved']?.selected || user?.role === 'superadmin';

    const allUserApis = require('../../../apis/users');
    const [verifydocument, setverifydocument] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('')
    const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
    const [inputFocus, setInputFocus] = useState(false);
    const [buttonFocus, setButtonFocus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const getallverificationdocument = async (page, searchTerm = '') => {
        try {
            const response = await verifydocumentapi.getalldocument(page, searchTerm)
            console.log(response.data, "response from blocked users");
            setverifydocument(response.data)
            setTotalPages(response.pagination.totalPages);

        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    // useEffect(() => {
    //     getallverificationdocument();
    // }, []);


    useEffect(() => {
        getallverificationdocument(currentPage, searchTerm);
    }, [currentPage, searchTerm]);

    const handleSearch = (searchValue) => {
        setSearchTerm(searchValue);
    };


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            // setCurrentPage((prevPage) => prevPage + 1);
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            // setCurrentPage((prevPage) => prevPage - 1);
            setCurrentPage(currentPage - 1);
        }
    };
    const handleGoToPage = () => {
        const pageNumber = parseInt(inputPageNumber, 10);
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            setInputPageNumber('')
        } else {
            alert(`Please enter a valid page number between 1 and ${totalPages}`);
        }
    };


    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, options);
    }
    const handleOpenModal = (document) => {
        setSelectedDocument(document);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedDocument(null);
        setModalOpen(false);
    };
    const handleNoAccess = () => {
        alert('You are not able to access this feature.');
    };
    const CancelApproved=async(userid)=>{
        const confirmed = window.confirm("Are you sure you want to Cancel Approved?");
        if (confirmed) {
          // console.log(userid, 'user_iduser_iduser_id');
          setIsLoading(true);
          try {
            // const data = {
            //   userid: userid,
             
            // }
            console.log(userid,'userid approve')
            // console.log(data, 'user_iduser_iduser_id');
    
            const response = await alluserverificationrequestapi.CancelApprovedByAdmin(userid);
    
            if (response.success) {
              // Show success message
              alert("Cancel Approved Request successfully");
              // Optionally, update UI or fetch updated data
            } else {
              // Show message returned from backend
              alert(response.message);
            }
          } catch (error) {
            console.error("Error cancelapproved:", error);
            alert("Failed to handle approval");
          } finally {
            setIsLoading(false);
          }
        }
      }
    
    return (

        <IncludeSideBar>
            <div>
                <p>
                    Here we are displaying the sections of Verification Document.
                </p>
                <input
                    type="text"
                    placeholder="Search by user name"
                    value={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                />
                <table>
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>User ID</th>
                            <th>User Name</th>

                            <th>Nick name</th>
                            <th>Email</th>
                            <th>Apply Date</th>
                            <th>Verifyed User</th>


                            <th>Action</th> {/* New column for the Status button */}
                        </tr>
                    </thead>
                    <tbody>
                        {verifydocument.map((user, index) => {
                            const userIsVerified = user.user.verified === 1;

                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{user.user.id}</td>
                                    <td>
                                        {user.user.username}
                                    </td>

                                    <td>{user.user.nickname}</td>
                                    <td>{user.user.email}</td>
                                    <td>{formatDate(user.createdAt)}</td>
                                    <td>
                                        <div style={ticstyle}>
                                            {userIsVerified ? (
                                                <FaCheckCircle style={{ color: 'green', fontSize: '24px' }} />
                                            ) : (
                                                <FaTimesCircle style={{ color: 'red', fontSize: '24px' }} />
                                            )}
                                        </div>
                                    </td>


                                    <td>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <button onClick={() => {
                                                if (hasViewDocument) {
                                                    handleOpenModal(user)
                                                } else {
                                                    handleNoAccess()
                                                }
                                            }}
                                                style={{
                                                    backgroundColor:
                                                        !hasViewDocument
                                                            ? '#C5C6C7'
                                                            : '', // Set red background when there is access
                                                    cursor: !hasViewDocument ? 'not-allowed' : 'pointer', // Changes cursor when disabled
                                                }}
                                            >View Document</button>
                                            <button
                                                onClick={() => {
                                                    if(hasCancelApproved){
                                                        CancelApproved(user.user.id)

                                                    }else{
                                                        handleNoAccess()
                                                    }
                                                }}
                                                style={{
                                                    backgroundColor:
                                                        !hasCancelApproved
                                                            ? '#C5C6C7'
                                                            : '', // Set red background when there is access
                                                    cursor: !hasCancelApproved ? 'not-allowed' : 'pointer', // Changes cursor when disabled
                                                }}
                                            >
                                                Cancel Approved
                                            </button>

                                        </div>
                                    </td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>

                <div className="pagination">
                    <button onClick={handlePrevious}>Previous</button>
                    {Array.from({ length: Math.min(totalPages, 20) }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                            className={currentPage === index + 1 ? "active" : ""}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button onClick={handleNext}>Next</button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
                    <input
                        type="number"
                        value={inputPageNumber}
                        onChange={(e) => setInputPageNumber(e.target.value)}
                        placeholder="Page No"
                        style={{
                            padding: '5px',
                            fontSize: '14px',
                            // border: '1px solid #ccc',
                            border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

                            borderRadius: '4px 0 0 4px',
                            outline: 'none',
                            width: '90px'
                        }}
                        onFocus={() => setInputFocus(true)}
                        onBlur={() => setInputFocus(false)}
                    />
                    <button
                        onClick={handleGoToPage}
                        style={{
                            padding: '6.5px 10px',
                            fontSize: '14px',
                            border: '1px solid #ccc',
                            borderLeft: 'none',
                            borderRadius: '0 4px 4px 0',
                            // backgroundColor: '#007bff',

                            backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
                            color: 'white',
                            cursor: 'pointer',
                            outline: 'none',
                        }}


                    >
                        Go
                    </button>
                </div>

            </div>
            <DocumentDetailModal
                isOpen={modalOpen}
                onRequestClose={handleCloseModal}
                document={selectedDocument}
            />
        </IncludeSideBar >

    )
}

export default Document_data

const ticstyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

}

const DocumentDetailModal = ({ isOpen, onRequestClose, document }) => {
    if (!document) return null;

    const getDisplayValue = (value) => (value ? value : 'N/A');

    const modalStyles = {
        display: isOpen ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: '1000',
        overflow: 'auto',
    };

    const modalContentStyles = {
        backgroundColor: '#fff',
        borderRadius: '15px',
        width: '600px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'left',
        position: 'relative',
        maxHeight: '80%',
        overflowY: 'auto',
    };

    const headerStyles = {
        backgroundColor: '#f0f0f0',
        padding: '10px',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    };

    const closeButtonStyles = {
        backgroundColor: '#ff4d4f',
        color: 'white',
        padding: '5px 10px',
        border: 'none',
        borderRadius: '50%',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    };

    const profileSectionStyles = {
        display: 'flex',
        alignItems: 'center',
        padding: '20px',
        borderBottom: '1px solid #ddd',
    };

    const profileImgStyles = {
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        objectFit: 'cover',
        marginRight: '20px',
    };

    const userDetailsStyles = {
        flex: 1,
    };

    const userDetailsLabelStyles = {
        fontWeight: 'bold',
        color: '#555',
        marginBottom: '5px',
    };

    const userDetailsValueStyles = {
        color: '#333',
    };

    const documentSectionStyles = {
        padding: '20px',
    };

    const documentItemStyles = {
        marginBottom: '20px',
        display: 'flex',
        alignItems: 'center',
    };

    const documentImgStyles = {
        width: '100px',
        height: '100px',
        borderRadius: '10px',
        marginRight: '20px',
        objectFit: 'cover',
    };

    const documentInfoStyles = {
        flex: 1,
    };

    const documentLabelStyles = {
        fontWeight: 'bold',
        color: '#555',
        marginBottom: '5px',
    };

    const documentDownloadButtonStyles = {
        backgroundColor: '#007bff',
        color: 'white',
        padding: '8px 15px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    };

    const handleDownload = (url, fileName) => {
        const data = `https://dpcst9y3un003.cloudfront.net/${url}`
        saveAs(data, fileName)
    };

    return (
        <div style={modalStyles}>
            <div style={modalContentStyles}>
                <div style={headerStyles}>
                    <h2>Document Details</h2>
                    <button
                        style={{ ...closeButtonStyles, marginRight: '10px' }}
                        onClick={onRequestClose}
                        onMouseEnter={(e) => (e.target.style.backgroundColor = '#ff1a1a')}
                        onMouseLeave={(e) => (e.target.style.backgroundColor = '#ff4d4f')}
                    >
                        &times;
                    </button>
                </div>
                <div style={profileSectionStyles}>
                    <img src={document.user.profile_pic} alt="Profile" style={profileImgStyles} />
                    <div style={userDetailsStyles}>
                        <div>
                            <span style={userDetailsLabelStyles}>User ID:</span>
                            <span style={userDetailsValueStyles}>{getDisplayValue(document.user_id)}</span>
                        </div>
                        <div>
                            <span style={userDetailsLabelStyles}>Username:</span>
                            <span style={userDetailsValueStyles}>{getDisplayValue(document.user.username)}</span>
                        </div>
                    </div>
                </div>
                <div style={documentSectionStyles}>

                    {document.photo && (
                        <div>
                            <span style={documentLabelStyles}>Clear Photo</span>

                            <div style={documentItemStyles}>

                                <img src={`https://dpcst9y3un003.cloudfront.net/${document.photo}`} alt="Clear Photo" style={documentImgStyles} />
                                <div style={documentInfoStyles}>
                                    <button
                                        style={documentDownloadButtonStyles}
                                        onClick={() => handleDownload(document.photo, 'clear_photo.jpg')}
                                    >
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {document.passport && (
                        <div>
                            <span style={documentLabelStyles}>Passport</span>

                            <div style={documentItemStyles}>
                                <img src={`https://dpcst9y3un003.cloudfront.net/${document.passport}`} alt="Passport" style={documentImgStyles} />
                                <div style={documentInfoStyles}>
                                    <button
                                        style={documentDownloadButtonStyles}
                                        onClick={() => handleDownload(document.passport, 'passport.jpg')}
                                    >
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {document.drivingLicenceFront && (
                        <div>
                            <span style={documentLabelStyles}>Driving License Front</span>

                            <div style={documentItemStyles}>
                                <img src={`https://dpcst9y3un003.cloudfront.net/${document.drivingLicenceFront}`} alt="Driving License Front" style={documentImgStyles} />
                                <div style={documentInfoStyles}>
                                    <button
                                        style={documentDownloadButtonStyles}
                                        onClick={() => handleDownload(document.drivingLicenceFront, 'driving_license_front.jpg')}
                                    >
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {document.drivingLicenceBack && (
                        <div>
                            <span style={documentLabelStyles}>Driving License Back</span>

                            <div style={documentItemStyles}>
                                <img src={`https://dpcst9y3un003.cloudfront.net/${document.drivingLicenceBack}`} alt="Driving License Back" style={documentImgStyles} />
                                <div style={documentInfoStyles}>
                                    <button
                                        style={documentDownloadButtonStyles}
                                        onClick={() => handleDownload(document.drivingLicenceBack, 'driving_license_back.jpg')}
                                    >
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {document.otherIdProofFront && (
                        <div>
                            <span style={documentLabelStyles}>Driving License Back</span>

                            <div style={documentItemStyles}>
                                <img src={`https://dpcst9y3un003.cloudfront.net/${document.otherIdProofFront}`} alt="Other ID Proof Front" style={documentImgStyles} />
                                <div style={documentInfoStyles}>
                                    x                                <button
                                        style={documentDownloadButtonStyles}
                                        onClick={() => handleDownload(document.otherIdProofFront, 'other_id_proof_front.jpg')}
                                    >
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {document.otherIdProofBack && (
                        <div>
                            <span style={documentLabelStyles}>Other ID Proof Back</span>

                            <div style={documentItemStyles}>
                                <img src={`https://dpcst9y3un003.cloudfront.net/${document.otherIdProofBack}`} alt="Other ID Proof Back" style={documentImgStyles} />
                                <div style={documentInfoStyles}>
                                    <button
                                        style={documentDownloadButtonStyles}
                                        onClick={() => handleDownload(document.otherIdProofBack, 'other_id_proof_back.jpg')}
                                    >
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
