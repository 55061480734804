import { SERVER_API_URL, SERVER_DOMAIN } from "../constants/constants";
import axios from "axios";
import moment from 'moment';

const getAllBadgeRequestInfo = async (page, searchTerm = '') => {
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/BadgeRequest/getAllBadgeRequestInfo?page=${page}&search=${searchTerm}`;


  const result = await axios.get(url, config);
  // console.log(result)
  return result.data;
};

const approvedBadgeRequest=async(id)=>{
    const getToken = localStorage.getItem("token");

    const config = {
      headers: { Authorization: `Bearer ${getToken}` },
    };
    const url = `${SERVER_API_URL}/admin/BadgeRequest/approvedBadgeRequest/${id}`;
  
  
    const result = await axios.patch(url, null,config);
    return result.data;
}


export {
    getAllBadgeRequestInfo,
    approvedBadgeRequest
  
}