import { SERVER_API_URL } from "../constants/constants";
import axios from "axios";
import moment from 'moment';

const addMoneyToSuperAdmin = async (data) => {
  console.log(data, "data_super_admin_transaction")
  try {
    const getToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${getToken}` },
    };
    const url = `${SERVER_API_URL}/admin/super_admin_transaction/super_admintransactions`;

    // Assuming 'occupationData' is an array of occupation objects
    const response = await axios.post(url, data, config);
    // console.log(response,"response")
    return response;
  } catch (error) {
    throw error;
  }
};


const SendMoneyToAdmin = async (data) => {
  console.log(data, "data_admin_transaction")
  try {
    const getToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${getToken}` },
    };
    const url = `${SERVER_API_URL}/admin/super_admin_transaction/sendMoneyToAdmin`;

    // Assuming 'occupationData' is an array of occupation objects
    const response = await axios.post(url, data, config);
    // console.log(response,"response")
    return response;
  } catch (error) {
    throw error;
  }
};
const getWalletDetails = async () => {
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/super_admin_transaction/getWalletDetails`;
  const result = await axios.get(url, config);
  return result;
};

// const getSuper_admin_transaction = async (page) => {
//   const getToken = localStorage.getItem("token");
//   const config = {
//     headers: { Authorization: `Bearer ${getToken}` },
//   };
//   const url = `${SERVER_API_URL}/admin/super_admin_transaction/getSuper_admin_transaction?page=${page}`;
//   const result = await axios.get(url, config);
//   return result; 
// };
const getSuper_admin_transaction = async (filters) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  // Extract parameters from filters object
  const { page, searchTerm = '', startDate = null, endDate = null } = filters;
  const encodedSearchTerm = encodeURIComponent(searchTerm);

  let encodedStartDate = '';
  let encodedEndDate = '';

  if (startDate) {
    encodedStartDate = moment.utc(startDate).add(1, 'day').endOf('day').toISOString(); // Keep startDate as is
  }
  if (endDate) {
    encodedEndDate = moment.utc(endDate).add(1, 'day').endOf('day').toISOString(); // Add 1 day to endDate
  }

  let url = `${SERVER_API_URL}/admin/super_admin_transaction/getSuper_admin_transaction?page=${page}&search=${encodedSearchTerm}`;

  if (encodedStartDate) {
    url += `&startDate=${encodeURIComponent(encodedStartDate)}`;
  }
  if (encodedEndDate) {
    url += `&endDate=${encodeURIComponent(encodedEndDate)}`;
  }

  // Make the API request
  const result = await axios.get(url, config);
  return result;
}
const GetAllEmployeeTransation = async (filters) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  // Extract parameters from filters object
  const { page, searchTerm = '', startDate = null, endDate = null } = filters;
  const encodedSearchTerm = encodeURIComponent(searchTerm);

  let encodedStartDate = '';
  let encodedEndDate = '';

  if (startDate) {
    encodedStartDate = moment.utc(startDate).add(1, 'day').endOf('day').toISOString(); // Keep startDate as is
  }
  if (endDate) {
    encodedEndDate = moment.utc(endDate).add(1, 'day').endOf('day').toISOString(); // Add 1 day to endDate
  }
  let url = `${SERVER_API_URL}/admin/super_admin_transaction/getalladminemployeetransation?page=${page}&search=${encodedSearchTerm}`;

  if (encodedStartDate) {
    url += `&startDate=${encodeURIComponent(encodedStartDate)}`;
  }
  if (encodedEndDate) {
    url += `&endDate=${encodeURIComponent(encodedEndDate)}`;
  }

  const result = await axios.get(url, config);
  return result;
}

const GetEmployeeAdminTransationById = async (filters) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  // Extract parameters from filters object
  const { page, searchTerm = '', startDate = null, endDate = null } = filters;
  const encodedSearchTerm = encodeURIComponent(searchTerm);

  let encodedStartDate = '';
  let encodedEndDate = '';

  if (startDate) {
    encodedStartDate = moment.utc(startDate).add(1, 'day').endOf('day').toISOString(); // Keep startDate as is
  }
  if (endDate) {
    encodedEndDate = moment.utc(endDate).add(1, 'day').endOf('day').toISOString(); // Add 1 day to endDate
  }

  let url = `${SERVER_API_URL}/admin/super_admin_transaction/getadminemployeetransactionbyid?page=${page}&search=${encodedSearchTerm}`;

  if (encodedStartDate) {
    url += `&startDate=${encodeURIComponent(encodedStartDate)}`;
  }
  if (encodedEndDate) {
    url += `&endDate=${encodeURIComponent(encodedEndDate)}`;
  }
  // Make the API request
  const result = await axios.get(url, config);
  return result;
}

const getadmin_transaction = async (filters) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  // Extract parameters from filters object
  const { page, searchTerm = '', startDate = null, endDate = null } = filters;

  // Build the base URL with page and searchTerm
  let url = `${SERVER_API_URL}/admin/super_admin_transaction/getadmin_transaction?page=${page}&search=${searchTerm}`;

  // Append startDate and endDate if they exist
  if (startDate) {
    url += `&startDate=${startDate}`;
  }
  if (endDate) {
    url += `&endDate=${endDate}`;
  }

  // Make the API request
  const result = await axios.get(url, config);
  return result;
}

const SendMoneyToUser = async (data) => {
  console.log(data, "data_sendMoney_transaction")
  try {
    const getToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${getToken}` },
    };
    const url = `${SERVER_API_URL}/admin/super_admin_transaction/sendMoneyToUser`;

    // Assuming 'occupationData' is an array of occupation objects
    const response = await axios.post(url, data, config);
    console.log(response, "response")
    return response;
  } catch (error) {
    throw error;
  }
};

const getTransationDeatilByTransationId = async (id) => {
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/super_admin_transaction/gettransationbytransationid/${id}`;
  const result = await axios.get(url, config);
  return result;
}
const getTransationDeatilAdminEmployeeByTransationId = async (id) => {
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/super_admin_transaction/gettransationforemployeeadminbytransationid/${id}`;
  const result = await axios.get(url, config);
  return result;
}


const RollBackTransation = async (newdiamonvalue, transationdetails) => {
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/super_admin_transaction/rollbacktransation`;
  const result = await axios.put(url, { newdiamonvalue, transationdetails }, config);
  return result;
}
const RollBackTransationForAdminEmployee = async (newdiamonvalue, transationdetails) => {
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/super_admin_transaction/rollbacktransationforadminemployee`;
  const result = await axios.put(url, { newdiamonvalue, transationdetails }, config);
  return result;
}



const RollBackTransationSuperAdmintoUserside = async (newdiamonvalue, transationdetails) => {
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/super_admin_transaction/rollbacktransationsuperadmintouser`;
  const result = await axios.put(url, { newdiamonvalue, transationdetails }, config);
  return result;
}
export {
  addMoneyToSuperAdmin, SendMoneyToAdmin, getWalletDetails, getSuper_admin_transaction, getadmin_transaction, SendMoneyToUser, GetAllEmployeeTransation, GetEmployeeAdminTransationById, getTransationDeatilByTransationId, RollBackTransation, getTransationDeatilAdminEmployeeByTransationId, RollBackTransationForAdminEmployee, RollBackTransationSuperAdmintoUserside
}