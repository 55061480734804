import { SERVER_API_URL, SERVER_DOMAIN } from "../constants/constants";
import axios from "axios";

const getCountryDetails = async () => {
  const getToken = localStorage.getItem("token");
  // console.log(page,perPage,getToken)
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/diamondrate/getcountrydetails`;
  const result = await axios.get(url, config);
  return result.data;
};

const updateDiamondRate=async(data)=>{
  const getToken = localStorage.getItem("token");
  // console.log(page,perPage,getToken)
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/diamondrate/createandupdatediamondrate`;
  const result = await axios.post(url,data, config);
  return result.data;
}

const getDiamondAndCurrencyByCountryId=async(id)=>{
  const getToken = localStorage.getItem("token");
  console.log(id,'getDiamondAndCurrencyByCountryId')
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/diamondrate/getdiamondandcurrencybycountryid/${id}`;
  const result = await axios.get(url, config);
  return result.data;
}

const AllCountryDiamondRate=async()=>{
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/diamondrate/allcountrydiamondrate`;
  const result = await axios.get(url, config);
  return result.data;
}
export { getCountryDetails,updateDiamondRate,getDiamondAndCurrencyByCountryId,AllCountryDiamondRate};
