
import React, { useState, useEffect } from 'react';
import IncludeSideBar from '../../Components/Sidebar/IncludeSideBar';
import { FaGem } from 'react-icons/fa'; // Import diamond icon

function DiamondRate() {
  const allDiamondRateApi = require("../../../src/apis/diamondRate");

  const [searchTerm, setSearchTerm] = useState('Germany');
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);  // To store unique regions
  const [selectedRegion, setSelectedRegion] = useState('Europe');  // Default region is "Europe"

  // New states for diamond value and price
  const [diamondValue, setDiamondValue] = useState('');
  const [price, setPrice] = useState('');

  const [CurrencyData, setCurrencyData] = useState([])
  // Fetch country details from the API
  const fetchCountryDetails = async () => {
    try {
      setLoading(true);
      const response = await allDiamondRateApi.getCountryDetails();
      setCountries(response.data);

      // Extract unique regions
      const uniqueRegions = [...new Set(response.data.map(country => country.region))];
      setRegions(uniqueRegions);

      // Automatically filter and set Germany as the default country
      const germany = response.data.find(country => country.name === 'Germany');
      if (germany) {
        setSelectedCountry(germany);
        setFilteredCountries(response.data.filter(country => country.region === 'Europe'));
      }
    } catch (error) {
      console.error("Error fetching country details:", error);
    } finally {
      setLoading(false);  // Hide loader after data retrieval
    }
  };

  useEffect(() => {
    fetchCountryDetails();
  }, []);

  // Filter countries based on the search input and selected region
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const filtered = countries.filter(country =>
      country.region === selectedRegion &&
      country.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredCountries(filtered);

  };

  // Handle country selection
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setSearchTerm(country.name);
    setFilteredCountries([]);  // Close dropdown after selection
  };

  // Handle region selection
  const handleRegionSelect = (event) => {
    setSelectedRegion(event.target.value);
    setFilteredCountries(countries.filter(country => country.region === event.target.value));
    setSearchTerm('');  // Clear search term
  };
  const fetchDiamondAndCurrencyDetailsByCountryId = async () => {
    try {
      setLoading(true);
      const countryId = selectedCountry.id

      const response = await allDiamondRateApi.getDiamondAndCurrencyByCountryId(countryId);
      setCurrencyData(response.data);




    } catch (error) {
      console.error("Error fetching  details:", error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (selectedCountry) {
      fetchDiamondAndCurrencyDetailsByCountryId(); // Fetch data only if a country is selected
    }
  }, [selectedCountry]);
  // Handle form submission to update diamond value and price
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        countryId: selectedCountry.id,
        diamondValue: diamondValue,
        price: price,
      };

      // Call backend API to update the diamond value and price
      const response = await allDiamondRateApi.updateDiamondRate(data);

      if (response.status === 200) {

        alert(response.message);
        await fetchDiamondAndCurrencyDetailsByCountryId(selectedCountry.id); // Await this call to ensure execution

        setDiamondValue('')
        setPrice('')
      } else {
        alert(response.message);
        setDiamondValue('')
        setPrice('')
      }
    } catch (error) {
      console.error('Error updating diamond rate:', error);
      alert('Error updating the rate. Please check the console for details.');
    }
  };

  
  return (
    <IncludeSideBar>
      <h3 style={{ textAlign: 'center', marginTop: '20px' }}>Diamond Rate Update</h3>
      <div style={{ width: '100%', padding: '20px', boxSizing: 'border-box' }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          padding: '20px',
          borderRadius: '5px',
          backgroundColor: '#fff',
          width: '100%',
        }}>
          <div style={{ display: 'flex', gap: '10px', width: '100%', maxWidth: '1200px' }}>
            {/* Region Selection Dropdown */}
            <div style={{ flex: 1 }}>
              <select
                id="region-select"
                value={selectedRegion}
                onChange={handleRegionSelect}
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ddd',
                  outline: 'none',
                }}
              >
                <option value="">--Select a Region--</option>
                {regions.map((region, index) => (
                  <option key={index} value={region}>
                    {region}
                  </option>
                ))}
              </select>
            </div>

            {/* Search Bar for Countries */}
            <div style={{ flex: 1, position: 'relative' }}>
              <input
                type="text"
                placeholder="Search for a country..."
                value={searchTerm}
                onChange={handleSearch}

                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ddd',
                  outline: 'none',
                }}
              />
              {/* Dropdown for showing filtered country list */}
              {filteredCountries.length > 0 && searchTerm !== 'Germany' && (
                <ul style={{
                  position: 'absolute',
                  width: '100%',
                  backgroundColor: '#fff',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  listStyle: 'none',
                  padding: '0',
                  margin: '0',
                  zIndex: 1000,
                  maxHeight: '200px',  // Approximately 10 countries (adjust as needed)
                  overflowY: 'auto',  // Enable scrolling
                  border: '1px solid #ddd',
                  borderRadius: '5px'
                }}>
                  {filteredCountries.map(country => (
                    <li
                      key={country.id}
                      onClick={() => handleCountrySelect(country)}
                      style={{
                        padding: '10px',
                        cursor: 'pointer',
                        borderBottom: '1px solid #ddd',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={country.flagurl}
                        alt={country.name}
                        style={{ width: '20px', marginRight: '10px' }}
                      />
                      {country.name} (+{country.phonecode})
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>

        {/* Full-width table for displaying selected country details */}
        {selectedCountry && (
          <>
            <table style={{
              width: '100%',
              marginTop: '20px',
              borderCollapse: 'collapse',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '5px',
              overflow: 'hidden',
            }}>
              <thead>
                <tr style={{ backgroundColor: '#f5f5f5' }}>
                  <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Country Code</th>
                  <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Flag Image</th>
                  <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Capital</th>
                  <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Currency</th>
                  <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Region</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>+{selectedCountry.phonecode}</td>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                    <img
                      src={selectedCountry.flagurl}
                      alt={selectedCountry.name}
                      style={{ width: '50px', height: '30px' }}
                    />
                  </td>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{selectedCountry.capital}</td>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{selectedCountry.currency}</td>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{selectedCountry.region}</td>
                </tr>
              </tbody>
            </table>

            {/* Diamond Value and Price Form */}
            <form onSubmit={handleSubmit} style={{ marginTop: '50px', width: '100%' }}>
              <div style={{
                justifyContent: 'space-around',
                alignItems: 'center',
                // background: 'red',
                textAlign: 'center',
                padding: '20px',
                borderRadius: '5px',
                marginBottom: '20px',
              }}>
                {
                  CurrencyData ? (
                    <>
                      <span style={{

                        // transform: 'translateY(-50%)',
                        color: '#888',
                        paddingRight: '20px'
                      }}><b>Diamond Value =</b> <FaGem style={{
                        marginTop: '20px',
                        transform: 'translateY(-50%)',
                        color: '#edd30e',
                        fontSize: '20px'  // Increase the size of the icon
                      }} />  {CurrencyData?.diamond_value}</span>
                      <span style={{

                        // transform: 'translateY(-50%)',
                        color: '#888',
                      }}><b>Price =</b> {selectedCountry?.currency}  {CurrencyData?.currency_value}</span>

                    </>
                  ) : ('')
                }



              </div>
              <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>


                <div style={{ flex: 1, position: 'relative' }}>
                  <FaGem style={{
                    position: 'absolute',
                    top: '50%',
                    left: '10px',
                    transform: 'translateY(-50%)',
                    color: '#edd30e',
                    fontSize: '24px'  // Increase the size of the icon
                  }} />
                  <input
                    type="number"
                    placeholder="Diamond Value"
                    value={diamondValue}
                    onChange={(e) => setDiamondValue(e.target.value)}
                    style={{
                      width: '100%',
                      padding: '10px 10px 10px 40px', // Extra padding for icon
                      borderRadius: '5px',
                      border: '1px solid #ddd',
                      outline: 'none',
                    }}
                  />
                </div>

                <div style={{ flex: 1, position: 'relative' }}>
                  <span style={{
                    position: 'absolute',
                    top: '50%',
                    left: '10px',
                    transform: 'translateY(-50%)',
                    color: '#888',
                  }}>{selectedCountry?.currency}</span>
                  <input
                    type="number"
                    placeholder="PRICE"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    style={{
                      width: '100%',
                      padding: '10px 10px 10px 50px', // Extra padding for currency symbol
                      borderRadius: '5px',
                      border: '1px solid #ddd',
                      outline: 'none',
                    }}
                  />
                </div>
              </div>
              <div style={{ justifyContent: 'center', textAlign: 'center' }}>
                <button
                  type="submit"
                  style={{
                    padding: '10px 20px',
                    backgroundColor: '#28a745',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',

                  }}
                >
                  Update Diamond Rate
                </button>
              </div>

            </form>


          </>
        )}
      </div>
    </IncludeSideBar>
  );
}

export default DiamondRate;
