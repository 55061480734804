import { SERVER_API_URL } from '../constants/constants';
import axios from 'axios';

const getwithdrawmoneyrequest = async (pageNo, pageSize, status, search = '', startDate = '', endDate = '') => {
    const getToken = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${getToken}` },
    };

    // Construct the base URL
    let url = `${SERVER_API_URL}/admin/payout_request/getAllPayoutRequest/?pageNo=${pageNo}&pageSize=${pageSize}`;

    // Add optional query parameters if provided
    if (status) {
        url += `&status=${encodeURIComponent(status)}`;
    }
    if (search) {
        url += `&search=${encodeURIComponent(search)}`;
    }
    if (startDate) {
        url += `&startDate=${encodeURIComponent(startDate)}`;
    }
    if (endDate) {
        url += `&endDate=${encodeURIComponent(endDate)}`;
    }

    try {
        const result = await axios.get(url, config);
        return result.data;
    } catch (error) {
        console.error('Error fetching payout requests:', error);
        throw error;
    }
};

/**
 * @typedef {Object} UpdatePayoutRequestData
 * @property {number} id
 * @property {string} status
 */

/**
 * Update payout request.
 * @param {UpdatePayoutRequestData} data
 * @returns {Promise<Object>}
 */
const updatePayoutRequest = async (data) => {
    const getToken = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${getToken}` },
    };

    const url = `${SERVER_API_URL}/admin/payout_request/updatePayoutRequest`;

    try {
        const result = await axios.patch(url, data, config);
        return result.data;
    } catch (error) {
        console.error('Error fetching payout requests:', error);
        throw error;
    }
};



export {
    getwithdrawmoneyrequest,
    updatePayoutRequest
};
