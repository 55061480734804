import React, { useEffect, useState } from 'react';
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

function ReportedUsers() {
  const generateReport = require('../../../apis/generate_report');
  const [reportData, setReportData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isFocused, setIsFocused] = useState(false)
  const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);
  const [pageWindow, setPageWindow] = useState({ start: 1, end: 20 });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([]);


  const generateReportvideo = async (page, searchTerm = '', startDate = null, endDate = null) => {
    try {
      setIsLoading(true)
      const filters = { page, searchTerm };

      if (startDate) {
        filters.startDate = startDate.toISOString();
      }

      if (endDate) {
        filters.endDate = endDate.toISOString();
      }
      const response = await generateReport.generateReport(filters);
      console.log(response, "response");
      setReportData(response?.data);
      setFilteredUsers(response?.data); // Set filteredUsers to transactions initially

      setTotalPages(response?.pagination?.totalPages);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching videoReport:", error);
      // Handle the error as needed
      setIsLoading(false);

    }
  };

  // useEffect(() => {
  //   generateReportvideo(currentPage, searchTerm);
  // }, [currentPage, searchTerm]);
  useEffect(() => {
    generateReportvideo(currentPage, searchTerm, startDate, endDate);
  }, [currentPage, searchTerm, startDate, endDate]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    const filtered = reportData.filter(
      (user) =>
        (user?.reportedUser?.username?.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    setFilteredUsers(filtered);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
console.log(filteredUsers,'filteredUsersfilteredUsers')
  // const handleNext = () => {
  //   if (currentPage < totalPages) {
  //     setCurrentPage(currentPage + 1);
  //     setPageWindow({
  //       start: Math.floor((currentPage - 1) / 20) * 20 + 1,
  //       end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
  //     });
  //   }

  // };

  const handleNext = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);

      // Check if the new page is outside the current page window, update the window
      if (newPage > pageWindow.end) {
        setPageWindow({
          start: pageWindow.start + 20,
          end: Math.min(pageWindow.end + 20, totalPages),
        });
      }
    }
  };
  const handlePrevious = () => {
    if (currentPage > 1) {
      // setCurrentPage((prevPage) => prevPage - 1);
      setCurrentPage(currentPage - 1);
      setPageWindow({
        start: Math.floor((currentPage - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
      });

    }
  };



  const handleNextPageWindow = () => {
    if (pageWindow.end < totalPages) {
      setPageWindow({
        start: pageWindow.start + 19,
        end: Math.min(pageWindow.end + 19, totalPages),
      });
    }
  };

  const handlePreviousPageWindow = () => {
    if (pageWindow.start > 1) {
      setPageWindow({
        start: pageWindow.start - 20,
        end: pageWindow.end - 20,
      });
    }
  };

  const handlePageClick = (page) => {
    handlePageChange(page);
    if (page === pageWindow.end && pageWindow.end < totalPages) {
      handleNextPageWindow();
    } else if (page === pageWindow.start && pageWindow.start > 1) {
      handlePreviousPageWindow();
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setPageWindow({
      start: Math.floor((totalPages - 1) / 20) * 20 + 1,
      end: totalPages,
    });
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    setPageWindow({
      start: 1,
      end: Math.min(20, totalPages),
    });
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(inputPageNumber, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setPageWindow({
        start: Math.floor((pageNumber - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((pageNumber - 1) / 20) * 20 + 20, totalPages),
      });
      setInputPageNumber('');
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedImage, setSelectedimage] = useState(null);

  const [thumbnailClicked, setThumbnailClicked] = useState(false);
  const [SelectedImageClick, setSelectedImageClick] = useState(false)
  const showVideo = (video) => {
    setSelectedVideo(video);
    setThumbnailClicked(true);
  };
  const showImage = (image) => {
    setSelectedimage(image);
    setSelectedImageClick(true);
  };
  const closethumbModal = () => {
    setThumbnailClicked(false);
    setSelectedImageClick(false)
    setSelectedVideo(null);
    setSelectedimage(null)
  };

  const handleClean = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchTerm('');

    // Use a callback in the state setter to ensure it fetches with updated values
    setTimeout(() => {
      generateReportvideo(currentPage, '', null, null);
    }, 0);
  }

  const [showFullDescription, setShowFullDescription] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});

  // Function to toggle the description state for a specific row
  const toggleDescription = (id) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle the state for this row
    }));
  };
  return (
    <IncludeSideBar>
      <div>
        <p>
          Here we are displaying the All reports.
        </p>
        <input
          type="text"
          placeholder="Search by username or email"
          value={searchTerm}
          onChange={handleSearch}
          // onFocus={handleFocus}
          // onBlur={handleBlur}
          style={{
            borderWidth: isFocused ? '10px' : '1px',
            borderStyle: 'solid',
            borderColor: isFocused ? 'green' : 'red',
          }}
        />
        <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
          <label style={{ marginRight: '10px' }}>Filter  </label>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              maxDate={new Date()} // Ensure start date is not in the future
              dateFormat="dd:MM:yyyy" // Format date as dd:mm:yyyy

              style={{
                padding: '5px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                fontSize: '14px',
              }}
            />
            <span style={{ margin: '0 10px' }}>to</span>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              placeholderText="End Date"
              minDate={startDate} // Ensure end date is not before start date
              maxDate={new Date()} // Disable selecting future dates
              dateFormat="dd:MM:yyyy" // Format date as dd:mm:yyyy

              style={{
                padding: '5px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                fontSize: '14px',
              }}
            />
            <button
              style={{
                padding: '6px 12px',
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
              onClick={handleClean}
            >
              Clean
            </button>
          </div>
        </div>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Video ID</th>
                <th>Video Thumbnail</th>


                <th>Description</th>
                <th>Reason</th>
                <th>Username</th>
                <th>Evidence Image</th>
                <th>Evidence VIDEO</th>

                <th>Created At</th>
                {/* <th>Updated At</th> */}
              </tr>
            </thead>
            <tbody>
            {filteredUsers?.length > 0 ? (
  filteredUsers?.map((report) => {
    const isLongDescription = report?.description?.length > 30; // Adjust the length as needed
    const isExpanded = expandedRows[report?.id]; // Check if this row is expanded

    return (
      <tr key={report?.id}>
        <td>{report?.id}</td>
        <td>{report?.videoId}</td>
        <td>
          <img
            src={`https://dpcst9y3un003.cloudfront.net/${report?.reportedVideo?.thum}`}
            alt="Thumbnail"
            onClick={() => showVideo(report?.reportedVideo?.video)}
            style={{ cursor: 'pointer', width: '50px', height: '50px' }}
          />
        </td>
        <td>
        {isLongDescription && !isExpanded
            ? `${report?.description?.substring(0, 100)}...` // Show first 100 characters
            : report?.description}
          {isLongDescription && (
            <button
              onClick={() => toggleDescription(report?.id)}
              style={{
                border: 'none',
                background: 'transparent',
                color: 'blue',
                cursor: 'pointer',
                marginLeft: '5px',
              }}
            >
              {isExpanded ? 'Show Less' : 'Show More'}
            </button>
          )}
        </td>
        <td>{report?.reason}</td>

        <td>{report?.reportedUser?.username}</td>
        <td>
          <img
            src={`https://dpcst9y3un003.cloudfront.net/${report?.images}`}
            alt="Thumbnail"
            onClick={() => showImage(report?.images)}
            style={{ cursor: 'pointer', width: '50px', height: '50px' }}
          />
        </td>
        <td>
          <button onClick={() => showVideo(report?.videos)}>Show Video</button>
        </td>
        <td>{new Date(report?.createdAt).toLocaleDateString()}</td>
      </tr>
    );
  })
) : (
  <tr>
    <td colSpan="8">No records found</td>
  </tr>
)}

            </tbody>
          </table>
        
        <div className="pagination">
          <button onClick={handleFirstPage} disabled={currentPage === 1}>
            First
          </button>
          <button onClick={handlePrevious} disabled={currentPage === 1}>
            Previous
          </button>
          {/* {Array.from(
            { length: pageWindow.end - pageWindow.start + 1 },
            (_, index) => pageWindow.start + index
          ).map((page) => (
            <button
              key={page}
              onClick={() => handlePageClick(page)}
              className={currentPage === page ? 'active' : ''}
              style={{
                backgroundColor: currentPage === page ? 'red' : '',
                color: 'white',
              }}
            >
              {page}
            </button>
          ))} */}
          {Array.from(
            {
              length: Math.min(pageWindow.end - pageWindow.start + 1, totalPages)
            },
            (_, index) => pageWindow.start + index
          ).map((page) => (
            <button
              key={page}
              onClick={() => handlePageClick(page)}
              className={currentPage === page ? 'active' : ''}
              style={{
                backgroundColor: currentPage === page ? 'red' : '',
                color: 'white',
              }}
            >
              {page}
            </button>
          ))}
          <button onClick={handleNext} disabled={currentPage === totalPages}>
            Next
          </button>
          <button onClick={handleLastPage} disabled={currentPage === totalPages}>
            Last
          </button>
        </div>



        {/* direct page */}
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
          <input
            type="number"
            value={inputPageNumber}
            onChange={(e) => setInputPageNumber(e.target.value)}
            placeholder="Page No"
            style={{
              padding: '5px',
              fontSize: '14px',
              // border: '1px solid #ccc',
              border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

              borderRadius: '4px 0 0 4px',
              outline: 'none',
              width: '90px'
            }}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
          />
          <button
            onClick={handleGoToPage}
            style={{
              padding: '6.5px 10px',
              fontSize: '14px',
              border: '1px solid #ccc',
              borderLeft: 'none',
              borderRadius: '0 4px 4px 0',
              // backgroundColor: '#007bff',

              backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
              color: 'white',
              cursor: 'pointer',
              outline: 'none',
            }}


          >
            Go
          </button>
        </div>
        <Thumbnail
          showModal={thumbnailClicked}
          closeModal={closethumbModal}
          selectedVideo={selectedVideo}
        // loading={isLoading}

        />
        <OnSelectedImage
          showModal={SelectedImageClick}
          closeModal={closethumbModal}
          selectedImage={selectedImage}
        />
      </div>
    </IncludeSideBar>
  );
}

export default ReportedUsers;

const Thumbnail = ({ showModal, closeModal, selectedVideo }) => {

  console.log(selectedVideo, 'selectedVideo')

  const modalStyle = {
    display: showModal ? 'block' : 'none',
    position: 'fixed',
    zIndex: 1,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '550px',
    height: '500px',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.6)',

    borderRadius: '10px',
    padding: '20px'
  };

  const modalContentStyle = {
    // backgroundColor: '#fefefe',
    // width: '100%',
    // height: '100%',
    // borderRadius: '10px',
    // padding: '20px',
    // boxSizing: 'border-box',
    textAlign: 'center'
  };

  const closeButtonStyle = {
    color: '#fefefe',
    float: 'right',
    fontSize: '28px',
    fontWeight: 'bold',
    cursor: 'pointer'
  };


  return (
    <div className="modal" style={modalStyle}>
      {/* <div className="modal-content" style={modalContentStyle}> */}
      <span className="close" style={closeButtonStyle} onClick={closeModal}>&times;</span>
      {selectedVideo && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>

          <video controls width="220" >
            <source
              src={`https://dpcst9y3un003.cloudfront.net/${selectedVideo}`}
              type="video/mp4"
            />
          </video>
        </div>
      )}
      {/* </div> */}
    </div>
  );


}

const OnSelectedImage = ({ showModal, closeModal, selectedImage }) => {

  // console.log(selectedVideo, 'selectedVideo')

  const modalStyle = {
    display: showModal ? 'block' : 'none',
    position: 'fixed',
    zIndex: 1,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '550px',
    height: '500px',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.6)',

    borderRadius: '10px',
    padding: '20px'
  };

  const modalContentStyle = {
    // backgroundColor: '#fefefe',
    // width: '100%',
    // height: '100%',
    // borderRadius: '10px',
    // padding: '20px',
    // boxSizing: 'border-box',
    textAlign: 'center'
  };

  const closeButtonStyle = {
    color: '#fefefe',
    float: 'right',
    fontSize: '28px',
    fontWeight: 'bold',
    cursor: 'pointer'
  };


  return (
    <div className="modal" style={modalStyle}>
      {/* <div className="modal-content" style={modalContentStyle}> */}
      <span className="close" style={closeButtonStyle} onClick={closeModal}>&times;</span>
      {selectedImage && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>


          <img
            src={`https://dpcst9y3un003.cloudfront.net/${selectedImage}`}
            alt="Thumbnail"
            style={{ cursor: 'pointer', width: '400px', height: '400px' }}
          />
        </div>
      )}
      {/* </div> */}
    </div>
  );


}