import React, { useEffect, useState } from 'react';
import IncludeSideBar from '../../../src/Components/Sidebar/IncludeSideBar';
import { FaGem } from 'react-icons/fa'; // Import diamond icon

function AllDiamondRateInfo() {
  const allDiamondRateApi = require("../../../src/apis/diamondRate");

  const [diamondRates, setDiamondRates] = useState([]); // State to store diamond rates
  const [filteredRates, setFilteredRates] = useState([]); // State to store filtered data
  const [regions, setRegions] = useState([]); // State to store region options
  const [selectedRegion, setSelectedRegion] = useState('All'); // State to store selected region
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const itemsPerPage = 10; // Number of rows per page

  useEffect(() => {
    // Fetch the diamond rates from the backend API
    fetchDiamondRates();
  }, []);

  // Fetching diamond rate data (mockup function)
  const fetchDiamondRates = async () => {
    try {
      const data = await allDiamondRateApi.AllCountryDiamondRate();

      // Set diamond rate data
      setDiamondRates(data);
      setFilteredRates(data);

      // Extract unique regions from the data
      const uniqueRegions = [...new Set(data.map(item => item.region))];
      setRegions(['All', ...uniqueRegions]); // Include 'All' for no filter
    } catch (error) {
      console.error("Error fetching diamond rates:", error);
    }
  };

  // Handle region filter
  const handleRegionChange = (e) => {
    const region = e.target.value;
    setSelectedRegion(region);

    // Filter the diamond rates by selected region
    const filtered = region === 'All'
      ? diamondRates
      : diamondRates.filter(item => item.region === region);

    setFilteredRates(filtered);
    setCurrentPage(1); // Reset to first page on filter change
  };

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredRates.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <IncludeSideBar>
      <div style={{ padding: '20px' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#4a90e2' }}>
          All Country Diamond Rate Info
        </h2>

        {/* Region Filter */}
        <div style={{ marginBottom: '20px', textAlign: 'center' }}>
          <label htmlFor="region" style={{ marginRight: '10px', fontWeight: 'bold' }}>Filter by Region:</label>
          <select
            id="region"
            value={selectedRegion}
            onChange={handleRegionChange}
            style={{
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ddd',
              cursor: 'pointer',
            }}
          >
            {regions.map((region, index) => (
              <option key={index} value={region}>
                {region}
              </option>
            ))}
          </select>
        </div>

        {/* Table */}
        <div style={{ overflowX: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
          {currentData.length > 0 ? (
            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
              <thead>
                <tr style={{ backgroundColor: '#f8f9fa', textAlign: 'left' }}>
                  <th style={{ padding: '15px', borderBottom: '2px solid #ddd' }}>Flag</th>
                  <th style={{ padding: '15px', borderBottom: '2px solid #ddd' }}>Country</th>
                  <th style={{ padding: '15px', borderBottom: '2px solid #ddd' }}>Phone Code</th>
                  <th style={{ padding: '15px', borderBottom: '2px solid #ddd' }}>Currency</th>
                  <th style={{ padding: '15px', borderBottom: '2px solid #ddd' }}>Region</th>
                  <th style={{ padding: '15px', borderBottom: '2px solid #ddd' }}>Diamond Value</th>
                  <th style={{ padding: '15px', borderBottom: '2px solid #ddd' }}>Currency Value</th>
                </tr>
              </thead>
              <tbody>
                {currentData.map((item, index) => (
                  <tr key={index} style={{ borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                    <td style={{ padding: '15px' }}>
                      <img
                        src={item.flagurl}
                        alt={`${item.country_name} flag`}
                        style={{ width: '40px', height: '25px', borderRadius: '4px' }}
                      />
                    </td>
                    <td style={{ padding: '15px', fontWeight: '500' }}>{item.country_name}</td>
                    <td style={{ padding: '15px', fontWeight: '500' }}>{item.phonecode}</td>
                    <td style={{ padding: '15px' }}>{item.currency}</td>
                    <td style={{ padding: '15px' }}>{item.region}</td>
                    <td style={{ padding: '15px', color: '#edd30e' }}>
                      <FaGem style={{ color: '#edd30e', fontSize: '16px', marginRight: '5px' }} />
                      {item.diamond_value}
                    </td>
                    <td style={{ padding: '15px' }}>
                      {item.currency} {item.currency_value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>No data available for the selected region.</p>
          )}
        </div>

        {/* Pagination */}
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          {Array.from({ length: Math.ceil(filteredRates.length / itemsPerPage) }, (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              style={{
                padding: '10px',
                margin: '0 5px',
                borderRadius: '5px',
                border: '1px solid #ddd',
                backgroundColor: currentPage === index + 1 ? 'red' : '#fff',
                color: currentPage === index + 1 ? '#fff' : 'red',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
              }}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </IncludeSideBar>
  );
}

export default AllDiamondRateInfo;
