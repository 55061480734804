import React, { useState } from 'react'
import { Status } from '../enum/Status'
import '../index.css';
import * as payoutApi from '../../../apis/withdraw_money_request'
import { formattedDateAndTime } from '../../../utils/customDate';

function RenderTableData({ withdrawdata, index }) {
    const [status, setStatus] = useState(withdrawdata?.status)


    // handleApproval
    const handleApproval = async (id) => {
        try {
            const update_result = await payoutApi.updatePayoutRequest({
                id: id,
                status: Status.APPROVED
            });
            setStatus(Status.APPROVED);
            console.log(`Approving request with ID: ${id}`, update_result);
        } catch (error) {
            console.error("Error during approval", error);
        }
    };

    // handleRollback
    const handleRollback = async (id) => {
        try {
            const update_result = await payoutApi.updatePayoutRequest({
                id: id,
                status: Status.PENDING // Assuming rollback will move status back to 'PENDING'
            });
            setStatus(Status.PENDING);
            console.log(`Rolling back request with ID: ${id}`, update_result);
        } catch (error) {
            console.error("Error during rollback", error);
        }
    };

    // handleReject
    const handleReject = async (id) => {
        try {
            const update_result = await payoutApi.updatePayoutRequest({
                id: id,
                status: Status.REJECTED
            });
            setStatus(Status.REJECTED);
            console.log(`Rejecting request with ID: ${id}`, update_result);
        } catch (error) {
            console.error("Error during rejection", error);
        }
    };

    // handleProcessing
    const handleProcessing = async (id) => {
        try {
            const update_result = await payoutApi.updatePayoutRequest({
                id: id,
                status: Status.PROCESSING
            });
            setStatus(Status.PROCESSING);
            console.log(`Processing request with ID: ${id}`, update_result);
        } catch (error) {
            console.error("Error during processing", error);
        }
    };



    return (
        <tr key={index}>
            <td>{withdrawdata.id}</td>
            <td>{withdrawdata.user_id}</td>
            <td>{withdrawdata?.user?.username || "N/A"}</td>
            <td>{withdrawdata?.user?.nickname || "N/A"}</td>
            <td>{withdrawdata.paypal_account_id || "N/A"}</td>
            <td>{withdrawdata?.paypal_account?.email || "N/A"}</td>
            <td>
                {withdrawdata?.paypal_account?.firstName + " " + withdrawdata?.paypal_account?.lastName || "N/A"}
            </td>
            <td>$ {withdrawdata.amount_in_dollar || "N/A"}</td>
            <td>{withdrawdata.diamonds_value || "N/A"}</td>
            <td>{formattedDateAndTime(withdrawdata.createdAt) || "N/A"}</td>
            <td>{status || "N/A"}</td>
            <td>
                {/* If status is 'APPROVED' */}
                {status === Status.APPROVED && (
                    <>
                        <button
                            disabled={true}
                            className="approve-btn"
                            onClick={() => handleApproval(withdrawdata.id)}>
                            Approve
                        </button>
                        <button
                            disabled={true}
                            className="rollback-btn"
                            onClick={() => handleRollback(withdrawdata.id)}>
                            Roll Back
                        </button>
                    </>
                )}

                {/* If status is 'PENDING' */}
                {status === Status.PENDING && (
                    <>
                        <button
                            disabled={false}
                            className="reject-btn"
                            onClick={() => handleReject(withdrawdata.id)}>
                            Reject
                        </button>
                        <button
                            disabled={false}
                            className="processing-btn"
                            onClick={() => handleProcessing(withdrawdata.id)}>
                            Processing
                        </button>
                    </>
                )}

                {/* If status is 'PROCESSING' */}
                {status === Status.PROCESSING && (
                    <>
                        <button
                            disabled={false}
                            className="approve-btn"
                            onClick={() => handleApproval(withdrawdata.id)}>
                            Approve
                        </button>
                        <button
                            disabled={false}
                            className="reject-btn"
                            onClick={() => handleReject(withdrawdata.id)}>
                            Reject
                        </button>
                    </>
                )}

                {/* If status is 'REJECTED' */}
                {status === Status.REJECTED && (
                    <>
                        <button
                            disabled={true}
                            className="approve-btn"
                            onClick={() => handleApproval(withdrawdata.id)}>
                            Approve
                        </button>
                        <button
                            disabled={true}
                            className="processing-btn"
                            onClick={() => handleProcessing(withdrawdata.id)} >
                            Processing
                        </button>
                    </>
                )}
            </td>

        </tr>
    )
}

export default React.memo(RenderTableData)
