import React, { useState, useEffect,useMemo } from 'react';
import './Employee.css';
import IncludeSideBar from '../../Components/Sidebar/IncludeSideBar'
import { useNavigate } from 'react-router-dom';


const Employee = () => {
  const [sections, setSections] = useState([]); // State to hold sections and features
  const [showSections, setShowSections] = useState(false); // To toggle the dropdown
  const [checkedSections, setCheckedSections] = useState({}); // To track selected sections
  const [selectedFeatures, setSelectedFeatures] = useState({}); // To track selected features
  const [selectedSubfeatures, setSelectedSubfeatures] = useState({}); // To track selected subfeatures
  const [expandedFeatures, setExpandedFeatures] = useState({}); // To track expanded features
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [employeeToEdit, setEmployeeToEdit] = useState(null);

  const navigate = useNavigate();


  const addmoneysuperadminApis = require('../../apis/super_admin_transaction');
  const EmployeeApis = require('../../apis/employee')


  const [showForm, setShowForm] = useState(false); // State to toggle form visibility
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [gender, setGender] = useState('');
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const [employees, setEmployees] = useState([]);
  const [selectedRole, setSelectedRole] = useState('All');
  const [showAmountInputMap, setShowAmountInputMap] = useState({});
  const [cancelInputMap, setCancelInputMap] = useState({});
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [getotp, setgetotp] = useState('')

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validate form fields before submission (e.g., password match, required fields, etc.)
    if (!firstName || !lastName || !email || !password || !confirmPassword || password !== confirmPassword) {
      console.error('Invalid form data');
      // Handle form validation errors (display messages or prevent submission)
      return;
    }
    const permissions = {};


    Object.keys(checkedSections).forEach(section => {
      if (checkedSections[section]) { // If section is checked
        permissions[section] = {};

        const sectionObj = sections.find(s => s.name === section);
        if (sectionObj) {
          sectionObj.features.forEach(feature => {
            // Main feature selection
            permissions[section][feature.name] = selectedFeatures[section]?.[feature.name] || false;

            // Handle subfeatures if they exist
            if (feature.subfeatures) {
              permissions[section][feature.name] = {
                selected: permissions[section][feature.name], // Keep track of the main feature
                subfeatures: {}, // Initialize subfeatures object
              };

              // Iterate over subfeatures
              feature.subfeatures.forEach(subfeature => {
                // Store subfeature selection
                permissions[section][feature.name].subfeatures[subfeature.name] =
                  selectedSubfeatures[section]?.[feature.name]?.[subfeature.name] || false;
              });
            }
          });
        }
      }
    });

    const newEmployee = {
      firstName,
      lastName,
      email,
      password,
      phoneNumber,
      gender,
      role,
      active: 1,
      permissions
    };
    console.log(permissions, 'newEmployeenewEmployee')
    try {
      const addedEmployee = await EmployeeApis.addEmployee(newEmployee);

      if (addedEmployee.success) {
        alert(addedEmployee.message)
        setFirstName('');
        setLastName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setPhoneNumber('');
        setGender('');
        setRole('');
        setCheckedSections('');
        setSelectedFeatures('');
        setSelectedSubfeatures('');
        setExpandedFeatures('')
        getEmployee();
        setShowForm(false);
      } else {
        alert(addedEmployee.message)
      }
      // Clear form fields upon successful addition

    } catch (error) {
      console.error('Error adding employee:', error.message);
      // Handle error - show an error message, for example
    } finally {
      setLoading(false); // Hide loader after form submission (whether successful or not)
    }
  };
  const filteredEmployees = selectedRole === 'All' ? employees : employees.filter(employee => employee.role === selectedRole);
  // function for getting the addedemployee


  const getEmployee = async () => {
    try {
      const response = await EmployeeApis.getAllEmployee();
      console.log(response, "response")
      setEmployees(response.employees);
    } catch (error) {
      console.error("Error fetching eemployees:", error);
    }
  };
  useEffect(() => {
    getEmployee();
  }, []);


  //function to activate and deactivate the code 

  const activateEmployee = async (id) => {
    try {
      // Display a confirmation alert before activating the employee
      const confirmActivate = window.confirm("Are you sure you want to activate this employee?");

      if (confirmActivate) {
        await EmployeeApis.activateEmployee(id);
        // Refresh the employee list after activation
        getEmployee();

      } else {
        // If the user cancels the activation, you can perform any other action here or simply return
        console.log('Activation canceled by the user.');
        return;
      }
    } catch (error) {
      console.error('Error activating employee:', error);
    }
  };

  const deactivateEmployee = async (id) => {
    try {
      // Display a confirmation alert before deactivating the employee
      const confirmDeactivate = window.confirm("Are you sure you want to deactivate this employee?");

      if (confirmDeactivate) {
        await EmployeeApis.deactivateEmployee(id);
        // Refresh the employee list after deactivation
        getEmployee();
      } else {
        // If the user cancels the deactivation, you can perform any other action here or simply return
        console.log('Deactivation canceled by the user.');
        return;
      }
    } catch (error) {
      console.error('Error deactivating employee:', error);
    }
  };



  //function to delete the employes

  const deleteEmployee = async (id) => {
    try {
      // Display a confirmation alert before proceeding with deletion
      const confirmDelete = window.confirm("Are you sure you want to delete this employee?");

      if (confirmDelete) {
        await EmployeeApis.deleteEmployee(id);
        // Refresh the employee list after deletion
        getEmployee();
      } else {
        // If the user cancels the deletion, you can perform any other action here or simply return
        console.log('Deletion canceled by the user.');
        return;
      }
    } catch (error) {
      console.error('Error deleting employee:', error);
    }
  };



  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  const toggleAmountInput = (id) => {
    setShowAmountInputMap((prevMap) => ({
      ...prevMap,
      [id]: !prevMap[id],
    }));
    setCancelInputMap((prevMap) => ({
      ...prevMap,
      [id]: false,
    }));
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSendMoney = async (id) => {

    try {
      setLoading(true);
      toggleAmountInput(); // Toggle the amount input visibility

      // Implement logic to send money through the API using the entered amount and employee id
      // Use the 'amount' and 'id' state variables for sending money

      // Assuming sendMoneyApis.sendMoneyToEmployee is a function that sends the API request
      const response = await addmoneysuperadminApis.SendMoneyToAdmin({ id, amount });

      console.log('API response:', response);

      // Handle the response as needed, update UI, etc.
      // For example, you might want to show a success message to the user

    } catch (error) {
      console.error('Error sending money:', error);
      // Handle errors, show error message, etc.
    } finally {
      toggleAmountInput(id);
      setAmount('');

      setLoading(false);
    }
  };

  const handleCancelAmountInput = (id) => {
    setAmount('');
    setShowAmountInputMap((prevMap) => ({
      ...prevMap,
      [id]: false,
    }));
    setCancelInputMap((prevMap) => ({
      ...prevMap,
      [id]: true,
    }));
  };


  //testing

  const handleInputClick = () => {
    setShowSections((prev) => !prev);
  };

  // Handle section checkbox click
  const handleSectionCheck = (sectionName) => {
    setCheckedSections((prevCheckedSections) => ({
      ...prevCheckedSections,
      [sectionName]: !prevCheckedSections[sectionName],
    }));
  };

  // Handle feature checkbox click
  // const handleFeatureCheck = (sectionName, feature) => {
  //   setSelectedFeatures((prevSelectedFeatures) => ({
  //     ...prevSelectedFeatures,
  //     [sectionName]: {
  //       ...prevSelectedFeatures[sectionName],
  //       [feature]: !prevSelectedFeatures[sectionName]?.[feature],
  //     },
  //   }));
  // };
  const handleFeatureCheck = (sectionName, feature) => {
    setSelectedFeatures((prevSelectedFeatures) => ({
      ...prevSelectedFeatures,
      [sectionName]: {
        ...prevSelectedFeatures[sectionName],
        [feature]: !prevSelectedFeatures[sectionName]?.[feature],
      },
    }));

    // Toggle the expanded state of the feature
    setExpandedFeatures((prev) => ({
      ...prev,
      [feature]: !prev[feature],
    }));
  };

  const handleSubfeatureCheck = (sectionName, feature, subfeature) => {
    setSelectedSubfeatures((prevSelectedSubfeatures) => ({
      ...prevSelectedSubfeatures,
      [sectionName]: {
        ...prevSelectedSubfeatures[sectionName],
        [feature]: {
          ...prevSelectedSubfeatures[sectionName]?.[feature],
          [subfeature]: !prevSelectedSubfeatures[sectionName]?.[feature]?.[subfeature],
        },
      },
    }));
  };
  //new testing
  //add permission

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const handleOpenFormModal = () => {
    setIsFormModalOpen(true);
  };

  const handleCloseFormModal = () => {
    setIsFormModalOpen(false);
  };

  const handlePermissionSubmitForm = async (formData) => {
    console.log('Form Submitted:', formData);

    // Set loading to true when the submission starts
    setLoading(true);

    try {
      // Make API call to submit form data
      const response = await EmployeeApis.AddAdminPermissionAndFeature(formData);

      // Check for success status in the response
      if (response.success) {
        // Display a success message
        alert('Permission added successfully!');

        setIsFormModalOpen(false);
      } else {
        // Display a message if the section already exists
        alert(response.message);
      }

    } catch (error) {
      // Log the error and display an error message to the user
      console.error('Error adding permission:', error.message);
      alert('Error adding permission. Please try again.');

    } finally {
      // Hide the loader once the process is complete
      setLoading(false);
    }
  };



  const getAllAdminPermissionList = async () => {
    try {
      const response = await EmployeeApis.GetAllAdminPermissionsData();
      console.log(response.permissions, "response all permission data");

      // Map the response data to the required format, including subfeatures
      const formattedSections = response.permissions.map(permission => ({
        name: permission.section,
        features: permission.feature.map(feat => ({
          name: feat.value,         // Access the 'value' property of the feature
          enabled: feat.enabled,    // Access the 'enabled' property of the feature
          subfeatures: feat.subfeatures ? feat.subfeatures.map(sub => ({
            name: sub.value,        // Access the 'value' property of the subfeature
            enabled: sub.enabled,   // Access the 'enabled' property of the subfeature
          })) : [] // In case 'subfeature' is undefined or empty
        })),
      }));

      console.log(formattedSections, "Formatted sections with subfeatures");
      setSections(formattedSections);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  useEffect(() => {
    getAllAdminPermissionList();
  }, []); // Fetch permissions when the component mounts


  const sendOtp = async () => {
    try {
      const response = await EmployeeApis.Sendotp(email);
      console.log(response, 'responseresponse')
      alert(response.message);
      setOtpSent(true);
      setgetotp(response.otp)
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('Failed to send OTP');
    }
  };

  const verifyOtp = () => {
    if (otp === getotp) {
      setOtpVerified(true)
      alert('OTP verified successfully!');
      // Proceed with the next steps after successful verification
    } else {
      alert('Invalid OTP. Please try again.');

    }
  };


  const openEditModal = async (id) => {
    try {
      const response = await EmployeeApis.GetEmployeeAdminById(id);
      setEmployeeToEdit(response); // Store the fetched employee data
      setEditModalOpen(true);      // Open the modal
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  // Function to close the edit modal
  const closeEditModal = () => {
    setEmployeeToEdit(null);
    setEditModalOpen(false);
  };


  const handleeditSubmit = async (payload) => {
    try {
      console.log(payload, 'payload');
      
      // Send the payload to the backend API for updating employee details
      const response = await EmployeeApis.UpdateAdminEmployee(payload);
      
      if (response.success) { // Check if the response is successful
        alert("Employee updated successfully");
        closeEditModal(); // Close the modal after saving
      } else {
        alert("Something went wrong while updating the employee.");
      }
    } catch (error) {
      console.error("Error updating employee:", error);
      alert("An error occurred while updating the employee.");
    }
  };
  
  //end testinhg
  return (
    <IncludeSideBar>
      <div>
        <button style={{ marginTop: "2%", marginLeft: "80%" }}
          onClick={toggleFormVisibility} className="add-employee-btn">
          {showForm ? 'Hide Form' : 'Add Employee'}
        </button>

        <button onClick={handleOpenFormModal}>Add Feature & Permisions</button>

        <select style={{ marginLeft: "78%", marginTop: "4%" }}
          value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)}>
          <option value="All">All</option>
          <option value="admin">admin</option>
          <option value="manager">manager</option>
          <option value="assistant manager">assistant manager</option>

          {/* Add other roles as options */}
        </select>

        {showForm && (
          <div>
            <h2>Employee Information</h2>
            <form onSubmit={handleFormSubmit} className="employee-form">
              <label>
                First Name:
                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="input-field" />
              </label>
              <br />
              <label>
                Last Name:
                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} className="input-field" />
              </label>
              <br />
              <label>
                Email:
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="input-field" disabled={otpSent} required />
                <button
                  type="button"
                  onClick={sendOtp}
                  disabled={otpSent} // Disable the button after clicking
                  className="otp-button"
                >
                  Send OTP
                </button>
              </label>
              {otpSent && ( // Show this section only after OTP is sent
                <>
                  <label>
                    OTP:
                    <input
                      type="text"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      className="input-field"
                      required
                      disabled={otpVerified} // Disable the input if OTP is verified
                    />
                    <button
                      type="button"
                      onClick={verifyOtp}
                      className="verify-button"
                      style={{ backgroundColor: otpVerified ? 'green' : '' }} // Change button color to green if verified
                    >
                      {otpVerified ? 'Verified' : 'Verify OTP'}
                    </button>
                  </label>
                  <br />
                </>
              )}

              <br />
              <label>
                Password:
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="input-field" />
              </label>
              <br />
              <label>
                Confirm Password:
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="input-field"
                />
              </label>
              <br />
              <label>
                Phone Number:
                <input type="number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
              </label>
              <br />
              <label>
                Gender:
                <select value={gender} onChange={(e) => setGender(e.target.value)}>
                  <option value="">Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </label>
              <br />
              <label>
                Role:
                <select value={role} onChange={(e) => setRole(e.target.value)}>
                  <option value="">Select Role</option>
                  <option value="admin">Admin</option>
                  <option value="manager">Manager</option>
                  <option value="assistant manager">Assistant Manager</option>
                </select>
              </label>
              <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
                <label style={{ display: "block", marginBottom: "10px" }}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      marginBottom: "5px",
                      display: "block",
                    }}
                  >
                    Select Permissions:
                  </span>
                  <div
                    onClick={handleInputClick}
                    style={{
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      cursor: "pointer",
                      backgroundColor: "#f9f9f9",
                      transition: "background-color 0.3s, border 0.3s",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                      fontSize: "14px",
                      position: "relative",
                    }}
                  >
                    {Object.keys(checkedSections).length > 0
                      ? Object.keys(checkedSections)
                        .filter((key) => checkedSections[key])
                        .join(", ")
                      : "Click to Select Sections"}
                  </div>
                </label>

                {/* Sections with Checkboxes */}
                {showSections && (
                  <div
                    style={{
                      marginTop: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      padding: "10px",
                      backgroundColor: "#fff",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                      maxHeight: "150px",
                      overflowY: "auto",
                    }}
                  >
                    {sections.map((section) => (
                      <div
                        key={section.name}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <span
                          style={{
                            marginLeft: "10px",
                            flex: 1,
                            fontSize: "14px",
                          }}
                        >
                          {section.name}
                        </span>
                        <input
                          type="checkbox"
                          checked={checkedSections[section.name] || false}
                          onChange={() => handleSectionCheck(section.name)}
                          style={{
                            marginRight: "5px",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                )}

                {Object.keys(checkedSections)
                  .filter((section) => checkedSections[section])
                  .map((section) => (
                    <div
                      key={section}
                      style={{
                        marginTop: "20px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        padding: "10px",
                        backgroundColor: "#f9f9f9",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <h4 style={{ margin: "0", fontSize: "16px" }}>
                        {section} Features:
                      </h4>
                      {sections
                        .find((sec) => sec.name === section)
                        .features.map((feature) => (
                          <div key={feature.name}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "5px",
                              }}
                            >
                              <label
                                style={{
                                  flex: 1,
                                  fontSize: "14px",
                                  marginLeft: "10px",
                                }}
                              >
                                {feature.name}
                              </label>
                              <input
                                type="checkbox"
                                checked={selectedFeatures[section]?.[feature.name] || false}
                                onChange={() => handleFeatureCheck(section, feature.name)}
                                style={{
                                  marginRight: "10px",
                                }}
                              />
                            </div>

                            {/* Dropdown for subfeatures */}
                            {expandedFeatures[feature.name] && feature.subfeatures.length > 1 && (
                              <div
                                style={{
                                  paddingLeft: "20px",
                                  marginBottom: "10px",
                                  border: "1px solid #ddd",
                                  borderRadius: "5px",
                                  backgroundColor: "#f0f0f0",
                                }}
                              >
                                {feature.subfeatures.map((subfeature) => (
                                  <div
                                    key={subfeature.name}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <label
                                      style={{
                                        flex: 1,
                                        fontSize: "14px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      {subfeature.name}
                                    </label>
                                    {
                                      subfeature.name && (
                                        <input
                                          type="checkbox"
                                          checked={selectedSubfeatures[section]?.[feature.name]?.[subfeature.name] || false}
                                          onChange={() => handleSubfeatureCheck(section, feature.name, subfeature.name)}
                                          style={{
                                            marginRight: "10px",
                                          }}
                                        />
                                      )
                                    }
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  ))}
              </div>


              {/* <button type="submit">Submit</button> */}
              <button
                disabled={!otpVerified} // Disable the submit button until OTP is verified
                style={{
                  backgroundColor: !otpVerified ? 'gray' : '', // Gray background when disabled
                  color: 'white', // White text for better contrast
                  cursor: !otpVerified ? 'not-allowed' : 'pointer', // Change cursor on hover
                }}
              >
                Submit
              </button>
            </form>
          </div>
        )}
        {loading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : null}


        <div>
          <h2 style={{ marginLeft: "40%", marginBottom: "4%" }}>Employee List</h2>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>role</th>
                <th>action</th>
                <th>View Profile</th>
                <th>Edit Employee</th>


                <th>Delete</th>
                <th> Send Log</th>
              </tr>
            </thead>
            <tbody>
              {filteredEmployees.map((employee) => (
                <tr key={employee.id}>
                  <td>{employee.id}</td>
                  <td>{employee.first_name} {employee.last_name}</td>
                  <td>{employee.email}</td>
                  <td>{employee.role}</td>
                  <td>
                    {employee.active === 1 ? (
                      <button onClick={() => deactivateEmployee(employee.id)}>Deactivate</button>
                    ) : (
                      <button onClick={() => activateEmployee(employee.id)}>Activate</button>
                    )}
                  </td>
                  <td>
                    <button onClick={() => navigate(`/viewprofile/${employee.id}`)}>View profile</button>
                  </td>
                  <td>
                    <button onClick={() => openEditModal(employee.id)}>Edit Employee</button>
                  </td>
                  <td>
                    <button onClick={() => deleteEmployee(employee.id)}>Delete</button>
                  </td>
                  <td>
                    {showAmountInputMap[employee.id] && !cancelInputMap[employee.id] && (
                      <div>
                        <input
                          type="number"
                          placeholder="Enter amount"
                          value={amount}
                          onChange={(e) => handleAmountChange(e)}
                        />
                        <button onClick={() => handleSendMoney(employee.id)}>Confirm</button>
                        <button onClick={() => handleCancelAmountInput(employee.id)}>Cancel</button>
                      </div>
                    )}
                    {!showAmountInputMap[employee.id] && (
                      <button onClick={() => toggleAmountInput(employee.id)}>Send Money</button>
                    )}
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>


        <AddFeatureAndPermission
          isFormModalOpen={isFormModalOpen}
          handleCloseFormModal={handleCloseFormModal}
          handlePermissionSubmitForm={handlePermissionSubmitForm}
        />
         {/* Edit Employee Modal */}
         {editModalOpen && (
                  <EditEmployeeModal employee={employeeToEdit} onClose={closeEditModal} sec={sections} handleeditSubmit={handleeditSubmit}/>

        )}
      </div>
    </IncludeSideBar>
  );
};

export default Employee;


const AddFeatureAndPermission = ({
  isFormModalOpen,
  handleCloseFormModal,
  handlePermissionSubmitForm,
}) => {
  const [formData, setFormData] = useState({
    addSection: '',
    features: [{ value: '', enabled: false, subfeatures: [{ value: '', enabled: false }] }],
  });

  const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '600px',
    textAlign: 'center',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  };

  const inputStyle = {
    padding: '10px',
    width: '50%',
    borderRadius: '4px',
    border: '1px solid #ccc',
    margin: '10px 5px 10px 0',
  };

  const buttonStyle = {
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
    marginTop: '10px',
  };

  const smallButtonStyle = {
    padding: '5px 10px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
    marginLeft: '5px',
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFeatureChange = (index, value) => {
    const updatedFeatures = [...formData.features];
    updatedFeatures[index].value = value;
    setFormData({ ...formData, features: updatedFeatures });
  };

  const handleAddFeature = () => {
    setFormData((prevData) => ({
      ...prevData,
      features: [...prevData.features, { value: '', enabled: false, subfeatures: [{ value: '', enabled: false }] }],
    }));
  };

  const handleRemoveFeature = (index) => {
    const updatedFeatures = formData.features.filter((_, i) => i !== index);
    setFormData({ ...formData, features: updatedFeatures });
  };

  const handleSubfeatureChange = (featureIndex, subfeatureIndex, value) => {
    const updatedFeatures = [...formData.features];
    updatedFeatures[featureIndex].subfeatures[subfeatureIndex].value = value;
    setFormData({ ...formData, features: updatedFeatures });
  };

  const handleAddSubfeature = (featureIndex) => {
    const updatedFeatures = [...formData.features];
    updatedFeatures[featureIndex].subfeatures.push({ value: '', enabled: false });
    setFormData({ ...formData, features: updatedFeatures });
  };

  const handleRemoveSubfeature = (featureIndex, subfeatureIndex) => {
    const updatedSubfeatures = formData.features[featureIndex].subfeatures.filter((_, i) => i !== subfeatureIndex);
    const updatedFeatures = [...formData.features];
    updatedFeatures[featureIndex].subfeatures = updatedSubfeatures;
    setFormData({ ...formData, features: updatedFeatures });
  };

  const handleAddFormSubmit = (e) => {
    e.preventDefault();
    handlePermissionSubmitForm(formData);
    setFormData({
      addSection: '',
      features: [{ value: '', enabled: false, subfeatures: [{ value: '', enabled: false }] }],
    });
  };

  return (
    isFormModalOpen && (
      <div style={modalStyle}>
        <div style={modalContentStyle}>
          <h2>Add Feature And Permission</h2>
          <form onSubmit={handleAddFormSubmit}>
            <input
              type="text"
              name="addSection"
              value={formData.addSection}
              onChange={handleChange}
              placeholder="Add Section"
              style={inputStyle}
              required
            />
            {formData.features.map((feature, featureIndex) => (
              <div key={featureIndex} style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="text"
                    value={feature.value}
                    onChange={(e) => handleFeatureChange(featureIndex, e.target.value)}
                    placeholder={`Feature ${featureIndex + 1}`}
                    style={inputStyle}
                    required
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveFeature(featureIndex)}
                    style={{ ...smallButtonStyle, backgroundColor: '#dc3545' }}
                  >
                    Remove Feature
                  </button>
                  <button
                    type="button"
                    onClick={handleAddFeature}
                    style={smallButtonStyle}
                  >
                    Add Feature
                  </button>
                </div>
                {feature.subfeatures.map((subfeature, subfeatureIndex) => (
                  <div key={subfeatureIndex} style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                    <input
                      type="text"
                      value={subfeature.value}
                      onChange={(e) => handleSubfeatureChange(featureIndex, subfeatureIndex, e.target.value)}
                      placeholder={`Subfeature ${subfeatureIndex + 1}`}
                      style={inputStyle}
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveSubfeature(featureIndex, subfeatureIndex)}
                      style={{ ...smallButtonStyle, backgroundColor: '#dc3545' }}
                    >
                      Remove Subfeature
                    </button>
                    <button
                      type="button"
                      onClick={() => handleAddSubfeature(featureIndex)}
                      style={smallButtonStyle}
                    >
                      Add Subfeature
                    </button>
                  </div>
                ))}
              </div>
            ))}
            <button type="submit" style={buttonStyle}>Submit</button>
          </form>
          <button onClick={handleCloseFormModal} style={{ ...buttonStyle, backgroundColor: '#6c757d' }}>
            Close
          </button>
        </div>
      </div>
    )
  );
};

// *********************************

const EditEmployeeModal = ({ employee, onClose ,sec,handleeditSubmit}) => {
  
  const [sections, setSections] = useState(sec); // State to hold sections and features

  // console.log(checkedSections,'checkedSections')
    const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    phone_number: '',
    gender: '',
  });
  // console.log(checkedSections,'checkedSections')

  
  const transformPermissionsToFeatures = (permissions = {}) => {
    // Ensure permissions is always an object
    if (!permissions || typeof permissions !== 'object') {
      return [];
    }
  
    // Loop through each section in the permissions object
    return Object.keys(permissions).reduce((acc, sectionName) => {
      // Transform the features for each section
      const sectionFeatures = Object.keys(permissions[sectionName]).map(featureName => {
        const feature = permissions[sectionName][featureName];
  
        // Check if feature has subfeatures
        const subfeatures = feature.subfeatures && typeof feature.subfeatures === 'object'
          ? Object.keys(feature.subfeatures).map(subfeatureName => {
              return { name: subfeatureName, enabled: feature.subfeatures[subfeatureName] };
            })
          : []; // Default to an empty array if subfeatures is undefined or not an object
  
        return {
          name: featureName,
          enabled: feature.selected,
          subfeatures: subfeatures
        };
      });
      
      // Add the transformed section to the accumulator
      acc.push({
        name: sectionName,
        features: sectionFeatures
      });
  
      return acc;
    }, []);
  };
  
  // Example usage with employee?.EmployeeDetails?.permissions
  const permissions = employee?.EmployeeDetails?.permissions;
  console.log()
  // Example call
  const transformedFeatures = transformPermissionsToFeatures(permissions);
    
  useEffect(() => {
    if (employee) {
      // Initialize form data
      setFormData({
        first_name: employee?.EmployeeDetails?.first_name || '',
        last_name: employee?.EmployeeDetails?.last_name || '',
        email: employee?.EmployeeDetails?.email || '',
        role: employee?.EmployeeDetails?.role || '',
        phone_number: employee?.EmployeeDetails?.phone_number || '',
        gender: employee?.EmployeeDetails?.gender || '',
      });
  
      // Initialize sections state with permission checks
      const updatedSections = sections.map((section) => {
        // Check if the section has any enabled features
        const matchingPermission = transformedFeatures.find((p) => p.name === section.name);
        section.selected = matchingPermission ? true : false;
  
        if (matchingPermission) {
  
          // Update each feature and its subfeatures based on the matchingPermission
          section.features = section.features.map((feature) => {
            const matchingFeature = matchingPermission.features.find((f) => f.name === feature.name);
      
            if (matchingFeature) {
              // Use matchingFeature.enabled directly to set feature selection status
              feature.enabled = matchingFeature.enabled;
      
              // Update each subfeature based on the enabled status in transformedFeatures
              feature.subfeatures = feature.subfeatures.map((subfeature) => {
                const matchingSubfeature = matchingFeature.subfeatures.find((sf) => sf.name === subfeature.name);
                subfeature.enabled = matchingSubfeature?.enabled || false;
                return subfeature;
              });
            }
            return feature;
          });
        }
      
        return section;
      });
  
      console.log(updatedSections, 'updatedSectionsupdatedSections');
  
      // Update sections in state
      setSections(updatedSections);
    }
  }, [employee]);  // Only depend on `employee` as that's the data driving everything
  const handleSectionToggle = (sectionName) => {
    setSections(prevSections =>
      prevSections.map(section =>
        section.name === sectionName
          ? { ...section, selected: !section.selected }
          : section
      )
    );
  };

  // Handle Feature Toggle
  const handleFeatureToggle = (sectionName, featureName) => {
    setSections(prevSections =>
      prevSections.map(section => 
        section.name === sectionName
          ? {
              ...section,
              features: section.features.map(feature =>
                feature.name === featureName
                  ? { ...feature, enabled: !feature.enabled }
                  : feature
              ),
            }
          : section
      )
    );
  };

  // Handle Subfeature Toggle
  const handleSubfeatureToggle = (sectionName, featureName, subfeatureName) => {
    setSections(prevSections =>
      prevSections.map(section => 
        section.name === sectionName
          ? {
              ...section,
              features: section.features.map(feature =>
                feature.name === featureName
                  ? {
                      ...feature,
                      subfeatures: feature.subfeatures.map(subfeature =>
                        subfeature.name === subfeatureName
                          ? { ...subfeature, enabled: !subfeature.enabled }
                          : subfeature
                      ),
                    }
                  : feature
              ),
            }
          : section
      )
    );
  };




  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const collectPermissions = () => {
    const permissions = {};
    sections.forEach((section) => {
      if (section.selected) {
        permissions[section.name] = {};
        section.features.forEach((feature) => {
          permissions[section.name][feature.name] = {
            selected: feature.enabled,
            subfeatures: feature.subfeatures.reduce((acc, subfeature) => {
              acc[subfeature.name] = subfeature.enabled;
              return acc;
            }, {}),
          };
        });
      }
    });
    return permissions || {}; // Return an empty object if permissions is undefined
  };



const handleSubmit = async (e) => {
  e.preventDefault();
  const permissions = collectPermissions();
  const payload = {
    ...formData,
    permissions,
    employee_id:employee?.EmployeeDetails?.id
  };
  handleeditSubmit(payload);
};
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}>
      <div style={{
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        width: '600px',
        maxHeight: '80vh',  // Set max height for the modal
        overflowY: 'auto',   // Enable scrolling within the modal
        textAlign: 'center',
      }}>
        <h2>Edit Employee</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            placeholder="First Name"
            style={{ marginBottom: '10px', padding: '8px', width: '100%' }}
            required
          />
          <input
            type="text"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            placeholder="Last Name"
            style={{ marginBottom: '10px', padding: '8px', width: '100%' }}
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            style={{ marginBottom: '10px', padding: '8px', width: '100%' }}
            required
          />
          <input
            type="text"
            name="role"
            value={formData.role}
            onChange={handleChange}
            placeholder="Role"
            style={{ marginBottom: '10px', padding: '8px', width: '100%' }}
          />
          <input
            type="text"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            placeholder="Phone Number"
            style={{ marginBottom: '10px', padding: '8px', width: '100%' }}
          />
          <input
            type="text"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            placeholder="Gender"
            style={{ marginBottom: '10px', padding: '8px', width: '100%' }}
          />
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", backgroundColor: "#f5f5f5", borderRadius: "8px" }}>
      {/* Render Sections */}
      {sections.map((section) => (
        <div key={section.name} style={{ marginBottom: "20px", borderRadius: "8px", backgroundColor: "#fff", boxShadow: "0 4px 8px rgba(0,0,0,0.1)", padding: "15px" }}>
          {/* Section Header */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "15px",
              borderBottom: "2px solid #e1e1e1",
              paddingBottom: "10px"
            }}
          >
            <span style={{ fontSize: "18px", fontWeight: "600", color: "#4A90E2" }}>
              {section.name.charAt(0).toUpperCase() + section.name.slice(1)}
            </span>
            <input
              type="checkbox"
              checked={section.selected}
              onChange={() => handleSectionToggle(section.name)}
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
            />
          </div>

          {/* Render Features */}
          {section.selected && section.features.map((feature) => (
            <div key={feature.name} style={{ marginLeft: "20px", marginBottom: "15px" }}>
              {/* Feature Header */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "5px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  marginBottom: "10px",
                }}
              >
                <span style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
                  {feature.name}
                </span>
                <input
                  type="checkbox"
                  checked={feature.enabled}
                  onChange={() => handleFeatureToggle(section.name, feature.name)}
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </div>

              {feature.enabled && feature.subfeatures.length > 0 && (
                <div style={{ marginLeft: "30px" }}>
                  {feature.subfeatures.map((subfeature) => (
                    <div key={subfeature.name} style={{ marginBottom: "8px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ fontSize: "14px", color: "#666" }}>{subfeature.name}</span>
                        {
                          subfeature.name .length > 0 && (
                            <input
                            type="checkbox"
                            checked={subfeature.enabled}
                            onChange={() => handleSubfeatureToggle(section.name, feature.name, subfeature.name)}
                            style={{
                              width: "16px",
                              height: "16px",
                              cursor: "pointer",
                            }}
                          />
                          )
                        }
                       
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>

          <button type="submit" style={{ padding: '10px 20px', margin: '10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px' }}>
            Save
          </button>
          <button type="button" onClick={onClose} style={{ padding: '10px 20px', backgroundColor: '#6c757d', color: '#fff', border: 'none', borderRadius: '4px' }}>
            Cancel
          </button>
        </form>
      </div>
    </div>
    
  );
};
