import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  SubMenu
} from 'react-pro-sidebar';
import { ImNewspaper } from 'react-icons/im';
import {
  FiHome,
  FiLogOut,
  FiArrowLeftCircle,
  FiArrowRightCircle
} from 'react-icons/fi';
import { FaUserMd, FaUserAlt, FaUserCircle } from 'react-icons/fa';
import { BsListOl, BsFillStopwatchFill } from 'react-icons/bs';
import { GrList } from 'react-icons/gr';
import { MdPersonAddAlt1 } from 'react-icons/md';
import { BiCommentAdd } from 'react-icons/bi';
import { FcCalendar } from 'react-icons/fc';
import { RiFileAddLine } from 'react-icons/ri';
import 'react-pro-sidebar/dist/css/styles.css';
import useAuth from '../../useAuth'
import Verifyauth from '../../verifyauth';

import './Sidebar.css';

const Sidebar = () => {
  // const { isAuth, userinfo } = Verifyauth();
  // const { user } = useAuth();


  const [menuCollapse, setMenuCollapse] = useState(false);
  const { user, isAuthenticated } = useAuth()
  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };
  console.log(user?.permissions, 'all subfeaturesa')
  const hasUserAccess = user?.permissions?.Users || user?.role === 'superadmin';
  const hasVideoAccess = user?.permissions?.Videos || user?.role === 'superadmin';
  const hasBankTransactionAccess = user?.permissions?.['Bank TranSaction'] || user?.role === 'superadmin';
  const hasDiamondTransactionAccess = user?.permissions?.['Diamond Transaction'] || user?.role === 'superadmin';
  const hasPromotions = user?.permissions?.Promotions || user?.role === 'superadmin';
  const hasVerification = user?.permissions?.Verification || user?.role === 'superadmin';
  const hasCountries = user?.permissions?.Countries || user?.role === 'superadmin';
  const hasCities = user?.permissions?.Cities || user?.role === 'superadmin';
  const hasGifts = user?.permissions?.Gifts || user?.role === 'superadmin';
  const hasWithdrawalRequest = user?.permissions?.['Withdrawal request'] || user?.role === 'superadmin';
  const hasNotification = user?.permissions?.Notification || user?.role === 'superadmin';
  const hasDollarDiamondrate = user?.permissions?.['Dollar Diamond rate'] || user?.role === 'superadmin';

  const hasHobbies = user?.permissions?.Hobbies || user?.role === 'superadmin';
  const hasOccupations = user?.permissions?.Occupations || user?.role === 'superadmin';
  const hasLanguages = user?.permissions?.Languages || user?.role === 'superadmin';
  const hasAvatar = user?.permissions?.Avatar || user?.role === 'superadmin';
  const hasPrivacyPolicy = user?.permissions?.['Privacy Policy'] || user?.role === 'superadmin';
  const hasTermsCondition = user?.permissions?.['Terms Condition'] || user?.role === 'superadmin';
  const hasFeedAccess=user?.permissions?.Feed || user?.role === 'superadmin'
  return (
    <div className='header' >
      <ProSidebar collapsed={menuCollapse}>

        <SidebarHeader>
          <div className="logotext" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
            <p style={{ fontSize: 25 }}>{menuCollapse ? <FaUserCircle /> : user?.first_name}</p>


          </div>
          <p style={{ fontSize: 25, marginLeft: '6px' }}>{user?.role}</p>

          <div className="closemenu" onClick={menuIconClick}>
            {menuCollapse ? <FiArrowRightCircle /> : <FiArrowLeftCircle />}
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            <MenuItem active={true} icon={<FiHome />}>
              <Link to="/dashboard">Dashboard</Link>
            </MenuItem>




            {
              hasUserAccess && (
                <SubMenu icon={<FaUserMd />} title="Users">

                  <MenuItem icon={<MdPersonAddAlt1 />}>
                    <Link to="/users/all_users">All Users</Link>
                  </MenuItem>

                  <MenuItem icon={<MdPersonAddAlt1 />}>
                    <Link to="/users/basic_users">Basic Users</Link>
                  </MenuItem>

                  <MenuItem icon={<MdPersonAddAlt1 />}>
                    <Link to="/users/premium_users">Premium Users</Link>
                  </MenuItem>


                  <MenuItem icon={<BsListOl />}>
                    <Link to="/users/business_users">Business Users</Link>
                  </MenuItem>

                  <MenuItem icon={<MdPersonAddAlt1 />}>
                    <Link to="/users/reported_users">Reported Users</Link>
                  </MenuItem>

                  <MenuItem icon={<BsListOl />}>
                    <Link to="/users/blocked_users">Blocked Users</Link>
                  </MenuItem>
                  <MenuItem icon={<MdPersonAddAlt1 />}>
                    <Link to="/users/deletion_request">Deletion Request</Link>
                  </MenuItem>
                  <MenuItem icon={<MdPersonAddAlt1 />}>
                    <Link to="/users/muteprivatechat">Muted Private Chat</Link>
                  </MenuItem>
                  
                </SubMenu>

              )
            }





            {
              hasVideoAccess && (
                <SubMenu icon={<FaUserAlt />} title="Videos">
                  <MenuItem icon={<MdPersonAddAlt1 />}>
                    <Link to="/videos/all_videos">All Videos</Link>
                  </MenuItem>
                  <MenuItem icon={<BsListOl />}>
                    <Link to="/videos/reported_videos">Reported Videos</Link>
                  </MenuItem>
                  <MenuItem icon={<FcCalendar />}>
                    <Link to="/videos/blocked_videos">Blocked Videos</Link>
                  </MenuItem>
                  <MenuItem icon={<FcCalendar />}>
                    <Link to="/videos/video_delection">Video Delection Request</Link>
                  </MenuItem>
                  <MenuItem icon={<FcCalendar />}>
                    <Link to="/videos/rankedvideo">Ranked Video</Link>
                  </MenuItem>
                  <MenuItem icon={<FcCalendar />}>
                    <Link to="/videos/profilerankvideo">Profile Ranked Video</Link>
                  </MenuItem>
                  <MenuItem active={true} icon={<FiHome />}>
                    <Link to="/videos/mutevideocomment"> Mute Video Comment</Link>
                  </MenuItem>

                  <MenuItem active={true} icon={<FiHome />}>
                    <Link to="/videos/muteVideoAudio"> Mute Video Audio</Link>
                  </MenuItem>
                  
                </SubMenu>

              )
            }


            {/* *********************feed section */}
            {
              hasFeedAccess && (
                <SubMenu icon={<FaUserAlt />} title="Feed">
                <MenuItem icon={<MdPersonAddAlt1 />}>
                  <Link to="/AllFeed">All Feed</Link>
                </MenuItem>
                <MenuItem icon={<MdPersonAddAlt1 />}>
                  <Link to="/muteuser">Muted Comment User</Link>
                </MenuItem>
                <MenuItem icon={<MdPersonAddAlt1 />}>
                  <Link to="/blockFeed">Block Feed Info</Link>
                </MenuItem>
              </SubMenu>
              )
            }
           




            {/* <MenuItem active={true} icon={<FiHome />}>
            <Link to="/">Sound</Link>
          </MenuItem> */}

            {
              hasBankTransactionAccess && (
                <SubMenu icon={<FaUserAlt />} title="Bank Transaction">
                  <MenuItem icon={<MdPersonAddAlt1 />}>
                    <Link to="/bank_transactions/success">Success</Link>
                  </MenuItem>
                  <MenuItem icon={<BsListOl />}>
                    <Link to="/bank_transactions/failure">Failure</Link>
                  </MenuItem>
                </SubMenu>

              )
            }
            {
              hasDiamondTransactionAccess && (
                <SubMenu icon={<FaUserAlt />} title="Diamond Transaction">
                  {/* <MenuItem icon={<MdPersonAddAlt1 />}>
      <Link to="/diamond_transactions/all_transaction">All Transactions</Link>
    </MenuItem> */}
                  <MenuItem icon={<BsListOl />}>
                    <Link to="/diamond_transactions/video_transaction">Video</Link>
                  </MenuItem>
                  <MenuItem icon={<BsListOl />}>
                    <Link to="/diamond_transactions/live_transaction">Live </Link>
                  </MenuItem>
                  <MenuItem icon={<BsListOl />}>
                    <Link to="/diamond_transactions/messages_transaction">Messages </Link>
                  </MenuItem>
                  <MenuItem icon={<BsListOl />}>
                    <Link to="/diamond_transactions/comment_transaction">Comments </Link>
                  </MenuItem>
                  <MenuItem icon={<BsListOl />}>
                    <Link to="/diamond_transactions/admin_to_user_transation">Admin </Link>
                  </MenuItem>
                  <MenuItem icon={<BsListOl />}>
                    <Link to="/diamond_transactions/feed_rose_transation">Feed Rose Transation </Link>
                  </MenuItem>

                </SubMenu>

              )
            }
            {
              hasPromotions && (
                <SubMenu icon={<FaUserAlt />} title="Promotions">
                  {/* <MenuItem icon={<MdPersonAddAlt1 />}>
      <Link to="/promotions/all_promotions">All</Link>
    </MenuItem> */}
                  <MenuItem icon={<MdPersonAddAlt1 />}>
                    <Link to="/promotions/live_promotions">Live</Link>
                  </MenuItem>
                  <MenuItem icon={<BsListOl />}>
                    <Link to="/promotions/accomplished_promotions">Accomplished</Link>
                  </MenuItem>
                  <MenuItem icon={<BsListOl />}>
                    <Link to="/promotions/reported_promotions">Reported</Link>
                  </MenuItem>
                  <MenuItem icon={<BsListOl />}>
                    <Link to="/promotions/failure_promotions">Failure</Link>
                  </MenuItem>
                </SubMenu>

              )
            }

            {
              hasVerification && (
                <SubMenu icon={<FaUserAlt />} title="Verifivation">

                  <MenuItem active={true} icon={<BsListOl />}>
                    <Link to="/verification_request">Verification request By Admin</Link>
                  </MenuItem>
                  {/* <MenuItem active={true} icon={<BsListOl />}>
              <Link to="/Verification_document">Verification document</Link>
            </MenuItem> */}
                  <MenuItem active={true} icon={<BsListOl />}>
                    <Link to="/document_data">User verification Document</Link>
                  </MenuItem>

                  <MenuItem active={true} icon={<BsListOl />}>
                    <Link to="/verification_request_by_user">Verification request By User</Link>
                  </MenuItem>

                  <MenuItem active={true} icon={<BsListOl />}>
                    <Link to="/BadgeRequest">Badge Request</Link>
                  </MenuItem>
                  
                </SubMenu>
              )
            }

            {
              hasCountries && (
                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/countries">Countries</Link>
                </MenuItem>
              )
            }

            {
              hasCities && (
                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/cities">Cities</Link>
                </MenuItem>
              )
            }

            {
              hasGifts && (
                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/gifts">Gifts</Link>
                </MenuItem>
              )

            }

            {
              hasWithdrawalRequest && (
                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/withdrawel_request">Withdrawal request</Link>
                </MenuItem>
              )
            }

            {
              hasNotification && (
                <SubMenu icon={<FaUserAlt />} title="Notification">

                  <MenuItem active={true} icon={<FiHome />}>
                    <Link to="/push_notification">Push Notification</Link>
                  </MenuItem>
                  <MenuItem active={true} icon={<FiHome />}>
                    <Link to="/email_notification">Email</Link>
                  </MenuItem>
                </SubMenu>
              )
            }

            {
              hasDollarDiamondrate && (
                <SubMenu icon={<FaUserAlt />} title="Dollar/Diamond rate">

                  <MenuItem active={true} icon={<FiHome />}>
                    <Link to="/diamond_rate">Dollar/Diamond rate</Link>
                  </MenuItem>
                  <MenuItem active={true} icon={<FiHome />}>
                    <Link to="/all_diamond_rate_info">All Diamond Rate Info</Link>
                  </MenuItem>
                  <MenuItem active={true} icon={<FiHome />}>
                    <Link to="/diamond_rate_converstion">Diamond Rate Converter</Link>
                  </MenuItem>

                </SubMenu>
              )
            }
            {
              hasHobbies && (
                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/hobbies">Hobbies</Link>
                </MenuItem>
              )
            }


            {
              hasOccupations && (
                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/occupations">Occupations</Link>
                </MenuItem>
              )
            }


            {
              hasLanguages && (
                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/languages">Languages</Link>
                </MenuItem>
              )
            }


            {
              hasAvatar && (
                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/avatar">Avatar</Link>
                </MenuItem>
              )
            }

            {
              hasPrivacyPolicy && (
                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/privacy_policy">Privacy Policy</Link>
                </MenuItem>
              )
            }


            {
              hasTermsCondition && (
                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/terms_and_conditions">Terms & Conditions</Link>
                </MenuItem>
              )
            }




            {user?.role === 'superadmin' ? (
              <SubMenu icon={<ImNewspaper />} title="Employee">

                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/employee">Employee</Link>
                </MenuItem>
                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/updatepermission">Update Permission</Link>
                </MenuItem>
              </SubMenu>

            ) : null}

            {user?.role === 'superadmin' ? (
              <SubMenu icon={<ImNewspaper />} title="Profile">
                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/profile"> Your Profile</Link>
                </MenuItem>


                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/profile/superadmmin_employees_transaction"> {user?.role === 'superadmin' ? 'Employees' : 'My'} Transactions</Link>
                </MenuItem>


                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/profile/superadmin_my_transaction"> My Transactions</Link>
                </MenuItem>
                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/profile/AllAdminEmployeeTransation"> All Admin Employee Transation</Link>
                </MenuItem>
                <MenuItem >
                  <Link to={`/viewprofile/${user.id}`}></Link>
                </MenuItem>

              </SubMenu>
            ) : null}


            {/* {user?.role === 'admin' ? (
       <SubMenu icon={<ImNewspaper />} title="Profile">

              <MenuItem active={true} icon={<FiHome />}>
                <Link to="/adminProfile"> Profile</Link>
              </MenuItem>
              <MenuItem active={true} icon={<FiHome />}>
                <Link to="/adminemployetransation">All Transation</Link>
              </MenuItem>
              </SubMenu>

            ) : null}


            {user?.role === 'manager' ? (
              <MenuItem active={true} icon={<FiHome />}>
                <Link to="/managerProfile">Manager Profile</Link>
              </MenuItem>
            ) : null}

            {user?.role === 'assistant manager' ? (
              <MenuItem active={true} icon={<FiHome />}>
                <Link to="/assistantManagerProfile">Assistant Manager </Link>
              </MenuItem>
            ) : null} */}

            {['admin', 'manager', 'assistant manager'].includes(user?.role) && (
              <SubMenu icon={<ImNewspaper />} title="Profile">

                {/* Profile Tab for all roles */}
                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/adminProfile">
                    {user?.role === 'admin' ? 'Admin Profile' :
                      user?.role === 'manager' ? 'Manager Profile' :
                        user?.role === 'assistant manager' ? 'Assistant Manager' : 'Profile'}
                  </Link>
                </MenuItem>

                {/* All Transaction Tab (Visible to all roles) */}
                <MenuItem active={true} icon={<FiHome />}>
                  <Link to="/adminemployetransation">All Transaction</Link>
                </MenuItem>
              </SubMenu>
            )}



            <SubMenu icon={<ImNewspaper />} title="Generate Report">
              {/* <MenuItem icon={<RiFileAddLine />}>
                <Link to="/generate_reports/new_reports">New Reports</Link>
              </MenuItem> */}
              <MenuItem icon={<GrList />}>
                <Link to="/generate_reports/all_reports">All Reports</Link>
              </MenuItem>
              {/* <MenuItem icon={<FcCalendar />}>
                <Link to="/generate_reports/recent_reports">Recent Report</Link>
              </MenuItem> */}
            </SubMenu>
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape="square">
            <MenuItem icon={<FiLogOut />}>
              <Link to="/logout">Logout</Link>
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>


    </div>


  );
};

export default Sidebar;
